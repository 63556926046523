// @ts-nocheck
import { getLocaleStr, getItemId, formatPriceAsFloat, getSectionTitleForItem } from 'utils/utils'
import { CONSTANTS } from 'utils/constants'
import { SIGN_UP_STEPS } from 'constants/signUpProcess'
import { generatePromotionEventData } from './ga4'

let TagManager = null
let sendEvent = null
const menuItemImpressionsAlreadySentMap = {}
let menuItemImpressionsToSendMap = {}
const IMPRESSION_SENDER_DELAY_IN_MS = 3000
let sendImpressionsTimer = null

/**
 * WARNING!!!
 * 
 * The SIGN_IN_METHODS is used in the mobile application as well. It is duplicated there...
 * If you are changing It - make sure to change the mobile application code as well or at list 
 * to check if the changes are not required there.
 */
const SIGN_IN_METHODS = {
	PHONE: 'Phone',
	EMAIL: 'Email',
	GOOGLE: 'Google',
	FACEBOOK: 'Facebook',
	APPLE: 'Apple',
	GUEST: 'Guest',
}

/**
 * WARNING!!!
 * 
 * The ORDER_TYPE_MAPPING is used in the mobile application as well. It is duplicated there...
 * If you are changing It - make sure to change the mobile application code as well or at list 
 * to check if the changes are not required there.
 */
const ORDER_TYPE_MAPPING = {
	delivery: 'Delivery',
	pickup: 'Takeaway',
	peakup: 'Takeaway',
	'dine-in': 'Dine In',
}

/**
 * Init this SDK
 *
 * @param tagManager - tagManager instance
 * @param sendEventMethod - sendEvent method
 */
export const init = (tagManager, sendEventMethod) => {
	if (typeof TagManager !== 'undefined') {
		TagManager = tagManager
	} else {
		console.error('ERROR - TagManager is not defined')
	}
	sendEvent = sendEventMethod
}

/**
 * Each file has it's own sendEvent which uses the SDK to send the event so we need to call this a different name.
 *
 * @param eventName
 * @param data
 * @returns {*}
 */
export const triggerSendEvent = (eventName, ...args) => {
	if (mapAndSendEvent[eventName]) {
		if (eventName === CONSTANTS.ANALYTICS.OTHER_EVENTS.custom || eventName === CONSTANTS.ANALYTICS.OTHER_EVENTS.pageView) {
			mapAndSendEvent[eventName](...args)
		}
	} else {
		console.warn(`GA4 has no ecommerce event called '${eventName}'`)
	}
}

/**
 * Map the generic data to the format for this file's 'strategy'.
 *
 * We send it in each mapping because we user a timer in a few of the mappings to debounce requests for impressions.
 * Otherwise we would need to provide a callback which complicates the solution.
 *
 * @type {{
 * sendViewProdutDetailEvent: mapAndSendEvent.sendViewProdutDetailEvent,
 * sendProductImpressionEvent: mapAndSendEvent.sendProductImpressionEvent,
 * sendProductClickEvent: mapAndSendEvent.sendProductClickEvent,
 * addMenuItemImpression: mapAndSendEvent.addMenuItemImpression,
 * custom: mapAndSendEvent.custom,
 * sendAddToCartEvent: mapAndSendEvent.sendAddToCartEvent,
 * sendRemoveFromCartEvent: mapAndSendEvent.sendRemoveFromCartEvent,
 * sendCheckOutEvent: mapAndSendEvent.sendCheckOutEvent,
 * sendPurchaseEvent: mapAndSendEvent.sendPurchaseEvent,
 * sendSelectPromotionEvent: mapAndSendEvent.sendSelectPromotionEvent,
 * sendViewPromotionEvent: mapAndSendEvent.sendViewPromotionEvent,
 * }}
 */
const mapAndSendEvent = {
	/**
	 * Only send an impression for a menu-item that hasn't already had an impressions sent to GTM.
	 *
	 * Group impressions in a collection and send it every IMPRESSION_SENDER_DELAY_IN_MS seconds.
	 *
	 * @param item
	 * @param listType
	 * @param rest
	 * @param sectionTitle
	 */
	addMenuItemImpression: (item, listType, rest, sectionTitle) => {
		// clone the item so we can add props to it without effecting the main app
		const _item = JSON.parse(JSON.stringify(item))
		_item.listType = listType
		_item.sectionTitle = sectionTitle

		if (!menuItemImpressionsAlreadySentMap[item.id]) {
			menuItemImpressionsToSendMap[_item.id] = _item

			if (sendImpressionsTimer) {
				clearTimeout(sendImpressionsTimer)
				sendImpressionsTimer = null
			}

			sendImpressionsTimer = setTimeout(() => {
				mapAndSendEvent.sendProductImpressionEvent(rest)
			}, IMPRESSION_SENDER_DELAY_IN_MS)
		}
	},
	sendProductImpressionEvent: (rest) => {
		clearTimeout(sendImpressionsTimer)
		sendImpressionsTimer = null

		const _items = []

		Object.keys(menuItemImpressionsToSendMap).forEach((_itemId, _index) => {
			// update the impressions map saying this item has now been sent to GTM
			menuItemImpressionsAlreadySentMap[_itemId] = true

			const _item = menuItemImpressionsToSendMap[_itemId]
			_items.push({
				item_name: getLocaleStr(_item.title, rest.locale),
				item_id: getItemId(_item),
				price: formatPriceAsFloat(_item.price, rest.currency, rest.countryCode, 1, false, true),
				item_list_name: _item.listType,
				item_category: _item.sectionTitle,
				// position: _index + 1,
				quantity: 1,
				currency: rest.currency,
			})
		})

		const event = {
			dataLayer: {
				event: 'view_item_list',
				ecommerce: {
					items: _items,
				},
			},
		}

		sendEvent(event)
		menuItemImpressionsToSendMap = {}
	},
	sendProductClickEvent: (item, metaData) => {
		const event = {
			dataLayer: {
				event: 'view_item',
				ecommerce: {
					items: [
						{
							item_name: metaData.itemTitle,
							item_id: getItemId(item),
							price: formatPriceAsFloat(item.price, metaData.currency, metaData.countryCode, 1, false, true),
							item_category: metaData.sectionTitle,
							item_list_name: metaData.sectionTitle,
							currency: metaData.currency,
							quantity: 1,
						},
					],
				},
			},
		}

		sendEvent(event)

		// since we have no place to trigger this event from our UI and our GTM guru advised us to send it anyway, we
		// are triggering it from here!
		mapAndSendEvent.sendViewProdutDetailEvent(item, metaData)
	},
	sendViewProdutDetailEvent: (item, metaData) => {
		const event = {
			dataLayer: {
				event: 'select_item',
				ecommerce: {
					items: [
						{
							item_name: metaData.itemTitle,
							item_id: getItemId(item),
							price: formatPriceAsFloat(item.price, metaData.currency, metaData.countryCode, 1, false, true),
							item_category: metaData.sectionTitle,
							item_list_name: metaData.sectionTitle,
							quantity: 1,
							currency: metaData.currency,
						},
					],
				},
			},
		}

		sendEvent(event)
	},
	sendAddToCartEvent: (item, metaData) => {
		// clone so as not to effect the original item
		const _clone = JSON.parse(JSON.stringify(item))
		_clone.description = metaData.description

		const event = {
			dataLayer: {
				event: 'add_to_cart',
				ecommerce: {
					currency: metaData.currency,
					value: formatPriceAsFloat(_clone.price, metaData.currency, metaData.countryCode, item.quantity, false),
					items: [
						{
							item_name: metaData.itemTitle,
							index: 0,
							item_id: getItemId(_clone),
							price: formatPriceAsFloat(_clone.price, metaData.currency, metaData.countryCode, 1, false, true),
							item_category: metaData.sectionTitle,
							item_list_name: metaData.sectionTitle,
							quantity: _clone.quantity,
						},
					],
				},
			},
		}

		sendEvent(event)
	},
	sendRemoveFromCartEvent: (item, metaData) => {
		const event = {
			dataLayer: {
				event: 'remove_from_cart',
				ecommerce: {
					currency: metaData.currency,
					value: formatPriceAsFloat(item.total, metaData.currency, metaData.countryCode, 1, false, true),
					items: [
						{
							currency: metaData.currency,
							name: item.itemTitle,
							id: getItemId(item),
							index: 0,
							price: formatPriceAsFloat(item.price, metaData.currency, metaData.countryCode, 1, false, true),
							category: item.sectionTitle,
							quantity: item.quantity,
						},
					],
				},
			},
		}

		sendEvent(event)
	},
	sendViewCartEvent: (rest, cartFields) => {
		if (!rest) {
			return
		}

		const _coupons = cartFields.getAppliedCoupons()

		const _items = []
		Object.keys(cartFields.items).forEach((_key) => {
			const _itemArray = cartFields.items[_key]
			_itemArray.forEach((_itemElement, _index) => {
				const _fullItem = rest.items[_itemElement.itemId]
				_items.push({
					currency: rest.currency,
					item_name: getLocaleStr(_fullItem.title, rest.locale),
					item_id: getItemId(_fullItem),
					index: _index,
					price: formatPriceAsFloat(_itemElement.total, rest.currency, rest.countryCode, 1, false, true),
					quantity: _itemElement.quantity,
					item_category: _itemElement.sectionTitle,
					item_list_name: _itemElement.sectionTitle,
					coupon: _coupons,
				})
			})
		})

		const ecommerce = {
			currency: rest.currency,
			value: formatPriceAsFloat(cartFields.grandTotal, rest.currency, rest.countryCode, 1, false, true),
			items: _items,
		}

		const event = {
			dataLayer: {
				event: 'view_cart',
				ecommerce,
			},
		}

		sendEvent(event)
	},
	sendCheckOutEvent: (rest, stepData, cartFields) => {
		const _coupons = cartFields.coupons
		const _items = []
		let _index = 0
		Object.keys(cartFields.items).forEach((_key) => {
			const _itemArray = cartFields.items[_key]
			_itemArray.forEach((_itemElement) => {
				const _fullItem = rest.items[_itemElement.itemId]
				_items.push({
					currency: rest.currency,
					item_name: getLocaleStr(_fullItem.title, rest.locale),
					item_id: getItemId(_fullItem),
					index: _index,
					price: formatPriceAsFloat(_itemElement.total, rest.currency, rest.countryCode, 1, false, true),
					quantity: _itemElement.quantity,
					item_category: _itemElement.sectionTitle,
					item_list_name: _itemElement.sectionTitle,
					coupon: _coupons,
				})
				_index += 1
			})
		})

		const CC = CONSTANTS.CHECKOUT_STEPS
		let eventName = null

		const ecommerce = {
			currency: rest.currency,
			value: formatPriceAsFloat(cartFields.grandTotal, rest.currency, rest.countryCode, 1, false, true),
			coupon: _coupons,
			// shipping_tier:
			items: _items,
		}

		switch (stepData.step) {
			case CC.GOTO_CHECKOUT_PAGE:
				eventName = 'begin_checkout'
				ecommerce.shipping_tier = stepData.deliveryType
				ecommerce.payment_type = stepData.paymentType || ''
				break
			case CC.SELECTED_PAYMENT_METHOD:
				eventName = 'add_payment_info'
				ecommerce.payment_type = stepData.option || ''
				break
			case CC.DELIVERY_FORM_VALID:
				eventName = 'add_shipping_info'
				ecommerce.shipping_tier = stepData.option
				break
			default:
				console.error(`unknown checkout step for GA4: ${stepData.step}`)
				return
		}

		const event = {
			dataLayer: {
				event: eventName,
				ecommerce,
			},
		}

		sendEvent(event)
	},
	sendPurchaseEvent: (itemArray, orderConfirmation, metaData) => {
		const _items = []

		itemArray.forEach((_itemElement, _index) => {
			const sectionTitle = getSectionTitleForItem(_itemElement.itemId, metaData?.sections || [], metaData?.locale) || ''
			_items.push({
				currency: metaData.currency,
				item_name: getLocaleStr(_itemElement.title, metaData.locale),
				item_id: getItemId(_itemElement),
				item_category: sectionTitle,
				item_list_name: sectionTitle,
				index: _index,
				coupon: orderConfirmation.coupon || '',
				price: formatPriceAsFloat(_itemElement.price, metaData.currency, metaData.countryCode, 1, false, true),
				quantity: _itemElement.quantity, // the courseList from the orderConfirm API doesn't group but has 1 menu-item per element
			})
		})

		const event = {
			dataLayer: {
				event: 'purchase',
				email: metaData.user?.email || '',
				first_name: metaData.user?.first_name || '',
				last_name: metaData.user?.last_name || '',
				phone_number: metaData.user?.phone_number || '',
				transaction_id: orderConfirmation.id,
				tictuk_orderid: orderConfirmation.tictuk_orderid,
				shipping_tier: orderConfirmation.shipping_tier,
				payment_type: orderConfirmation.payment_type,
				ecommerce: {
					transaction_id: orderConfirmation.id,
					currency: metaData.currency,
					value: parseFloat(orderConfirmation.revenue) || 0.0,
					tax: parseFloat(orderConfirmation.tax),
					shipping: parseFloat(orderConfirmation.shipping) || 0,
					coupon: orderConfirmation.coupon || '',
					items: _items,
				},
			},
		}
		sendEvent(event)
	},
	/**
	 * Format the event data into GA4 format for a custom event.
	 *
	 * This code was copied from ga4.js since it's a private method and we cannot import it.
	 *
	 * This method provides the fallback 'GA4' event format if there is no mapping for 'KFC Global' for the event.
	 * @param data
	 */
	ga4Custom: (data) => {
		const { category, action, label, event, 'gtm.uniqueEventId': a, ...customKeys } = data

		const _customEvent = {
			event: category.toLowerCase().replaceAll(/\s/g, '_'),
			action,
			label,
		}

		// sometimes we need to add key/values that don't fit under category, action or label. So we extract and add these custome fields here
		Object.keys(customKeys).forEach((_customKey) => {
			_customEvent[_customKey] = data[_customKey]
		})

		const eventWrapper = {
			dataLayer: _customEvent,
		}

		sendEvent(eventWrapper)
	},
	/**
	 * WARNING!!!
	 * 
	 * Mobile application contains similar code for mapping the ga4YumGlobal veb-view events that arrive to firebase analytics.
	 * If you are changing It - make sure to change the mobile application code as well or at list 
	 * to check if the changes are not required there.
	 */
	custom: (data) => {
		try {
			const _customMap = mapAndSendEvent.customMap(data)

			if (_customMap[data.category] && _customMap[data.category][data.action]) {
				let _customEvent = mapAndSendEvent.customMap(data)[data.category][data.action]

				if (_customEvent[data.label]) {
					// occassionally the mapping needs to be 3 levels deep [category][action][label] due to [category][action] having > 1 child
					_customEvent = _customEvent[data.label]
				}

				if (_customEvent.event) {
					// each event for a KFC Global mapping has an 'event' property. So if this property exists then a single event mapping was found
					const eventWrapper = {
						dataLayer: _customEvent,
					}

					sendEvent(eventWrapper)
				}
			}
		} catch (e) {
			console.error(e)
		}
	},
	/**
	 * WARNING!!!
	 * 
	 * Mobile application contains similar code for mapping the ga4YumGlobal veb-view events that arrive to firebase analytics.
	 * If you are changing It - make sure to change the mobile application code as well or at list 
	 * to check if the changes are not required there.
	 */
	customMap: (data) => ({
		'address box': {
			'button clicked': {
				event: 'selectOrderType',
				disposition: ORDER_TYPE_MAPPING[data.label?.toLowerCase()] || 'unknown',
				page_location: window.location.href?.split('?')[0],
			},
			clicked: {
				'map icon': {
					event: 'localisationStart',
					page_location: window.location.href?.split('?')[0],
				},
				'map link': {
					event: 'localisationStart',
					page_location: window.location.href?.split('?')[0],
				},
			},
			'clicked on pre start my order': {
				event: 'localisationFinish',
				postLocalisation: 'true', // a string, not a boolean!
				storeID: data.label,
				disposition: ORDER_TYPE_MAPPING[data.orderType?.toLowerCase()] || 'unknown',
				page_location: window.location.href?.split('?')[0],
			},
			'clicked on start my order': {
				event: 'localisationFinish',
				postLocalisation: 'true', // a string, not a boolean!
				storeID: data.label,
				disposition: ORDER_TYPE_MAPPING[data.orderType?.toLowerCase()] || 'unknown',
				page_location: window.location.href?.split('?')[0],
			},
			typed: {
				event: 'localisationStart',
				page_location: window.location.href?.split('?')[0],
			},
			click: {
				'last address': {
					event: 'localisationStart',
					page_location: window.location.href?.split('?')[0],
				},
				'use my location': {
					event: 'localisationStart',
					page_location: window.location.href?.split('?')[0],
				},
			},
			finish: {
				'last address': {
					event: 'localisationFinish',
					postLocalisation: 'true',
					storeID: data.storeId,
					disposition: ORDER_TYPE_MAPPING[data.orderType?.toLowerCase()] || 'unknown',
					page_location: window.location.href?.split('?')[0],
				},
				'use my location': {
					event: 'localisationFinish',
					postLocalisation: 'true',
					storeID: data.storeId,
					disposition: ORDER_TYPE_MAPPING[data.orderType?.toLowerCase()] || 'unknown',
					page_location: window.location.href?.split('?')[0],
				},
			},
			'select address from drop down': {
				event: 'localisationFinish',
				postLocalisation: 'true',
				storeID: data.storeId,
				disposition: ORDER_TYPE_MAPPING[data.orderType?.toLowerCase()] || 'unknown',
				page_location: window.location.href?.split('?')[0],
			},
		},
		account: {
			'signup step': {
				[SIGN_UP_STEPS.VERIFY_IDENTIFIER]: {
					event: 'signInStart',
					loginMethod: data.loginMethod,
				},
				[SIGN_UP_STEPS.EDIT_CODE]: {
					event: 'registrationStart',
					loginMethod: data.loginMethod,
				},
			},
			signup: {
				'user details added': {
					event: 'registrationFinish',
					userID: data.uuid,
					loginMethod: data.loginMethod,
					firstName: data.first_name,
					lastName: data.last_name,
					emailAddress: data.email,
					phoneNumber: data.phone_number,
				},
			},
			signin: {
				success: {
					event: 'signInFinish',
					userID: data.uuid,
					loginMethod: data.loginMethod,
					firstName: data.first_name,
					lastName: data.last_name,
					emailAddress: data.email,
					phoneNumber: data.phone_number,
				},
			},
		},
		cart: {
			edit: {
				event: 'editCartItem',
				item: data.label,
			},
		},
		error: {
			notification: {
				event: 'embeddedErrorMessage',
				popupTitle: data.label,
				errorMessage: data?.message || '',
			},
			validation: {
				event: 'embeddedErrorMessage',
				popupTitle: data.label,
				errorMessage: data?.message || '',
			},
		},
	}),
	pageView: (data) => {
		const _pageViewEvent = {
			event: 'virtualPageView',
			pageTitle: window.document.title,
			pageURL: window.location.href,
		}

		const event = {
			dataLayer: _pageViewEvent,
		}

		sendEvent(event)
	},
	sendSelectPromotionEvent: (dealData, metaData) => {
		sendEvent({
			dataLayer: {
				event: 'select_promotion',
				...generatePromotionEventData(dealData, metaData),
			},
		})
	},
	sendViewPromotionEvent: (dealData, metaData) => {
		sendEvent({
			dataLayer: {
				event: 'view_promotion',
				...generatePromotionEventData(dealData, metaData),
			},
		})
	},
}
