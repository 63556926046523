import type { Dispatch, SetStateAction } from 'react'
import { useEffect, useRef, useState } from 'react'
import Application from 'mobx/Application'
import type { GenericCallback } from 'types/constants.types'

/**
 * useWindowState() Hook
 * This custom hook is used to add an empty state to the window history,
 * it useful for example when using a modal, and by pressing on the browser "back" button the modal will
 * be closed but the page itself won't navigate to the previous page.
 *
 * the hook returns an array of the function which can be renamed when destructuring the hook
 * @returns {[addState: func, activate: func]}
 *
 * addState(cb: func): a function that gets a callback function which will be invoked when adding a window state to the stack
 * activate(removeEvent: boolean): a function to activate the callback added to "addState()",
 * which gets an optional parameter to whether or not removing the window state after dispatching the event.
 */
const useWindowState = (): [(cb: GenericCallback, windowStateId?: string) => void, Dispatch<SetStateAction<boolean>>] => {
	const [isBack, setBack] = useState(false)
	const ref = useRef<GenericCallback | null>()

	useEffect(() => {
		if (isBack) {
			window.history.back()
			setBack(false)
		}
	}, [isBack])

	const popStateCallBack = (cb: GenericCallback) => {
		cb()
		window.removeEventListener('popstate', ref.current!)
		ref.current = null

		Application.dependencies?.replaceHistoryState()
	}

	const addState = (cb: GenericCallback, windowStateId?: string) => {
		if (!ref.current) {
			ref.current = () => popStateCallBack(cb)

			const newUrl = `${window.location.pathname}${window.location.search}#windowState${windowStateId ? '_' : ''}${windowStateId || ''}`
			Application.dependencies?.pushHistoryState(newUrl)

			window.addEventListener('popstate', ref.current)
		}
	}

	return [addState, setBack]
}

export default useWindowState
