import { throttle } from 'utils/throttle'
import { sendRequest, isMobile } from 'utils/utils'
import Infra from 'mobx/Infra'
import { type SessionConfiguration, type EventListener, DeviceType } from 'mobx/Infra/Infra.type'
import type { Session } from '../store'

const sendActivity = async (sessionId: string) => {
	const tenantId = Infra?.appParams?.c

	return sendRequest(false, `${Infra?.appParams?.wruec}/v3/session/keep-alive`, 'POST', null, {
		'tictuk-session-id': sessionId,
		'tictuk-tenant-id': tenantId,
	})
}

export const initKeepAlive = async (sessionStore: Session, sessionConfiguration?: SessionConfiguration) => {
	const { requestInterval, activities } = sessionConfiguration || {}
	const _isMobile = isMobile()

	if (!requestInterval || !activities?.length) {
		return
	}

	const throttledActivityListener = throttle(() => {
		const sessionId = sessionStore.getSessionId()

		if (!sessionId) {
			return
		}

		sendActivity(sessionId).catch((err) => {
			console.info('Keep alive error:', err)
		})
	}, requestInterval)

	for (const { id, device } of activities) {
		if ((device.includes(DeviceType.Mobile) && _isMobile) || (device.includes(DeviceType.Desktop) && !_isMobile)) {
			document.addEventListener(id, throttledActivityListener as EventListener)
		}
	}
}
