// @ts-nocheck
import React, { useEffect } from 'react'
import styled, { css } from 'styled-components/macro'
import { isMobile, isIphone } from 'utils/utils'
import { inject, observer } from 'mobx-react'
import Divider from '@material-ui/core/Divider'
import { ORDER_TYPES } from 'utils/constants'
import TypographyPro from 'themes/TypographyPro'
import { getListData } from 'components/home/ModalContent/utilsMap'
import ErrorFindingStores from 'components/home/ModalContent/ErrorFindingStores'
import { isEmpty } from 'lodash-es'
import StoreListRow from 'components/home/ModalContent/StoreListRow'
import useRerender from 'hooks/useRerender'
import VirtualizedList from 'components/common/VirtualizedList'
import { useStores } from 'hooks/useStores'

const List = styled.ul.attrs({
	'data-testid': 'stores-list',
})`
	margin: 0;
	list-style-type: none;
	overflow: auto;
	padding: 10px 10px 0 10px;

	@media (max-width: 768px) {
		padding: 0 0 25% 0;
		height: 92%;
	}
`

const InformationalText = styled(TypographyPro)`
	color: ${({ $color }) => $color};
	padding: 10px 20px;
`

const VirtualListContainer = styled.div`
	position: relative;
	height: 100%;
`

const SIZE_OF_CARDS_GAPS = 10
const SIZE_OF_MOBILE_CARDS_GAPS = 20
const DEFAULT_SIZE_OF_CARD = 300
const DELAY_TIME_IN_MS_BEFORE_RELOAD = 200

const StoresList = inject(
	'Infra',
	'Home',
	'User'
)(
	observer(
		({
			onSelectStore,
			Infra,
			User,
			Home,
			storesLists,
			selectedOrderType,
			setSelectedOrderType,
			setFilterByAvailability,
			allStoresFarAway,
			isStoreOnMapSelected,
			selectedStoreOnMap
		}) => {
			const { locale } = Home
			const listData = getListData(
				selectedOrderType,
				storesLists,
				Home.stores.length !== storesLists.length,
				isStoreOnMapSelected,
				false,
				allStoresFarAway,
				{
					deliveryNotAvailableInTheArea: () => setSelectedOrderType(ORDER_TYPES.PICKUP),
					addressNotFull: () => setSelectedOrderType(ORDER_TYPES.PICKUP),
					storesNotFound: () => setFilterByAvailability(false),
				}
			)

			const {addressManagerV2Store} = useStores()

			const rerender = useRerender(DELAY_TIME_IN_MS_BEFORE_RELOAD)

			useEffect(() => {
				// need to rerender the component because in some cases the gaps of few cards
				// don't set when the list gets rendered
				rerender()
			}, [])

			return (
				<>
					{!addressManagerV2Store.addressPopup?.mapButtonClicked && (
						<InformationalText variant="BodySmall" $color={listData.infoTextColor} data-testid="information-text">
							{listData.infoText}
						</InformationalText>
					)}
					<Divider />
					{listData?.consideredError && <ErrorFindingStores {...listData.informationalData} />}

					{!isEmpty(storesLists) && !listData?.consideredError && (
						<VirtualListContainer>
							<VirtualizedList
								gapSize={isMobile() ? SIZE_OF_MOBILE_CARDS_GAPS : SIZE_OF_CARDS_GAPS}
								cardSize={DEFAULT_SIZE_OF_CARD}
								listContainer={List}
								data={storesLists}
								renderProps={(data, index, setSize, style, innerRef) => (
									<li style={style} data-testid="store-list-item">
										<StoreListRow
											innerRef={innerRef}
											index={index}
											storesLists={data}
											listData={listData}
											Home={Home}
											User={User}
											Infra={Infra}
											onSelectStore={onSelectStore}
											setSize={setSize}
											locale={locale}
											selectedOrderType={selectedOrderType}
											selectedStoreOnMap={selectedStoreOnMap}
											setSelectedOrderType={setSelectedOrderType}
										/>
									</li>
								)}
							/>
						</VirtualListContainer>
					)}
				</>
			)
		}
	)
)

export default StoresList
