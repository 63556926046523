import type { Coupon, Flags } from 'shared-types/Coupon'
import { OrderType } from 'shared-types/Coupon'

export const IN_STORE_ORDER_TYPES: readonly OrderType[] = [OrderType.IN_STORE, OrderType.DRIVETHROUGH]

export type CouponFlags = keyof Flags
/**
 * Virtual fields that add shortcuts to access flag values
 * @example
 * // Assume we have a flag called 'wallet':
 * flags: {
 *   wallet?: {
 *     value: boolean
 *   }
 * }
 *
 * // We can access it in two ways:
 * coupon.flags.wallet?.value
 *
 * // or, using 'virtuals',
 * coupon.wallet // under the hood, calls coupon.flags.wallet?.value
 */
export type FlagShortcuts = Partial<{ [Key in CouponFlags]: Flags[Key]['value'] }>
// Make sure to update this list after adding a new flag
export const couponFlags = ['applied', 'requireLogin', 'wallet', 'active', 'showInMyCoupons'] as const

/**
 * A coupon whose title and description are localized,
 * i.e. are in a specific language instead of containing all locale variants
 */
export interface LocalizedCoupon extends Omit<Coupon, 'title' | 'description'> {
	title: string
	description: string
}
