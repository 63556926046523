import { Coupon } from './Coupon'

export interface VariationChoice {
    id: string
    name: string
    price: number
    en_ID?: string
    hasVariations?: boolean
    autoSelectedAndHidden?: boolean
    isVariation?: boolean
    groupHasDefaults?: boolean
    minNumAllowed?: number
    quantity?: number
    variationsChoices?: (Record<string, VariationChoice> | null)[]
}

type AdditionsNew =
    | Record<string, { variationsChoices: (Record<string, VariationChoice> | null)[] }>
    | Record<string, never>

interface ItemComment {
    text: string
    itemId: string
}

export interface CartItem {
    itemId: string
    en_ID: string //TODO-Jacob: rename to couponCode
    price: number
    quantity: number
    total: number
    comment?: ItemComment
    additionsNew: AdditionsNew //TODO-Jacob: rename to variations
}

export interface DeliveryInfo {
    minOrderPrice: number
    type: string
    delayMins: number
    charge: number
    inactive: boolean
}

export enum CartChangeType {
    ADD_ITEM = 'ADD_ITEM',
    UPDATE_ITEM = 'UPDATE_ITEM',
    REMOVE_ITEM = 'REMOVE_ITEM',
    REMOVE_ITEMS = 'REMOVE_ITEMS',
    UNAVAILABLE_ITEMS = 'UNAVAILABLE_ITEMS',
    ADD_DISCOUNT = 'ADD_DISCOUNT',
    REMOVE_DISCOUNT = 'REMOVE_DISCOUNT',
    DISCOUNT_ACTIVATED = 'DISCOUNT_ACTIVATED',
    DISCOUNT_DEACTIVATED = 'DISCOUNT_DEACTIVATED',
    OPEN_ITEM_MODAL = 'OPEN_ITEM_MODAL'
}

export interface BaseCartChange {
    type: CartChangeType
}

export interface ItemCartChange extends BaseCartChange {
    type: CartChangeType.ADD_ITEM | CartChangeType.UPDATE_ITEM | CartChangeType.REMOVE_ITEM
    id: string
    index: number
}

export interface ItemsCartChange extends BaseCartChange {
    type: CartChangeType.REMOVE_ITEMS
    id: string
    errorMessage: string
}

export interface DiscountCartChange extends BaseCartChange {
    code: string
    type:
        | CartChangeType.ADD_DISCOUNT
        | CartChangeType.REMOVE_DISCOUNT
        | CartChangeType.DISCOUNT_ACTIVATED
        | CartChangeType.DISCOUNT_DEACTIVATED
}

export interface CouponItemChange {
    type: CartChangeType.OPEN_ITEM_MODAL
    item: CartItem // might need to change to actual item
}

export type CartChange = ItemCartChange | DiscountCartChange | CouponItemChange | ItemsCartChange

export interface Cart {
    items: Record<string, CartItem[]>
    discounts: Coupon[]
    charges: ServerCharge[]
    grandTotal: number
    addedItemsFromDiscounts: any[]
    serverDisableCouponsField: boolean
    serverDisableSpecialDiscounts: boolean
    serverWarningsToDisplay: string[]
    serverSpecialDiscountsIdLimit: number
    deliveryInfo: DeliveryInfo
    changes: CartChange[]
    errorMessage?: string
    creationDate: number
}

export interface ServerCharge {
    amount: number
    type: string
    code?: string
    zeroAmountDiscount?: boolean
    freeDelivery?: boolean
    title?: Record<string, string>
}

export interface CourseListItemsNotAvailableError {
    itemId: string
    errorMessage: string
}

export interface CalcGTResponse {
    gt: number
    charges: ServerCharge[] | null
    deliveryInfo: DeliveryInfo
    addedItemsFromDiscounts: any[]
    appliedDiscounts: string[]
    courseListItemsNotAvailableErrors: Record<string, CourseListItemsNotAvailableError> | null
    warnings?: string[]
    disableCouponsField: boolean
    disableSpecialDiscounts: boolean
    error: boolean
    errorMessage: string
    kill: boolean
    msg: string
    specialDiscountsIdLimit: number | null
    tip: number
    redirectURL?: string
}

export enum ErrorCode {
    CART_ORDER_LOCK = 'CART_ORDER_LOCK',
    CART_CALC_GT_ERROR = 'CART_CALC_GT_ERROR',
    ITEM_NOT_FOUND_IN_CART = 'ITEM_NOT_FOUND_IN_CART',
    ITEM_NOT_FOUND_IN_STORE = 'ITEM_NOT_FOUND_IN_STORE',
    DISCOUNT_NOT_FOUND_IN_CART = 'DISCOUNT_NOT_FOUND_IN_CART',
    REMOVE_DISCOUNT_ERROR = 'REMOVE_DISCOUNT_ERROR',
    REDEEM_DISCOUNT_ERROR = 'REDEEM_DISCOUNT_ERROR',
    REDEEM_COUPON_ERROR = 'REDEEM_COUPON_ERROR',
    DISCOUNT_NOT_APPLIED = 'DISCOUNT_NOT_APPLIED',
    SESSION_EXPIRED = 'SESSION_EXPIRED',
    COUPON_ITEM_COUPON_NOT_VALID = 'COUPON_ITEM_COUPON_NOT_VALID',
    SET_ITEMS_ERROR = 'SET_ITEMS_ERROR'
}

export class CartError extends Error {
    constructor(message: string, public code: ErrorCode) {
        super(message)
        this.code = code
    }
}

export class SessionExpiredError extends CartError {
    constructor(message: string) {
        super(message, ErrorCode.SESSION_EXPIRED)
    }
}

export class CartOrderLockError extends CartError {
    constructor(message: string, public cart: Cart, public redirectURL: string) {
        super(message, ErrorCode.CART_ORDER_LOCK)
        this.cart = cart
        this.redirectURL = redirectURL
    }
}

export interface CartErrorResponse {
    error: true
    message: string
    code: ErrorCode
}

export interface CartOrderLockErrorResponse extends CartErrorResponse {
    cart: Cart
    redirectURL: string
}

export type ServerErrorResponse = CartOrderLockErrorResponse | CartErrorResponse | { error: true; message: string }
