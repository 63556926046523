import { action, computed, observable } from 'mobx'
import type Cart from 'mobx/Cart';
import type { NextRouter } from 'next/router';
import type { Dispatch, SetStateAction } from 'react';

import { Menu } from 'types/Menu';
import type { Store } from 'types/StoreContext';
import { StoreMetaData } from 'types/StoreContext';
import type { Localization, Address, Location, AddressDetails, OrderTypeFlow, Store as NewFlowStore, FlowStep } from "mobx/Localization/types";
import type { IAddressManager, LegacyAddress, LegacyAddressByOrderType, AddressManagerV2Dependencies } from './types';

import type _User from 'mobx/User'
import type _Home from 'mobx/Home'
import type _Infra from 'mobx/Infra'

export default class AddressManagerV2Store implements Localization, IAddressManager {
	
    // Props and methods from the old localization flow
    
    constructor(dependencies: AddressManagerV2Dependencies) {
		this.dependencies = dependencies
	}

    private readonly dependencies: AddressManagerV2Dependencies

    @computed get newLocalizationFlowEnabled() {
        return this.dependencies.newLocalizationFlowEnabled()
    }

    @computed get addressPopup() {
        return this.dependencies.addressManager?.addressPopup
    }

    @computed get loading(): boolean {
        return this.dependencies.addressManager?.loading || false
    }

    @computed get localizedAddress(): LegacyAddress | null {
        return this.dependencies.addressManager?.localizedAddress || null
    }

    @action setAddressPopup = (data: any) => this.dependencies.addressManager?.setAddressPopup(data)

    @action closeAddressPopup = () => this.dependencies.addressManager?.closeAddressPopup()
    
    @action isUserLocalized = () => this.dependencies.addressManager?.isUserLocalized() || false

    @action setFullAddress = (addressInfo: any, orderType: string | undefined, fromIFrame = false, removeStoreId = false) => this.dependencies.addressManager?.setFullAddress(addressInfo, orderType, fromIFrame, removeStoreId)

    @action setMatchingStoreIdToAddressByOrderType = (storeId: string, orderType: string) => this.dependencies.addressManager?.setMatchingStoreIdToAddressByOrderType(storeId, orderType)
    
    @action getAddressCoordinatesByOrderType = (orderType: string) => this.dependencies.addressManager?.getAddressCoordinatesByOrderType(orderType)

    @action getFormattedAddressByOrderType = (orderType: string) => this.dependencies.addressManager?.getFormattedAddressByOrderType(orderType)

    @action getValidateAddressResponseByOrderType = (orderType: string, showLoader = false) => this.dependencies.addressManager?.getValidateAddressResponseByOrderType(orderType, showLoader)

    @action getFullAddressByOrderType = (orderType: string) => this.dependencies.addressManager?.getFullAddressByOrderType(orderType) as LegacyAddressByOrderType

    @action getAddressWhenLocalized = () => this.dependencies.addressManager?.getAddressWhenLocalized()

    @action getLocalizedOrderType = () => this.dependencies.addressManager?.getLocalizedOrderType()

    @action getTempFormattedAddressByOrderType = (orderType: string) => this.dependencies.addressManager?.getTempFormattedAddressByOrderType(orderType) as string
    
    @action makeLocalizedForOrderTypeAndStoreId = (wru: string, pc: string, orderType: string, storeId: string, ref: any) => this.dependencies.addressManager?.makeLocalizedForOrderTypeAndStoreId(wru, pc, orderType, storeId, ref)

    @action onMenuClickWhenLocalized = (cartStore: Cart, User: typeof _User) => this.dependencies.addressManager?.onMenuClickWhenLocalized(cartStore, User)

    @action handleForceLocalization = (storeId: string, User: typeof _User, cartStore: Cart, Home: typeof _Home, Infra: typeof _Infra, router: NextRouter, setStore: Dispatch<SetStateAction<Store>>) => this.dependencies.addressManager?.handleForceLocalization(storeId, User, cartStore, Home, Infra, router, setStore)

    @action resetLocalization = () => this.dependencies.addressManager?.resetLocalization()

    @action storeMenuUrlToLS = (menuUrl: string) => this.dependencies.addressManager?.storeMenuUrlToLS(menuUrl)

    @action clearMenuUrlCache = () => this.dependencies.addressManager?.clearMenuUrlCache()

    @action setShowLocalizedAddress = (showLocalizedAddress: boolean) => this.dependencies.addressManager?.setShowLocalizedAddress(showLocalizedAddress)

    getAddressFromLocaleStorage = () => this.dependencies.addressManager?.getAddressFromLocaleStorage() as LegacyAddress

    getLocalizedStoreId = () => this.dependencies.addressManager?.getLocalizedStoreId()



    
    // Props and methods from the new localization flow

    @observable orderTypeFlows: OrderTypeFlow[] = []

    @action setOrderTypeFlows = (orderTypeFlows: OrderTypeFlow[]) => {}

    @observable orderTypeFlow: OrderTypeFlow | null = null

    @action setOrderTypeFlow = (orderTypeFlow: OrderTypeFlow) => {}

    @observable store: NewFlowStore | null = null

    @action setStore = (store: NewFlowStore) => {}

    @observable addressDetailsTypes: AddressDetails[] = []

    @action setAddressDetailsTypes = (addressDetailsTypes: AddressDetails[]) => {}

    @observable address: Address | null = null

    @action setAddress = (address: Address) => {}

    @action setAddressLocation = (formatted: string, location: Location) => {}

    @action setAddressDetails = (details: AddressDetails) => {}

    @observable savedAddress: Address[] = []

    @action addToSavedAddress = (address: Address) => {}

    @action removeSavedAddress = (addressId: string) => {}

    @action start = () => {}

    @action abort = () => {}

    @action end = () => {}

    @action nextStep = () => {}

    @action backStep = () => {}

    @action goToStep = (step: FlowStep) => {}
}