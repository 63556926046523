import React from 'react'
import { getTranslatedTextByKey, redirectToChainMenu } from 'utils/utils'
import { ERROR_IMAGE_ROUTE } from 'utils/staticFilesRoutes'
import { Logo } from 'components/common/Logo'
import {
	ErrorPageContainer,
	ErrorPageContent,
	ErrorPageContentButton,
	ErrorPageContentDescription,
	ErrorPageContentImage,
	ErrorPageContentSubTitle,
	ErrorPageContentTitle,
	ErrorPageHeaderButton,
	ErrorPageOneButtonHeader,
	GENERIC_ERROR_DESCRIPTION,
	GENERIC_ERROR_SUBTITLE,
	GENERIC_ERROR_TITLE,
} from 'static/components/errors/ErrorPagesSharedComponents'
import { getThemeUrl } from 'utils/theme/themeManager'
import { inject, observer } from 'mobx-react'
import type _Infra from 'mobx/Infra'
import { useRouter } from 'next/router'

interface GenericErrorPageProps {
	Infra: typeof _Infra
	User: any
}

const GenericErrorPageNextJS = inject(
	'User',
	'Infra'
)(
	observer(({ User, Infra }: GenericErrorPageProps) => {
		const { theme } = Infra.appParams
		const { c } = Infra.appParams
		const { brand } = Infra.appParams
		const l = User.preferredLanguage || Infra.appParams.l
		const router = useRouter()

		const imgPath = `${getThemeUrl(theme || 'generic', c, brand)}/assets`

		return (
			<ErrorPageContainer>
				<ErrorPageOneButtonHeader>
					<Logo />
					<ErrorPageHeaderButton $type="secondary" onClick={() => (window.location.href = `/`)} fullWidth>
						{getTranslatedTextByKey('conversationText.webviewFlow.backToHomepage', 'Back to homepage')}
					</ErrorPageHeaderButton>
				</ErrorPageOneButtonHeader>
				<ErrorPageContent>
					<ErrorPageContentImage src={`${imgPath}${ERROR_IMAGE_ROUTE}`} alt="error image" />
					<ErrorPageContentTitle variant="HomePageTitle" component="h1">
						{getTranslatedTextByKey('eCommerce.errorPages.genericErrorTitle', GENERIC_ERROR_TITLE)}
					</ErrorPageContentTitle>
					<ErrorPageContentSubTitle variant="SubheadHighlighted">
						{getTranslatedTextByKey('eCommerce.errorPages.genericErrorSubtitle', GENERIC_ERROR_SUBTITLE)}
					</ErrorPageContentSubTitle>
					<ErrorPageContentDescription variant="Subhead" component="div">
						{getTranslatedTextByKey('eCommerce.errorPages.genericErrorDescription', GENERIC_ERROR_DESCRIPTION)}
					</ErrorPageContentDescription>
					<ErrorPageContentButton onClick={() => redirectToChainMenu(l, router)} $size="big">
						{getTranslatedTextByKey('webviewFlow.ok', 'OK')}
					</ErrorPageContentButton>
				</ErrorPageContent>
			</ErrorPageContainer>
		)
	})
)

export default GenericErrorPageNextJS
