import type _Infra from 'mobx/Infra'
import { sendRequest } from 'utils/utils'
import type { StoreFrontResponse } from './types'
import { storeUrls } from 'utils/api/storefrontUrls'
import { CONSTANTS } from 'utils/constants'

export const getStoreInfo = async (storeId: string, Infra: typeof _Infra): Promise<StoreFrontResponse> => {
	const storeFrontUrl = Infra?.appParams.storefrontUrl
	if (!storeFrontUrl) {
		throw new Error('Storefront URL is missing')
	}

	const urls = await storeUrls(storeId)

	return (await sendRequest(
		false,
		`${urls.storeDetailsUrl}&channel=${CONSTANTS.CHANNEL_TYPE.WEB}&$pick=availability,status,orderTypes,location,title,id`,
		'GET',
		null,
		null,
		true,
		90000,
		null,
		false
	)) as StoreFrontResponse
}
