import type { SnackBarMessageType } from 'components/common/coupons/types'
import type { THEME_STRATEGY } from 'constants/theme'
import type { CountryCode } from 'libphonenumber-js'
import type React from 'react'
import type { Page } from 'utils/constants'
import type { Brand } from 'utils/theme/GlobalStyle'
import type { DEV, PROD, STAGING, TICTUK_TESTS, LOCAL } from 'types/Env'
import type { LanguageCode } from 'utils/language'

export interface LoginMethods {
	emailSupported: boolean
	phoneSupported: boolean
}

export enum LoginMode {
	PhoneOnly = 'phoneOnly',
	EmailOnly = 'emailOnly',
	EmailOrPhone = 'emailOrPhone',
}
// This will be shared with the backend once we share libs
export enum IdentifierType {
	Phone = 'PHONE',
	Email = 'EMAIL',
}

export enum VerificationStrategyName {
	Otp = 'OTP',
}

export interface VerificationStrategy {
	name: VerificationStrategyName
}

export interface AuthIdentifier {
	type: IdentifierType
	verificationStrategies?: VerificationStrategy[]
}

export enum SnackbarStatus {
	INFO = 'info',
	ERROR = 'error',
	WARN = 'warning',
	SUCCESS = 'success',
	DEAL = 'deal',
}

export interface ShowSnackbarProps {
	key?: string
	snackId: string
	message: SnackBarMessageType
	status: SnackbarStatus
	isAttachedToElement?: boolean
	duration?: number
}

export interface SnackBar {
	snackObj: {
		id: string
		isOpen: boolean
		refEl: React.Ref<HTMLElement>
	}
	message: string
	status: string
	positionAndSize: DOMRect
	isOpen: boolean
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Guard {
	export enum Context {
		wallet = 'wallet',
		account = 'account',
	}

	export enum Type {
		page = 'pages',
		component = 'components',
		event = 'events',
	}

	export enum Name {
		account = 'account',
		applyCoupon = 'applyCoupon',
	}
}

export enum DeviceType {
	Mobile = 'mobile',
	Desktop = 'desktop',
}

export type EventListener = (evt: Event) => void

interface Activity {
	id: string
	device: DeviceType[]
}

export interface SessionConfiguration {
	requestInterval: number
	activities: Activity[]
}

export interface TenantDetails {
	authIdentifiers: AuthIdentifier[]
}

export interface AppParams {
	title: string
	name: string
	c: string
	pc: string
	theme: THEME_STRATEGY
	brand: Brand
	logo: string
	wru: string
	wruec: string
	storefrontUrl: string
	favicon: string
	cust: string
	l: LanguageCode
	currency: string
	useProductionMenu: boolean
	useProductionTheme: boolean
	environment: typeof PROD | typeof STAGING | typeof DEV | typeof LOCAL | typeof TICTUK_TESTS // Injected from the server
	ref?: string | object
	locale: {
		region: CountryCode
	}
	pageText: Record<
		Page,
		{
			title: Record<string, string>
			description: Record<string, string>
			hidden: boolean | undefined
		}
	>
	assets?: {
		homepageBackgroundDesktop: string
		homepageBackgroundMobile: string
	}
	features: Record<string, any>
	cartRelocationEnabled?: boolean
	eCommerce?: boolean
	headlessBrowser: boolean
	setAppParams: (appParams: AppParams) => void
	mobileApp?: {
		navigationBarData: any
		bundleId?: string
		_deepLinks?: Record<string, string>
	}
	guard?: Record<
		Guard.Context,
		Record<
			Guard.Type,
			[
				{
					name: Guard.Name | Page
					roles: [
						{
							action: 'loginProtected'
							type: 'authenticated'
						}
					]
				}
			]
		>
	>
	sessionConfiguration?: SessionConfiguration
}
