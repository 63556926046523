import { getTranslatedTextByKey } from 'utils/utils'

export const buildChannelsErrorText = (discount: { channels: string[] }) => {
	let message = ''
	const channelIncludesApp = discount.channels.includes('App')
	const channelIncludesWeb = discount.channels.includes('Web')
	const channelIncludesTelegram = discount.channels.includes('Telegram')
	const channelIncludesWhatsapp = discount.channels.includes('WhatsApp')
	const channelIncludesMessenger = discount.channels.includes('Messenger')
	const channelIncludesAnyChatChannel = channelIncludesTelegram || channelIncludesWhatsapp || channelIncludesMessenger

	if (channelIncludesApp && channelIncludesWeb && channelIncludesAnyChatChannel) {
		return ''
	}

	if (channelIncludesApp) {
		message += ` ${getTranslatedTextByKey('application', 'application')}`
	}

	if (channelIncludesWeb) {
		if (channelIncludesApp && !channelIncludesAnyChatChannel) {
			message += ` and`
		} else if (channelIncludesApp) {
			message += `,`
		}
		message += ` ${getTranslatedTextByKey('website', 'website')}`
	}

	if (channelIncludesTelegram) {
		if (message !== '' && !channelIncludesWhatsapp && !channelIncludesMessenger) {
			message += ` and`
		} else if (channelIncludesApp || channelIncludesWeb) {
			message += `,`
		}

		message += ` ${getTranslatedTextByKey('telegram', 'telegram')}`
	}

	if (channelIncludesWhatsapp) {
		if (message !== '' && !channelIncludesMessenger) {
			message += ` and`
		} else if (message !== '') {
			message += `,`
		}

		message += ` ${getTranslatedTextByKey('whatsapp', 'whatsapp')}`
	}

	if (channelIncludesMessenger) {
		if (message !== '') {
			message += ' and'
		}

		message += ` ${getTranslatedTextByKey('messenger', 'messenger')}`
	}

	return message
}
