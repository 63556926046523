import type { Exception } from 'shared-types/Coupon'
import type Hour from './hour'
import type SmartRange from './smart-range'

export enum Day {
	Sunday,
	Monday,
	Tuesday,
	Wednesday,
	Thursday,
	Friday,
	Saturday,
}

type HourSuffix = 0 | 1 | 2 | 3
type MinutePrefix = HourSuffix | 4 | 5
type Digit = MinutePrefix | 6 | 7 | 8 | 9
type MinuteNumber = `${Digit}` | `${MinutePrefix}${Digit}`
type HourNumber = `${Digit}` | `${0 | 1}${Digit}` | `2${HourSuffix}`

export type DateString = `${number}-${number}-${number}`
export type HourString = `${HourNumber}:${MinuteNumber}`

export interface Range {
	start: Hour
	end: Hour
}

type Week = Record<Day, SmartRange>
export type { Week as FormattedWeek }
export type FormattedException = SmartRange & Omit<Exception, keyof Range>
export type Exceptions = Partial<Record<DateString, FormattedException>>

export interface FormattedAvailability {
	weekly: string[]
	exceptions: string[]
}
