import type CouponsStore from 'mobx/Coupons'
import type CouponFlowStore from 'mobx/CouponFlow'
import { createContext } from 'react'
import type CartStore from 'mobx/Cart/store'
import type AddressManagerV2Store from 'mobx/AddressManagerV2/store'

export interface Stores {
	couponsStore: CouponsStore
	couponFlowStore: CouponFlowStore
	cartStore: CartStore
	addressManagerV2Store: AddressManagerV2Store
}

const StoresContext = createContext<Stores | undefined>(undefined)

export default StoresContext
