import React, { useEffect, useState } from 'react'
import type { LocalizedCoupon } from 'types/Coupons'
import CouponAvailability, { AvailabilityState } from './Availability'
import OpenInChip, { OpenInVariant } from 'components/common/OpenInChip'
import { inject, observer } from 'mobx-react'
import TypographyPro from 'themes/TypographyPro'
import { getTranslatedTextByKey } from 'utils/language'
import styled from 'styled-components'
import { translateChannel } from 'mobx/Coupons/utils'
import { isAndroid, isiOS, sendRequest } from 'utils/utils'
import IconComponent from 'themes/IconComponent'
import { sendCustomEvent } from 'utils/analytics/analytics'
import { Channel } from 'shared-types/Coupon'
import type _Infra from 'mobx/Infra'
import { tenantUrls, TenantUrlPickOptions } from 'utils/api/storefrontUrls'

interface Props {
	coupon: LocalizedCoupon
	currentChannel: Channel
	redirectText?: string
	Infra?: typeof _Infra
}

const channelToIcon: Record<Channel, JSX.Element | null> = {
	[Channel.APP]: <IconComponent asset="/icons/channel_app.svg" />,
	[Channel.WHATSAPP]: <IconComponent asset="/icons/channel_whatsapp.svg" />,
	[Channel.WEB]: <IconComponent asset="/icons/channel_web.svg" />,
	[Channel.TELEGRAM]: <IconComponent asset="/icons/channel_telegram.svg" />,
	[Channel.MESSENGER]: <IconComponent asset="/icons/channel_messenger.svg" />,
	[Channel.KIOSK]: <IconComponent asset="/icons/channel_kiosk.svg" />,
}

const channelToVariant: Record<Channel, OpenInVariant[]> = {
	[Channel.APP]: [OpenInVariant.AppStore, OpenInVariant.PlayStore],
	[Channel.WHATSAPP]: [OpenInVariant.Whatsapp],
	[Channel.TELEGRAM]: [],
	[Channel.KIOSK]: [],
	[Channel.MESSENGER]: [],
	[Channel.WEB]: [],
}

const supportedChannels: Channel[] = [Channel.WHATSAPP, Channel.APP, Channel.WEB]

const Chip = styled.div`
	padding: 0.25rem 0.5rem;
	background: var(--optionalBackground);
	border-radius: 4px;
	width: fit-content;
	display: flex;
	align-items: center;
	gap: 4px;
`

const Text = styled(TypographyPro).attrs({ variant: 'BodySmall' })`
	color: var(--strokeMenuTitle);
`

const ChipContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 0.5rem;
	max-width: 50%;
`

const OpenInContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	gap: 0.5rem;
`

const ChannelAvailability: React.FC<Props> = ({ Infra, coupon, currentChannel, redirectText }) => {
	const { channels } = coupon
	const available = channels.includes(currentChannel)
	const [phoneNumber, setPhoneNumber] = useState<string | null>(null)

	useEffect(() => {
		if (!Infra) {
			return
		}
		const fetchWhatsAppPhoneNumber = async () => {
			const urls = await tenantUrls([TenantUrlPickOptions.TenantDetailsURL])
			const data = await sendRequest(false, `${urls.tenantDetailsURL}&$pick=whatsAppPhoneNumber`, 'get', null, null, true, 90000, null, false)
			setPhoneNumber((data as { whatsAppPhoneNumber: string }).whatsAppPhoneNumber)
		}

		fetchWhatsAppPhoneNumber()
	}, [])

	const buildUrl = (variant: OpenInVariant): string | null => {
		switch (variant) {
			case OpenInVariant.Whatsapp:
				return `https://wa.me/${phoneNumber}?text=${encodeURIComponent(redirectText ?? '')}`
			case OpenInVariant.AppStore:
				return Infra?.eCommerceFooter.apps?.ios?.link ?? null
			case OpenInVariant.PlayStore:
				return Infra?.eCommerceFooter.apps?.android?.link ?? null
			default:
				throw new Error(`Unknown variant: ${variant}`)
		}
	}

	return (
		<CouponAvailability
			icon={channelToIcon[currentChannel]!}
			title={getTranslatedTextByKey(
				`eCommerce.coupons.availability.${available ? 'availableFor' : 'notAvailableFor'}.${currentChannel.toLowerCase()}`,
				`${available ? 'Available' : 'Unavailable'} for ${translateChannel(currentChannel)}`
			)}
			state={available ? AvailabilityState.Available : AvailabilityState.Unavailable}
		>
			{available ? (
				<ChipContainer>
					{channels
						.filter((channel) => supportedChannels.includes(channel))
						.map((channel) => (
							<Chip key={channel} data-testid="channel-type-chip">
								{channelToIcon[channel]}
								<Text>{translateChannel(channel)}</Text>
							</Chip>
						))}
				</ChipContainer>
			) : (
				channels
					.filter((channel) => channel !== currentChannel && supportedChannels.includes(channel))
					.map((channel) => ({ channel, variants: channelToVariant[channel] }))
					.filter(({ variants }) => variants.length)
					.map(({ channel, variants }) => (
						<>
							<Text>
								{getTranslatedTextByKey(
									`eCommerce.coupons.availability.availableFor.${channel.toLowerCase()}`,
									`Available for ${translateChannel(channel)}`
								)}
								:
							</Text>
							<OpenInContainer data-testid="external-links-buttons-container">
								{variants
									.filter((variant) => {
										if (isiOS()) {
											return variant !== OpenInVariant.PlayStore
										}

										if (isAndroid()) {
											return variant !== OpenInVariant.AppStore
										}

										return true
									})
									.filter((variant) => buildUrl(variant))
									.map((variant) => (
										<OpenInChip
											onClick={() => {
												sendCustomEvent({
													category: 'download_app_button',
													action: 'clicked',
													page_path: window.location.pathname,
													download_platform: variant,
													coupon_code: coupon.code,
													coupon_name: coupon.title,
													coupon_price: coupon.price,
													order_type: coupon.orderTypes.join(', '),
													offer_type: coupon.offerType,
												})
											}}
											key={variant}
											variant={variant}
											url={buildUrl(variant)!}
										/>
									))}
							</OpenInContainer>
						</>
					))
			)}
		</CouponAvailability>
	)
}

export default inject('Infra')(observer(ChannelAvailability)) as React.FC<Props>
