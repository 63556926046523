import Session from 'mobx/Session'

const isPushNotificationDeclined = () => {
	const lastDate = localStorage.getItem('pushNotificationsDeclineDate')

	if (lastDate) {
		const lastDeclineDate = new Date(parseInt(lastDate))
		const now = new Date()

		const timeDiff = now.getTime() - lastDeclineDate.getTime()
		const daysPassed = timeDiff / (1000 * 3600 * 24)

		if (daysPassed < 21) {
			return false
		}
	}

	return true
}

const resetLocalStorage = () => {
	const deviceUniqueId = localStorage.getItem('deviceUniqueId')
	const pushNotificationsDeclineDate = localStorage.getItem('pushNotificationsDeclineDate')
	const pushNotificationsStatus = localStorage.getItem('pushNotificationsStatus')
	const pushNotificationsToken = localStorage.getItem('pushNotificationsToken')
	const forceMobileApp = localStorage.getItem('forceMobileApp')
	localStorage.clear()
	if (deviceUniqueId) {
		localStorage.setItem('deviceUniqueId', deviceUniqueId)
	}
	if (pushNotificationsDeclineDate) {
		localStorage.setItem('pushNotificationsDeclineDate', pushNotificationsDeclineDate)
	}
	if (pushNotificationsStatus) {
		localStorage.setItem('pushNotificationsStatus', pushNotificationsStatus)
	}
	if (pushNotificationsToken) {
		localStorage.setItem('pushNotificationsToken', pushNotificationsToken)
	}
	if (forceMobileApp) {
		localStorage.setItem('forceMobileApp', forceMobileApp)
	}
}

const getSessionId = () => localStorage.getItem('sessionId')

const getPushNotificationsStatus = () => localStorage.getItem('pushNotificationsStatus')

export default {
	isPushNotificationDeclined,
	getPushNotificationsStatus,
	getSessionId,
	resetLocalStorage,
	initOrUpdateSession: Session.initOrUpdateSession,
}
