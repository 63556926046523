export enum Channel {
    WHATSAPP = 'WhatsApp',
    MESSENGER = 'Messenger',
    WEB = 'Web',
    APP = 'App',
    TELEGRAM = 'Telegram',
    KIOSK = 'Kiosk'
}

export enum OfferType {
    ITEM = 'item',
    FIXED_DISCOUNT = 'fixedDiscount',
    PERCENTAGE_DISCOUNT = 'percentageDiscount',
    ITEM_DISCOUNT = 'itemDiscount',
    SPECIAL = 'special'
}

export enum CouponSource {
    INTERNAL = 'internal',
    EXTERNAL = 'external',
    INDIVIDUAL = 'individual'
}

export enum CouponStatus {
    REDEEMED = 'REDEEMED',
    EXPIRED = 'EXPIRED',
    AVAILABLE = 'AVAILABLE'
}

export enum OrderType {
    IN_STORE = 'inStore',
    DELIVERY = 'delivery',
    PICKUP = 'pickup',
    DRIVETHROUGH = 'driveThru'
}

type Flag<T, U = unknown> = { value: T } & Omit<U, 'value'>

export enum DiscountInactiveReasonCode {
    MIN_ORDER_PRICE_NOT_MET = 1,
    MISSING_SPECIFIC_ITEMS = 2,
    MISSING_ITEMS_FROM_SECTION = 3,
    MAX_ORDER_PRICE_EXCEEDED = 4,
    UNKNOWN = 5 // should not be used
}

export interface Flags {
    // Server-Side Flags
    requireLogin: Flag<boolean>
    showInMyCoupons: Flag<boolean>

    // Client-Side Flags
    wallet: Flag<boolean>
    applied: Flag<boolean>
    active: Flag<boolean, { reasonCode?: DiscountInactiveReasonCode }>
}

export enum ApplyTo {
    ALL = 'all',
    DISHES = 'dishes',
    SECTIONS = 'sections'
}

const localeToCode = {
    en_US: 'en',
    he_IL: 'iw',
    ar_IL: 'ar',
    ru_RU: 'ru',
    pt_PT: 'pt',
    es_ES: 'es',
    fr_FR: 'fr',
    pap_AW: 'pap',
    de_DE: 'de',
    fi_FI: 'fi'
} as const

type LanguageLocale = keyof typeof localeToCode

export interface WeeklyAvailability {
    minuteOfWeek: number
    durationMins: number
}

export interface Exception {
    start: Date
    end: Date
    available: boolean
    comment: Partial<Record<LanguageLocale, string>>
}

export interface Availability {
    weekly: WeeklyAvailability[]
    exceptions: Exception[]
}

interface BaseCoupon {
    id: string
    title: Record<string, string>
    description: Record<string, string>
    code: string
    expiration?: Date
    formattedExpiration?: string
    img: string
    orderTypes: OrderType[]
    channels: Channel[]
    offerType: OfferType
    source: CouponSource
    status: CouponStatus
    price: number
    flags: Partial<Flags>
    availability: Availability
    timestamp: Date
    applyTo?: ApplyTo
    itemOrSectionIds?: string[]
    minOrderPrice?: number
    maxOrderPrice?: number
}

export interface IndividualCoupon extends Omit<BaseCoupon, 'source'> {
    totalUses: number
    usesLeft: number
    parentCode: string
    source: CouponSource.INDIVIDUAL
    attachedAt?: Date
}

export interface IntegrationData {
    type: string
    holders: { id: string; name: string }[]
}

export type SpecialDiscount = BaseCoupon & {
    integrationData: IntegrationData
}

export type Coupon = IndividualCoupon | BaseCoupon | SpecialDiscount

export interface ExternalCoupon extends Omit<BaseCoupon, 'source'> {
    source: CouponSource.EXTERNAL
    sequence: number
    orderTypes:
        | [OrderType.DRIVETHROUGH]
        | [OrderType.IN_STORE]
        | [OrderType.IN_STORE, OrderType.DRIVETHROUGH]
        | [OrderType.DRIVETHROUGH, OrderType.IN_STORE]
}
