import type React from 'react'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useCurrentUrlInfo } from 'hooks/useCurrentUrlInfo'
import { useStores } from 'hooks/useStores'
import { inject, observer } from 'mobx-react'
import { readCouponDetailsFromUrl } from 'mobx/CouponFlow/utils'
import { CheckCouponOn } from 'mobx/CouponFlow'
import { CONSTANTS } from 'utils/constants'
import type _Infra from 'mobx/Infra'
import type _User from 'mobx/User'
import type _Home from 'mobx/Home'
import { StoreContext } from 'contexts/StoreContext'
import { useRouter } from 'next/router'
import { OrderType } from 'shared-types/Coupon'
import LocalizationError from 'mobx/AddressManager/errors'

const UrlActionHandler: React.FC<{
	Infra?: typeof _Infra
	User?: typeof _User
	Home?: typeof _Home
}> = ({ Infra, User, Home }) => {
	const { pathname, query: clientQuery, dynamicRouteQuery, hash, removeHash, asPath } = useCurrentUrlInfo()
	const router = useRouter()

	const handledHashes = useRef<Set<string>>(new Set())
	const [utmQueryParameters, setUtmQueryParameters] = useState<Record<string, string | string[]>>({})

	const forceLocalizationProcess = useRef<boolean>(false)

	const { couponFlowStore, cartStore, addressManagerV2Store } = useStores()
	const { setStore } = useContext(StoreContext)

	const handleExternalCouponFromUrlLegacy = useCallback(async () => {
		if (couponFlowStore.couponToApply || !hash || (hash && handledHashes.current.has(hash))) {
			return
		}

		const couponDetails = readCouponDetailsFromUrl({ query: clientQuery, hash })

		if (couponDetails) {
			handledHashes.current.add(hash)
			removeHash()
			await couponFlowStore.start({
				code: couponDetails.code,
				orderTypeToForce: couponDetails.orderTypeToForce,
				openModal: true,
			})
		}
	}, [couponFlowStore, clientQuery, hash, removeHash])

	const handleForceLocalization = useCallback(
		async (storeId: string) => {
			if (!storeId || !addressManagerV2Store || !User || !cartStore || !Home || !Infra) {
				return
			}
			await addressManagerV2Store.handleForceLocalization(storeId, User, cartStore, Home, Infra, router, setStore)
		},
		[addressManagerV2Store, User, cartStore, Home, Infra, router, setStore]
	)

	const showError = useCallback(
		(message: string) => {
			Infra?.setNotification?.({
				open: true,
				title: message,
				message: '',
				onClose: () => {
					Infra?.closeNotification()
				},
				okAction: () => {
					Infra?.closeNotification()
				},
			})
		},
		[Infra]
	)

	const handleUtmParameters = useCallback(async () => {
		const utmParameters: Record<string, string | string[]> = {}
		let hasUtmParameters = false

		clientQuery.forEach((value, key) => {
			if (key.startsWith('utm_')) {
				hasUtmParameters = true
				if (!utmQueryParameters[key]) {
					utmParameters[key] = value
				}
			}
		})

		const utmParametersExist = Object.keys(utmQueryParameters).length > 0

		if (hasUtmParameters && !utmParametersExist) {
			setUtmQueryParameters({ ...utmQueryParameters, ...utmParameters })
		} else if (!hasUtmParameters && utmParametersExist) {
			await router.replace({ pathname, query: { ...dynamicRouteQuery, ...utmQueryParameters } }, undefined, { shallow: true })
		}
	}, [clientQuery, utmQueryParameters, dynamicRouteQuery, pathname, router])

	const handleLocalizationFlow = useCallback(async () => {
		if (!clientQuery.has('forceLocalization') || clientQuery.get('forceLocalization') !== 'true' || forceLocalizationProcess.current) {
			return
		}

		try {
			forceLocalizationProcess.current = true

			const excludedKeys = new Set(['forceLocalization', 'storeId', 'couponCode'])
			const modifiedQuery: Record<string, string | string[]> = {}
			const storeId = clientQuery.get('storeId')
			const couponCode = clientQuery.get('couponCode')

			clientQuery.forEach((value, key) => {
				if (excludedKeys.has(key)) {
					return
				}

				if (!modifiedQuery[key]) {
					modifiedQuery[key] = value
					return
				}

				if (Array.isArray(modifiedQuery[key])) {
					;(modifiedQuery[key] as string[]).push(value)
				} else {
					modifiedQuery[key] = [modifiedQuery[key] as string, value]
				}
			})

			await router.replace({ pathname, query: { ...dynamicRouteQuery, ...modifiedQuery } }, undefined, { shallow: true })

			const userLocalized = User && addressManagerV2Store?.isUserLocalized()
			const currentStoreId = addressManagerV2Store?.getLocalizedStoreId()
			const currentOrderTypePickup = addressManagerV2Store?.localizedAddress?.localizedOrderType === CONSTANTS.ORDER_TYPE.PICKUP

			// User already localized to the store, in pickup delivery
			if (userLocalized && currentStoreId === storeId && currentOrderTypePickup) {
				const { menuRes, storeMetaData, serverSession, menuUrl } = await addressManagerV2Store.onMenuClickWhenLocalized(cartStore, User)
				await User.setSession(serverSession)
				setStore((store) => ({ ...store, data: menuRes, metaData: storeMetaData }))
				await router.push(menuUrl)
			} else {
				await handleForceLocalization(storeId ?? '')
			}

			if (couponCode) {
				await couponFlowStore.start({
					code: couponCode,
					orderTypeToForce: OrderType.PICKUP,
					openModal: true,
					checkCouponOn: CheckCouponOn.STORE,
				})
			}
		} catch (error) {
			let message = ''
			if (error instanceof LocalizationError) {
				message = error.text()
			} else if (error instanceof Error) {
				message = error.message
			} else {
				message = 'Something went wrong, please try again.'
			}

			// remove query params from the URL in order to avoid infinite loop of error message
			await router.replace({ pathname: asPath.split('?')[0] ?? '/home' }, undefined, { shallow: true })
			showError(message)
		} finally {
			forceLocalizationProcess.current = false
		}
	}, [clientQuery, dynamicRouteQuery, pathname, router, asPath, handleForceLocalization, couponFlowStore, showError])

	useEffect(() => {
		const handleRouteChange = async () => {
			if (hash?.startsWith(CONSTANTS.URL_QUERY_HASH_KEY.ITEM) || hash?.startsWith(CONSTANTS.URL_QUERY_HASH_KEY.DISCOUNT)) {
				await handleExternalCouponFromUrlLegacy()
			} else {
				await handleUtmParameters()
				await handleLocalizationFlow()
			}
		}

		handleRouteChange()
	}, [handleExternalCouponFromUrlLegacy, handleUtmParameters, handleLocalizationFlow, hash])

	return null // This component does not render anything
}

export default inject('Infra', 'User', 'Home')(observer(UrlActionHandler))
