import { getTenantInfo, sendRequest } from 'utils/utils'
import Infra from '../../mobx/Infra'

interface tenantUrls {
	storeAndMenuJsonURL: string
	webFlowAddressURL: string
	tenantDetailsURL: string
}

interface storeUrls {
	storeAndMenuJsonURL: string
	storeDetailsUrl: string
}

export enum TenantUrlPickOptions {
	WebFlowAddressURL = 'webFlowAddressURL',
	StoreAndMenuJsonURL = 'storeAndMenuJsonURL',
	TenantDetailsURL = 'tenantDetailsURL',
}

export const storeUrls = async (storeId: string): Promise<storeUrls> =>
	sendRequest(
		true,
		`${Infra?.appParams?.storefrontUrl || process.env.STOREFRONT_API_URL}/v1/stores/${storeId}/urls`,
		'get',
		null,
		null,
		true,
		90000,
		null,
		false
	) as Promise<storeUrls>

export const tenantUrls = async (pickOptions: TenantUrlPickOptions[], tenantId?: string): Promise<tenantUrls> => {
	const pickQuery = pickOptions.join(',')
	return sendRequest(
		true,
		`${Infra?.appParams?.storefrontUrl || process.env.STOREFRONT_API_URL}/v1/tenants/${tenantId || getTenantInfo()}/urls?$pick=${pickQuery}`,
		'get',
		null,
		null,
		true,
		90000,
		null,
		false
	) as Promise<tenantUrls>
}
