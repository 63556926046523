import { observable, action, runInAction } from 'mobx'
import { isNumber } from 'lodash-es'
import Cookies from 'js-cookie'
import nextCookiesState from '../../../utils/nextCookiesState'
import { fetchLanguageFile } from 'utils/utils'
import { CONSTANTS, ORDER_TYPES, ORDER_TYPES_QUERY_PARAMS } from 'utils/constants'
import oStorage from 'utils/o-storage'
import UserDependencies from './UserDependencies'
import type { LanguageCode } from 'utils/language'

class User {
	dependencies: any = null

	@observable session: any = {}

	@observable lastAddress: any = null

	@observable orderType: number = CONSTANTS.DELIVERY_METHODS.DELIVERY

	/**
	 * @type {string | null}
	 */
	@observable userLocaleLanguage: any = null

	@observable fbCheckboxOptedIn: any = false

	// used to store a non-signed-in user's preferred language
	@observable preferredLanguage: LanguageCode | null = null

	constructor(dependencies: any) {
		this.dependencies = dependencies
		runInAction(() => {
			this.reset()
		})
	}

	@action close = () => {
		this.reset()
	}

	reset = () => {
		this.session = {}
	}

	@action setFbCheckboxOptedIn = () => {
		this.fbCheckboxOptedIn = true
	}

	@action setSession = async (session: any) => {
		if (Object(session).hasOwnProperty('orderType')) {
			this.setOrderType(session.orderType)
		}
		if (session.phone === '0') {
			session.phone = ''
		}
		this.session = session
	}

	@action setLanguages = async (locale: LanguageCode, chainId: string) => {
		// dynamically import the user's locale's lang file
		const languages = await fetchLanguageFile(chainId, locale)
		this.userLocaleLanguage = languages
		this.preferredLanguage = locale
	}

	@action setLastAddress = (lastAddress: any) => {
		// we don't store this on the session since the session doesn't exist in the home-page. It's only loaded in the /menu page
		this.lastAddress = lastAddress
	}

	@action setSessionField = (field: any, value: any) => {
		if (field === 'orderType') {
			this.setOrderType(value)
		} else {
			this.session[field] = value
		}
	}

	@action setUserOptIn = (userOptIn: any) => {
		this.session.userOptIn = userOptIn
	}

	/**
	 * Classify by order type
	 * The function receive order type as a string and return the number of the enum key of it value
	 * Will be choosing between delivery | pickup | store | kiosk | road-side and more.
	 * default will be delivery type
	 * */
	classifyOrderType = (orderType: string | number): number => {
		switch (orderType) {
			case ORDER_TYPES.DELIVERY:
			case CONSTANTS.DELIVERY_METHODS.DELIVERY:
			case CONSTANTS.DELIVERY_METHODS.DELIVERY.toString():
			case ORDER_TYPES_QUERY_PARAMS.DELIVERY_CLASS:
				return CONSTANTS.DELIVERY_METHODS.DELIVERY

			case ORDER_TYPES.PEAKUP:
			case ORDER_TYPES.PICKUP:
			case CONSTANTS.DELIVERY_METHODS.PICKUP:
			case CONSTANTS.DELIVERY_METHODS.PICKUP.toString():
			case ORDER_TYPES_QUERY_PARAMS.PEAK_UP_CLASS:
				return CONSTANTS.DELIVERY_METHODS.PICKUP

			default:
				return CONSTANTS.DELIVERY_METHODS.DELIVERY
		}
	}

	@action setOrderType = (type: string | number) => {
		if (typeof type === 'string' || isNumber(type)) {
			if (!isNumber(type)) {
				type = this.classifyOrderType(type)
			}
			this.orderType = type
			if (typeof localStorage === 'object') {
				localStorage.setItem('currentDeliveryType', this.orderType.toString())
			}
			if (typeof window !== 'undefined') {
				nextCookiesState.fromClient.set('currentDeliveryType', this.orderType.toString(), Cookies)
			}
		}
	}

	@action getOrderType = () => {
		const storedOrderType = this.dependencies.getStoredOrderType()
		if (storedOrderType) {
			return this.classifyOrderType(storedOrderType)
		}

		return this.orderType
	}

	@action setDidUserOptInSession = (didUserOptInDuringSession: any) => {
		this.session.didUserOptInDuringSession = didUserOptInDuringSession || false
	}

	@action setPreferredLanguage = (language: any) => {
		this.preferredLanguage = language
		oStorage.set(CONSTANTS.USER.PREFERRED_LANGUAGE, language)
	}

	// We have this method instead of setting this in the constructor to prevent breaking the static pages in case they have a reference to this store
	@action init = (appParams: any) => {
		const storedOrderType = this.dependencies.getStoredOrderType()

		// /!\ We could take into consideration possible order types at a given time, some might be unavailable
		if (storedOrderType !== null) {
			this.setOrderType(this.classifyOrderType(storedOrderType))
		} else {
			this.setOrderType(this.classifyOrderType(appParams.features?.defaultDeliveryMethod))
		}

		const pl = oStorage.get(CONSTANTS.USER.PREFERRED_LANGUAGE)

		this.preferredLanguage = pl || appParams.l || 'en'
	}

	@action getUserConsistentId = () => this.dependencies.getUserConsistentId()

	@action setUserConsistentId = (userConsistentId: string) => {
		this.dependencies.setUserConsistentId(userConsistentId)
	}

	@action async fetchFirstPastOrdersPage(showLoader: any, header: any, tenantId: any, length: any) {
		return this.dependencies.fetchFirstPastOrdersPage(showLoader, header, tenantId, length)
	}

	@action async fetchNextPastOrdersPage(showLoader: any, header: any, url: any) {
		return this.dependencies.fetchNextPastOrdersPage(showLoader, header, url)
	}

	@action
	isChatApp = () => [CONSTANTS.APP.TYPES.WHATSAPP, CONSTANTS.APP.TYPES.MESSENGER, CONSTANTS.APP.TYPES.TELEGRAM].includes(this.session.appid)
}

export default new User(UserDependencies)
