// @ts-nocheck
import Big from 'big.js'

/** Recursive function to calculate total cost from variation choices.
 *  cost *must be an instance of Big*!
 */
export default function calculateTotalCostFromVariations(variationsChoices, cost) {
	if (variationsChoices && Array.isArray(variationsChoices)) {
		variationsChoices.forEach((_additionMap, _additionMapIdx) => {
			if (_additionMap) {
				Object.keys(_additionMap).forEach((_additionKey, _additionIdx) => {
					const addition = _additionMap[_additionKey]

					if (addition.price > 0) {
						const _quantity = addition.quantity || 1

						// some item prices are not whole number but decimals even though they are not supposed to have decimals!
						// eg item '8eaabbf7-cb69-53bc-2b7f-4c42b3d2737a' which is 'LARGE' has a price of 204.9999999999997 !!!!
						// so we round the value here to be sure and not break the calculation
						const roundedAdditionPrice = Math.round(addition.price)
						const additionBigPrice = new Big(roundedAdditionPrice).times(_quantity)
						cost = cost.plus(additionBigPrice)
					}

					if (addition.variationsChoices) {
						cost = calculateTotalCostFromVariations(addition.variationsChoices, cost)
					}
				})
			}
		})
	} else {
		console.warn(`${variationsChoices} is not an array!`)
	}

	return cost
}
