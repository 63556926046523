import type { FormattedAvailability } from 'mobx/Coupons/availability'
import React from 'react'
import styled from 'styled-components'
import TypographyPro from 'themes/TypographyPro'
import { getTranslatedTextByKey } from 'utils/language'
import CouponAvailability, { AvailabilityState } from './Availability'
import IconComponent from 'themes/IconComponent'

enum InternalHoursState {
	Expired = 'expired',
}

export const HoursState = { ...AvailabilityState, ...InternalHoursState }
export type HoursAvailabilityState = InternalHoursState | AvailabilityState

interface Props extends FormattedAvailability {
	expiration?: string
	state: HoursAvailabilityState
}

const Text = styled(TypographyPro).attrs({ variant: 'BodySmallHighlighted' })`
	color: var(--strokeMenuTitle);
`

const Title = styled(TypographyPro).attrs({ variant: 'BodySmall' })`
	color: var(--strokeMenuTitle);
`

const stateToTitle = (state: HoursAvailabilityState): string => {
	switch (state) {
		case AvailabilityState.Available:
			return getTranslatedTextByKey('eCommerce.coupons.availability.hoursAvailable', 'Coupon available at this time')
		case HoursState.Expired:
			return getTranslatedTextByKey('eCommerce.coupons.availability.hoursExpired', 'Coupon expired')
		case AvailabilityState.Unavailable:
			return getTranslatedTextByKey('eCommerce.coupons.availability.hoursNotAvailable', 'Coupon unavailable at this time')
		case AvailabilityState.Partial:
			return getTranslatedTextByKey('eCommerce.coupons.availability.hoursSoon', 'Coupon expires soon')
		default:
			return ''
	}
}

const HoursAvailability: React.FC<Props> = ({ weekly, exceptions, expiration, state }) => (
	<CouponAvailability
		state={state === HoursState.Expired ? AvailabilityState.Unavailable : state}
		title={stateToTitle(state)}
		icon={<IconComponent asset="/icons/available.svg" />}
	>
		<div>
			<Title>{getTranslatedTextByKey('eCommerce.coupons.availability.days', 'Availability:')}</Title>
			{weekly.map((day, index) => (
				<Text key={index}>{day}</Text>
			))}
		</div>
		{exceptions.length > 0 && (
			<div>
				{exceptions.map((exception, index) => (
					<Text key={index}>{exception}</Text>
				))}
			</div>
		)}
		{expiration && (
			<div>
				<Title data-testid="modal-expiry-text">{getTranslatedTextByKey('eCommerce.coupons.modal.expiry', 'Exp. date:')}</Title>
				<Text data-testid="modal-expiry-date">{expiration}</Text>
			</div>
		)}
	</CouponAvailability>
)

export default HoursAvailability
