import { getECommerceDomainByEnv, sendRequest } from '../../utils/utils'

const fetchFirstPastOrdersPage = async (showLoader: boolean, header: any, tenantId: string, length: number) => {
	try {
		return await sendRequest(
			showLoader,
			`${getECommerceDomainByEnv()}/v1/tenants/${tenantId}/users/me/orders?limit=${length}&page=1`,
			'get',
			{},
			header
		)
	} catch (error) {
		throw new Error(error as string)
	}
}

const fetchNextPastOrdersPage = async (showLoader: boolean, header: any, url: string) => {
	try {
		return await sendRequest(showLoader, url, 'get', {}, header)
	} catch (error) {
		console.error(error)
		return { data: [], paging: { page: 0, next: '', totalPages: 0 }, total: 0 }
	}
}

const getStoredOrderType = () => (typeof localStorage === 'object' ? localStorage.getItem('currentDeliveryType') : null)

const getUserConsistentId = () => localStorage.getItem('userConsistentId')
const setUserConsistentId = (userConsistentId: string) => localStorage.setItem('userConsistentId', userConsistentId)

export default { fetchFirstPastOrdersPage, fetchNextPastOrdersPage, getStoredOrderType, getUserConsistentId, setUserConsistentId }
