import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'
import { getTranslatedTextByKey, isiOS, routeToPage } from '../utils/utils'
import IconComponent from '../themes/IconComponent'
import Badge from '@material-ui/core/Badge'
import useWindowState from 'hooks/useWindowState'
import { Tab, Tabs } from '@material-ui/core'
import { getDefaultNavigationBarButtons } from '../../utils/nextUtils'
import kebabCase from 'lodash-es/kebabCase'
import { StoreContext } from 'contexts/StoreContext'
import { Page, HISTORY_WINDOW_STATE_ID, AUTHENTICATOR, ACCOUNT_TAB } from 'utils/constants'

import type _Application from 'mobx/Application'
import type _User from 'mobx/User'
import type _Infra from 'mobx/Infra'
import type _Home from 'mobx/Home'
import type _Account from 'mobx/Account'
import type _MobileApplication from 'mobx/MobileApplication'
import type { NextRouter } from 'next/router'
import { sendCustomEvent } from 'utils/analytics/analytics'
import { useStores } from 'hooks/useStores'

const StyledBadge = styled(Badge)`
	right: -3px;

	.MuiBadge-anchorOriginTopRightRectangular {
		top: 6px;
		right: 14px;
	}

	.MuiBadge-badge {
		height: 14px;
		padding: 0;
		min-width: 14px;
		justify-content: center;
		border-radius: 6px;
		line-height: 10px;
		background-color: var(--clear);
		color: var(--primary);
		border: 1px solid var(--primary);
		${({ theme }) => theme.typography.BodySmall};
	}
`

const BottomAppBar = styled(Tabs)<{ $isIPhoneX: boolean; $selected: boolean; $isiOs: boolean }>`
	width: 100%;
	cursor: pointer;
	background: var(--clear);
	box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.05);
	z-index: 100;
	bottom: 0;
	position: fixed;
	padding: 0 20px;
	height: ${({ $isiOs }) => ($isiOs ? '90px' : '65px')};
	padding-bottom: ${({ $isiOs }) => ($isiOs ? '25px' : '0')};
	align-items: flex-end;

	.MuiTabs-flexContainer {
		justify-content: center;
		height: 65px;
	}

	.MuiTabs-indicator {
		height: 100%;
		background-color: ${(props) => (props.$selected ? 'var(--primary)' : 'var(--clear)')};
		height: 60px;
		border-top-right-radius: 5px;
		border-top-left-radius: 5px;
	}
`

const BottomNavigationButton = styled(Tab)<{ $selected: boolean }>`
	&.MuiButtonBase-root {
		padding: 6px 0px 0 0px;
		height: 60px;
		min-width: unset;
		flex: 1;
		color: var(--appBotNavInactiveFontAndIcon);
		${({ theme }) => theme.typography.BodySmall};
		transition: color 0.5s;
		z-index: 1;
		//background-color: ${(props) => (props.$selected ? 'var(--primary)' : 'var(--clear)')};
		border-top-left-radius: ${(props) => (props.$selected ? '5px' : '0')};
		border-top-right-radius: ${(props) => (props.$selected ? '5px' : '0')};

		&.Mui-selected {
			color: var(--appBotNavActiveFontAndIcon);
		}

		&.MuiTab-labelIcon {
			min-height: unset;
			.MuiTab-wrapper {
				align-self: flex-end;
				svg,
				img {
					margin-bottom: 0;
				}
			}
		}
	}
`

const BottomNavigationItemText = styled.span`
	white-space: nowrap;
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
`

interface Props {
	history: NextRouter
	location: NextRouter
}

export const Footer = inject(
	'User',
	'Account',
	'Home',
	'MobileApplication',
	'Infra',
	'Application'
)(
	observer(
		({
			Application,
			User,
			Infra,
			Home,
			Account,
			MobileApplication,
			history,
			location,
		}: Props & {
			Application: typeof _Application
			User: typeof _User
			Infra: typeof _Infra
			Home: typeof _Home
			Account: typeof _Account
			MobileApplication: typeof _MobileApplication
		}) => {
			const { cartStore, addressManagerV2Store } = useStores()
			const currentPathName = location.pathname === '/' ? '/home' : location.pathname

			const [addState] = useWindowState()
			const { openSignUpPopUp } = Account

			const [navigationBarButtons, setNavigationBarButtons] = useState<ReturnType<typeof getDefaultNavigationBarButtons> | null>(null)

			const { setStore } = useContext(StoreContext)

			const onMenuButtonPress = async (_event: React.ChangeEvent<{}>, newValue: string) => {
				// The value 'SignInPopup' does not redirect to a route
				if (newValue === 'SignInPopup') {
					addState(() => {
						if (window.history?.state?.url?.includes(HISTORY_WINDOW_STATE_ID)) {
							openSignUpPopUp(false, { onSignupPopupClose: () => {} })
						}

						return false
					}, HISTORY_WINDOW_STATE_ID)

					Account.openSignUpPopUp(true, { onSignupPopupClose: () => {} })
					return
				}
				/**
				 * This condition remove hash item from the url when client back to menu page without refresh the page
				 * react router not refresh the page when route changed its just render new component
				 * */
				if (Home.clickedFeaturedItem === null) {
					newValue = newValue?.split('#')[0]
				}

				// If user click on menu tab, when user is localized, we check the session and init a new one if necessary
				if (
					(newValue === `/${User.preferredLanguage}/menu` || MobileApplication?.menuPath?.includes(newValue)) &&
					addressManagerV2Store.isUserLocalized()
				) {
					const result = await addressManagerV2Store.onMenuClickWhenLocalized(cartStore, User)

					// if result is null, it means user is redirected to order-confirmation (because of an order lock)
					if (!result) {
						return
					}

					await User.setSession(result.serverSession)
					MobileApplication.setMenuPath(result.menuUrl)
					setStore((store) => ({ ...store, data: result.menuRes, metaData: result.storeMetaData }))

					newValue = result.menuUrl
				}

				sendCustomEvent({
					category: 'bottom_navigation_link_click',
					tab_name: newValue,
					action: 'clicked',
				})

				history.push(newValue)
			}

			useEffect(() => {
				setNavigationBarButtons(Infra?.appParams?.mobileApp?.navigationBarData || getDefaultNavigationBarButtons())
			}, [])
			const focusedIcon = (() => {
				const page = routeToPage(location.pathname)

				switch (page) {
					case Page.HOME:
						return 'Home'
					case Page.MENU:
					case Page.STATIC_MENU:
						return 'Menu'
					case Page.ORDER_SUMMARY:
						return 'Cart'
					case Page.ACCOUNT:
						if (location.query[ACCOUNT_TAB] === AUTHENTICATOR) {
							return location.query[ACCOUNT_TAB]
						}
						return 'Account'
					case Page.DEALS:
						return 'Deals'
					case Page.COUPONS:
						return 'Coupons'
					default:
						return ''
				}
			})()

			const hideSignUpRoute = (arr: ReturnType<typeof getDefaultNavigationBarButtons>) => (arr ?? []).filter((item) => item.name !== 'Account')

			const getTabValue = (pathName: string) => {
				const page = routeToPage(pathName)
				switch (page) {
					case Page.HOME:
						return `/${Page.HOME}`
					case Page.STATIC_MENU:
					case Page.MENU: {
						if (MobileApplication?.menuPath) {
							return MobileApplication?.menuPath
						}
						return `/${User.preferredLanguage}/${Page.MENU}`
					}
					case Page.ACCOUNT:
						if (location.query[ACCOUNT_TAB] === AUTHENTICATOR) {
							return `/${Page.ACCOUNT}?${ACCOUNT_TAB}=${AUTHENTICATOR}`
						}
						return `/${Page.ACCOUNT}`
					case Page.DEALS:
						return `/${User.preferredLanguage}/${Page.DEALS}`
					case Page.COUPONS:
						return `/${User.preferredLanguage}/${Page.COUPONS}`
					default:
						return false
				}
			}

			const filteredRoutes = Infra?.appParams?.features?.disableSignUp ? hideSignUpRoute(navigationBarButtons!) : navigationBarButtons

			return (
				<>
					{User.preferredLanguage && navigationBarButtons && (
						<BottomAppBar
							TabIndicatorProps={{ style: { backgroundColor: 'var(--primary)' } }}
							// @ts-expect-error we should understand if this property is useless
							showLabels
							value={getTabValue(currentPathName)}
							onChange={onMenuButtonPress}
							$isiOs={isiOS()}
						>
							{(filteredRoutes ?? []).map((_button) => {
								let _route: string = _button.route
								// Allow us to pass parameters in the routes
								_route = _route.replace(':l', User.preferredLanguage as string)

								if (_button.name === 'Home') {
									_route = '/home'
								}

								const _selected = focusedIcon === _button.name
								if ((_button as { isMenuButton: boolean }).isMenuButton && MobileApplication?.menuPath) {
									_route = MobileApplication?.menuPath
								}

								let _icon = <IconComponent asset={`/icons/mobileApp/appBar/${_selected ? 'active/' : ''}${_button.icon}`} />

								if ((_button as unknown as { showBadgeCount: boolean }).showBadgeCount) {
									_icon = (
										<StyledBadge badgeContent={cartStore.numberOfItems} overlap="rectangular">
											{_icon}
										</StyledBadge>
									)
								}

								let _label = getTranslatedTextByKey(`mobileApp.${_button.languageKey}`, _button.name)

								if ((_button as { showUserName: boolean }).showUserName) {
									_label = (Account.userName || Account.userName === 'undefined') ?? 'Account'
								}

								if ((_button as { isSignInButton: boolean }).isSignInButton && !Account.user) {
									_label = getTranslatedTextByKey('eCommerce.signIn.text', 'SignIn')
									_route = 'SignInPopup'
								}

								const label = <BottomNavigationItemText>{_label}</BottomNavigationItemText>

								return (
									// @ts-expect-error why don't we pass the $selected?
									<BottomNavigationButton
										data-testid={`app-navigation-bar-${_button.name.toLowerCase()}-button`}
										id={`${Application.page}-page-${routeToPage(_route) ?? kebabCase(_label)}-button`}
										key={_label}
										label={label}
										value={_route}
										icon={_icon}
									/>
								)
							})}
						</BottomAppBar>
					)}

					{(filteredRoutes ?? []).map((_button) => (
						<div key={_button.name} style={{ overflow: 'hidden', height: '0px' }}>
							<IconComponent asset={`/icons/mobileApp/appBar/active/${_button.icon}`} />
							<IconComponent asset={`/icons/mobileApp/appBar/${_button.icon}`} />
						</div>
					))}
				</>
			)
		}
	) as React.FC<Props>
)

export default Footer
