export function throttle<T extends (...args: any[]) => any>(func: T, limit: number): (...args: any[]) => any {
	let timeoutId: NodeJS.Timeout | null = null
	const lastCall: {
		arguments: Parameters<T> | null
		timeStamp: number
	} = {
		arguments: null,
		timeStamp: 0,
	}

	return function (...args: Parameters<T>) {
		const now = Date.now()

		if (now - lastCall.timeStamp >= limit) {
			func(...args)

			if (timeoutId) {
				clearTimeout(timeoutId)
				timeoutId = null
			}

			lastCall.arguments = null
			lastCall.timeStamp = Date.now()

			return
		}

		lastCall.arguments = args
		if (timeoutId) {
			return
		}

		const remainingTime = limit - (now - lastCall.timeStamp)
		timeoutId = setTimeout(() => {
			func(...(lastCall.arguments || []))

			timeoutId = null
			lastCall.arguments = null
			lastCall.timeStamp = Date.now()
		}, remainingTime)
	}
}
