import type { Theme } from 'utils/theme/GlobalStyle'

const fallbackTheme: Theme = {
	name: 'Generic',
	palette: {
		first: 'rgba(31, 31, 31, 1)',
		second: 'rgba(36, 123, 160, 1)',
		footerAndDarkBackgrounds: 'rgba(31, 31, 31, 1)',
		strokeMenuTitle: 'rgba(31, 31, 31, 1)',
		highlights: 'rgba(36, 123, 160, 1)',
		fonts: 'rgba(31, 31, 31, 1)',
		inactive: 'rgba(134, 121, 128, 1)',
		disable: 'rgba(183, 174, 174, 1)',
		optionalBackground: 'rgba(243, 242, 242, 1)',
		approved: 'rgba(40, 185, 123, 1)',
		err: 'rgba(193, 41, 46, 1)',
		clear: 'rgba(255, 255, 255, 1)',
		localizationActive: 'rgba(31, 31, 31, 1)',
		localizationInactive: 'rgba(134, 121, 128, 1)',
		counterCircleBg: 'rgba(36, 123, 160, 1)',
		cartCounterFont: 'rgba(255, 255, 255, 1)',
		menuItemCounterFont: 'rgba(255, 255, 255, 1)',
		menuItemCartIcon: 'rgba(255, 255, 255, 1)',
		menuItemCartCirclebg: 'rgba(31, 31, 31, 1)',
		menuItemCartCircleBg: 'rgba(31, 31, 31, 1)',
		menuItemCartBg: 'rgba(10, 128, 32, 1)',
		menuItemCartBgEvent: 'rgba(36, 123, 160, 1)',
		price: 'rgba(36, 123, 160, 1)',
		priceStrikethroug: 'rgba(134, 121, 128, 1)',
		priceStrikeThrough: 'rgba(134, 121, 128, 1)',
		favoritesUnselected: 'rgba(0, 0, 0, 0.1)',
		successBg: 'rgba(170, 242, 211, 1)',
		errorBg: 'rgba(246, 226, 226, 1)',
		snacksbarSuccessFont: 'rgba(31, 31, 31, 1)',
		snacksBarSuccessFont: 'rgba(31, 31, 31, 1)',
		plainNotificationBg: 'rgba(255, 255, 255, 1)',
		plainNotificationFont: 'rgba(31, 31, 31, 1)',
		pushNotificationBg: 'rgba(36, 123, 160, 1)',
		pushNotificationFont: 'rgba(255, 255, 255, 1)',
		warningBg: 'rgba(248, 245, 191, 1)',
		warningText: 'rgba(177, 152, 8, 1)',
		couponBg: 'rgba(203, 229, 240, 1)',
		infoBg: 'rgba(233, 220, 233, 1)',
		mapBorder: 'rgba(36, 123, 160, 1)',
		mapIcon: 'rgba(36, 123, 160, 1)',
		topperBtnFontColor: 'rgba(36, 123, 160, 1)',
		topperBtnColor: 'rgba(200, 16, 46, 1)',
		accountIconSignedIn: 'rgba(31, 31, 31, 1)',
		accountIconWithoutSignedIn: 'rgba(134, 121, 128, 1)',
		headerLinkHover: 'rgba(36, 123, 160, 1)',
		headerLinkClicked: 'rgba(36, 123, 160, 1)',
		topMenuLine: 'rgba(36, 123, 160, 1)',
		useMyCurrentLocationFont: 'rgba(36, 123, 160, 1)',
		filterSelectionSelected: 'rgba(36, 123, 160, 1)',
		homePageTitle: 'rgba(255, 255, 255, 1)',
		carouselArrBg: 'rgba(255, 255, 255, 0.4)',
		homePageDivider: 'rgba(235, 242, 245, 1)',
		appBotNavActiveBg: 'rgba(36, 123, 160, 1)',
		appBotNavActiveFontAndIcon: 'rgba(255, 255, 255, 1)',
		appBotNavInactiveFontAndIcon: 'rgba(31, 31, 31, 1)',
		brandTitle: 'rgba(36, 123, 160, 1)',
	},
	shadow: {
		defaultShadow1: '4px 4px 20px 0px rgba(0, 0, 0, 0.1)',
		defaultShadow2: '4px 6px 28px 0px rgba(0, 0, 0, 0.25)',
		highlightShadow1: '4px 4px 15px 0px rgba(36, 123, 160, 0.15)',
		highlightShadow2: '3px 3px 15px 0px rgba(36, 123, 160, 0.3)',
		innerShadow: '1px -1px 5px 0px rgba(0, 0, 0, 0.1) inset',
		botNavShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.25)',
	},
	borderRadius: {
		borderRadiusFrame: '2px',
		borderRadiusBtn: '2px',
		borderRadiusInput: '2px',
	},
	options: {
		cartBadge: {
			desktop: {
				top: 2,
				right: 22,
			},
			mobile: {
				top: 4,
				right: 15,
			},
		},
	},
	typography: {
		fontFamily: 'Roboto',
		h1: {
			fontFamily: 'Roboto',
			fontWeight: '700',
			fontSize: '32px',
			lineHeight: '37px',
			letterSpacing: 'normal',
			textTransform: 'uppercase',
			textDecoration: 'none',
			'@media (max-width:576px)': {
				fontFamily: 'Roboto',
				fontSize: '24px',
				lineHeight: '28px',
				fontWeight: '700',
				letterSpacing: 'normal',
				textTransform: 'uppercase',
				textDecoration: 'none',
			},
		},
		h2: {
			fontFamily: 'Roboto',
			fontWeight: '700',
			fontSize: '24px',
			lineHeight: '28px',
			letterSpacing: 'normal',
			textTransform: 'uppercase',
			textDecoration: 'none',
			'@media (max-width:576px)': {
				fontFamily: 'Roboto',
				fontSize: '20px',
				lineHeight: '23px',
				fontWeight: '700',
				letterSpacing: 'normal',
				textTransform: 'uppercase',
				textDecoration: 'none',
			},
		},
		h3: {
			fontFamily: 'Roboto',
			fontWeight: '700',
			fontSize: '20px',
			lineHeight: '23px',
			letterSpacing: 'normal',
			textTransform: 'uppercase',
			textDecoration: 'none',
			'@media (max-width:576px)': {
				fontFamily: 'Roboto',
				fontSize: '16px',
				lineHeight: '18px',
				fontWeight: '700',
				letterSpacing: 'normal',
				textTransform: 'uppercase',
				textDecoration: 'none',
			},
		},
		h4: {
			fontFamily: 'Roboto',
			fontWeight: '700',
			fontSize: '16px',
			lineHeight: '18px',
			letterSpacing: 'normal',
			textTransform: 'uppercase',
			textDecoration: 'none',
			'@media (max-width:576px)': {
				fontFamily: 'Roboto',
				fontSize: '16px',
				lineHeight: '18px',
				fontWeight: '700',
				letterSpacing: 'normal',
				textTransform: 'uppercase',
				textDecoration: 'none',
			},
		},
		h5: {
			fontFamily: 'Roboto',
			fontWeight: '700',
			fontSize: '14px',
			lineHeight: '16px',
			letterSpacing: 'normal',
			textTransform: 'uppercase',
			textDecoration: 'none',
			'@media (max-width:576px)': {
				fontFamily: 'Roboto',
				fontSize: '14px',
				lineHeight: '16px',
				fontWeight: '700',
				letterSpacing: 'normal',
				textTransform: 'uppercase',
				textDecoration: 'none',
			},
		},
		Subhead: {
			fontFamily: 'Open Sans',
			fontWeight: '400',
			fontSize: '20px',
			lineHeight: '23px',
			letterSpacing: 'normal',
			textTransform: 'none',
			textDecoration: 'none',
			'@media (max-width:576px)': {
				fontSize: '16px',
				lineHeight: '21px',
				fontFamily: 'Open Sans',
				fontWeight: '400',
				letterSpacing: 'normal',
				textTransform: 'none',
				textDecoration: 'none',
			},
		},
		SubheadHighlighted: {
			fontFamily: 'Open Sans',
			fontWeight: '600',
			fontSize: '20px',
			lineHeight: '23px',
			letterSpacing: 'normal',
			textTransform: 'none',
			textDecoration: 'none',
			'@media (max-width:576px)': {
				fontSize: '16px',
				lineHeight: '21px',
				fontFamily: 'Open Sans',
				fontWeight: '600',
				letterSpacing: 'normal',
				textTransform: 'none',
				textDecoration: 'none',
			},
		},
		Price: {
			fontFamily: 'Open Sans',
			fontWeight: '600',
			fontSize: '16px',
			lineHeight: '21px',
			letterSpacing: 'normal',
			textTransform: 'none',
			textDecoration: 'none',
			'@media (max-width:576px)': {
				fontSize: '16px',
				lineHeight: '21px',
				fontFamily: 'Open Sans',
				fontWeight: '600',
				letterSpacing: 'normal',
				textTransform: 'none',
				textDecoration: 'none',
			},
		},
		BodyRegular: {
			fontFamily: 'Open Sans',
			fontWeight: '400',
			fontSize: '14px',
			lineHeight: '19px',
			letterSpacing: 'normal',
			textTransform: 'none',
			textDecoration: 'none',
			'@media (max-width:576px)': {
				fontSize: '14px',
				lineHeight: '19px',
				fontFamily: 'Open Sans',
				fontWeight: '400',
				letterSpacing: 'normal',
				textTransform: 'none',
				textDecoration: 'none',
			},
		},
		BodyRegularHighlighted: {
			fontFamily: 'Open Sans',
			fontWeight: '600',
			fontSize: '14px',
			lineHeight: '19px',
			letterSpacing: 'normal',
			textTransform: 'none',
			textDecoration: 'none',
			'@media (max-width:576px)': {
				fontSize: '14px',
				lineHeight: '19px',
				fontFamily: 'Open Sans',
				fontWeight: '600',
				letterSpacing: 'normal',
				textTransform: 'none',
				textDecoration: 'none',
			},
		},
		BodyRegularClickable: {
			fontFamily: 'Open Sans',
			fontWeight: '400',
			fontSize: '14px',
			lineHeight: '19px',
			letterSpacing: 'normal',
			textTransform: 'none',
			textDecoration: 'underline',
			'@media (max-width:576px)': {
				fontSize: '14px',
				lineHeight: '19px',
				fontFamily: 'Open Sans',
				fontWeight: '400',
				letterSpacing: 'normal',
				textTransform: 'none',
				textDecoration: 'underline',
			},
		},
		BodyRegularStrikethrough: {
			fontFamily: 'Open Sans',
			fontWeight: '400',
			fontSize: '14px',
			lineHeight: '19px',
			letterSpacing: 'normal',
			textTransform: 'none',
			textDecoration: 'line-through',
			'@media (max-width:576px)': {
				fontSize: '14px',
				lineHeight: '19px',
				fontFamily: 'Open Sans',
				fontWeight: '400',
				letterSpacing: 'normal',
				textTransform: 'none',
				textDecoration: 'line-through',
			},
		},
		BodySmall: {
			fontFamily: 'Open Sans',
			fontWeight: '400',
			fontSize: '12px',
			lineHeight: '16px',
			letterSpacing: 'normal',
			textTransform: 'none',
			textDecoration: 'none',
			'@media (max-width:576px)': {
				fontSize: '12px',
				lineHeight: '16px',
				fontFamily: 'Open Sans',
				fontWeight: '400',
				letterSpacing: 'normal',
				textTransform: 'none',
				textDecoration: 'none',
			},
		},
		BodySmallHighlighted: {
			fontFamily: 'Open Sans',
			fontWeight: '600',
			fontSize: '12px',
			lineHeight: '16px',
			letterSpacing: 'normal',
			textTransform: 'none',
			textDecoration: 'none',
			'@media (max-width:576px)': {
				fontSize: '12px',
				lineHeight: '16px',
				fontFamily: 'Open Sans',
				fontWeight: '600',
				letterSpacing: 'normal',
				textTransform: 'none',
				textDecoration: 'none',
			},
		},
		BodySmallClickable: {
			fontFamily: 'Open Sans',
			fontWeight: '400',
			fontSize: '12px',
			lineHeight: '16px',
			letterSpacing: 'normal',
			textTransform: 'none',
			textDecoration: 'underline',
			'@media (max-width:576px)': {
				fontSize: '12px',
				lineHeight: '16px',
				fontFamily: 'Open Sans',
				fontWeight: '400',
				letterSpacing: 'normal',
				textTransform: 'none',
				textDecoration: 'underline',
			},
		},
		CTABig: {
			fontFamily: 'Open Sans',
			fontWeight: '600',
			fontSize: '18px',
			lineHeight: '24px',
			letterSpacing: 'normal',
			textTransform: 'uppercase',
			textDecoration: 'none',
			'@media (max-width:576px)': {
				fontSize: '18px',
				lineHeight: '24px',
				fontFamily: 'Open Sans',
				fontWeight: '600',
				letterSpacing: 'normal',
				textTransform: 'uppercase',
				textDecoration: 'none',
			},
		},
		CTASmall: {
			fontFamily: 'Open Sans',
			fontWeight: '600',
			fontSize: '14px',
			lineHeight: '19px',
			letterSpacing: 'normal',
			textTransform: 'uppercase',
			textDecoration: 'none',
			'@media (max-width:576px)': {
				fontSize: '14px',
				lineHeight: '19px',
				fontFamily: 'Open Sans',
				fontWeight: '600',
				letterSpacing: 'normal',
				textTransform: 'uppercase',
				textDecoration: 'none',
			},
		},
		CTAText: {
			fontFamily: 'Open Sans',
			fontWeight: '600',
			lineHeight: '23px',
			fontSize: '20px',
			letterSpacing: 'normal',
			textTransform: 'none',
			textDecoration: 'none',
			'@media (max-width:576px)': {
				fontFamily: 'Open Sans',
				fontWeight: '600',
				lineHeight: '21px',
				fontSize: '16px',
				letterSpacing: 'normal',
				textTransform: 'none',
				textDecoration: 'none',
			},
		},
		CTATextBig: {
			fontFamily: 'Open Sans',
			fontWeight: '600',
			lineHeight: '23px',
			fontSize: '20px',
			letterSpacing: 'normal',
			textTransform: 'none',
			textDecoration: 'none',
			'@media (max-width:576px)': {
				fontFamily: 'Open Sans',
				fontWeight: '600',
				lineHeight: '21px',
				fontSize: '16px',
				letterSpacing: 'normal',
				textTransform: 'none',
				textDecoration: 'none',
			},
		},
		CTATextSmall: {
			fontFamily: 'Open Sans',
			fontWeight: '600',
			lineHeight: '21px',
			fontSize: '16px',
			letterSpacing: 'normal',
			textTransform: 'none',
			textDecoration: 'none',
			'@media (max-width:576px)': {
				fontFamily: 'Open Sans',
				fontWeight: '600',
				lineHeight: '19px',
				fontSize: '14px',
				letterSpacing: 'normal',
				textTransform: 'none',
				textDecoration: 'none',
			},
		},
		HomePageTitle: {
			fontFamily: 'Roboto',
			fontWeight: '700',
			lineHeight: '58px',
			fontSize: '50px',
			letterSpacing: 'normal',
			textTransform: 'uppercase',
			textDecoration: 'none',
			'@media (max-width:576px)': {
				fontFamily: 'Roboto',
				fontWeight: '700',
				lineHeight: '46px',
				fontSize: '40px',
				letterSpacing: 'normal',
				textTransform: 'uppercase',
				textDecoration: 'none',
			},
		},
	},
}

export default fallbackTheme
