import { useEffect } from 'react'
import { ReactNativeComms } from 'utils/reactNativeComms'
import { useRouter } from 'next/router'

/**
 * TopLevelServerError is meant to be displayed for critical errors that should
 * stop the execution of the request right away.
 * Examples: no params.json, no theme, no host, no environment variables...
 */
const UnrecoverableServerError = ({ message, traceId }: { message: string; traceId: string }) => {
	const router = useRouter()

	if (message) {
		console.error(`[TOP_LEVEL_SERVER_ERROR]: ${router?.route && `location - ${router?.route}`} ${message} - Trace ID: ${traceId}`)
	} else {
		console.error(
			`[TOP_LEVEL_SERVER_ERROR]: ${router?.route && `location - ${router?.route}`} Unidentified high level error - Trace ID: ${traceId}`
		)
	}

	useEffect(() => {
		ReactNativeComms.sendMessage.unrecoverableServerError(
			`[TOP_LEVEL_SERVER_ERROR]: location - ${window?.location} ${message || 'Unidentified high level error'} - Trace ID: ${traceId}`
		)
	}, [message])

	return (
		<div
			style={{
				width: '100vw',
				height: '100vh',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'column',
			}}
		>
			<div>This page cannot be loaded</div>
			<hr />
			{traceId && <div>Trace ID: {traceId}</div>}
		</div>
	)
}

export default UnrecoverableServerError
