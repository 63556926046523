import { isExternal, isCouponAvailableAtDate, isCouponExpired, isIndividual } from 'mobx/Coupons/utils'
import { groupBy } from 'lodash-es'
import { action, computed, observable } from 'mobx'
import type { PrbCoupon, Filter, CouponsDependencies, AddCouponToWalletResponse } from './types'
import { CouponFilter } from './types'
import type { LocalizedCoupon } from 'types/Coupons'
import type { Channel, Coupon, IndividualCoupon, OrderType } from 'shared-types/Coupon'
import { CouponSource, CouponStatus } from 'shared-types/Coupon'
import { getTranslatedTextByKey, type LanguageLocale } from 'utils/language'
import { CheckCouponOn } from 'mobx/CouponFlow'
import CouponError, { ErrorCode } from './errors'
import { minutesToMilliseconds } from 'date-fns'
import { SnackbarStatus } from 'mobx/Infra/Infra.type'
import { NumberFormatBase } from 'react-number-format'

export default class CouponsStore {
	private readonly dependencies: CouponsDependencies

	constructor(dependencies: CouponsDependencies) {
		this.dependencies = dependencies
	}

	@observable coupons: Coupon[] = []

	/**
	 * When not null, opens a pop-up of a coupon modal with details about a single coupon
	 */
	@observable couponModal: Coupon | null = null

	@observable private readonly externalCouponsCache = new Map<string, PrbCoupon & { timestamp: Date }>()

	@action
	setCouponModal(coupon: Coupon | null) {
		this.couponModal = coupon
	}

	@action
	async setAppliedCoupons(coupons: Coupon[]): Promise<void> {
		const existingCouponsMap = new Map<string, Coupon>()

		this.coupons.forEach((coupon) => {
			existingCouponsMap.set(coupon.code, coupon)
		})

		const incomingCouponCodes = new Set(coupons.map((coupon) => coupon.code))

		coupons.forEach((incomingCoupon) => {
			incomingCoupon.timestamp = new Date(incomingCoupon.timestamp)
			if (incomingCoupon.expiration) {
				incomingCoupon.expiration = new Date(incomingCoupon.expiration)
			}

			if (isIndividual(incomingCoupon) && incomingCoupon.attachedAt) {
				incomingCoupon.attachedAt = new Date(incomingCoupon.attachedAt)
			}

			const existingCoupon = existingCouponsMap.get(incomingCoupon.code)

			if (existingCoupon) {
				existingCoupon.flags = { ...existingCoupon.flags, ...incomingCoupon.flags }
				existingCoupon.price = incomingCoupon.price
			} else {
				this.coupons.push(incomingCoupon)
			}
		})

		this.coupons.forEach((existingCoupon) => {
			if (!incomingCouponCodes.has(existingCoupon.code)) {
				existingCoupon.flags = {
					...existingCoupon.flags,
					applied: { value: false },
					active: { value: false },
				}
			}
		})
	}

	private mergeCoupon(coupon: Coupon): void {
		const cachedCoupon = this.coupons.find(({ code }) => code === coupon.code) ?? null

		if (!cachedCoupon) {
			return
		}

		coupon.flags.applied = { value: !!cachedCoupon?.flags.applied?.value }
		coupon.flags.active = {
			value: !!cachedCoupon?.flags.active?.value,
			reasonCode: cachedCoupon?.flags.active?.reasonCode,
		}
	}

	@computed get appliedCoupons(): Coupon[] {
		return this.coupons.filter((coupon) => coupon.flags.applied?.value)
	}

	@computed get appliedCouponsWithoutGovernment(): Coupon[] {
		return this.coupons.filter((coupon) => coupon.flags.applied?.value && coupon.code !== 'GOVERNMENT')
	}

	private prepareCouponsToAdd(cachedCoupons: Coupon[], newCoupons: Coupon[], sources: CouponSource[]): Coupon[] {
		newCoupons.forEach((coupon) => this.mergeCoupon(coupon))
		const ignoreOrderTypes = this.dependencies.getIgnoreOrderTypes()

		return cachedCoupons
			.filter(({ code, flags, source }) => {
				const isRelevantSource = sources.includes(source)
				const shouldShowInWallet = flags.wallet?.value
				const newCodeAppearsInNewCoupons = newCoupons.some(({ code: newCode }) => code === newCode)

				return !isRelevantSource || (!shouldShowInWallet && !newCodeAppearsInNewCoupons)
			})
			.concat(newCoupons)
			.flatMap(({ orderTypes, ...rest }) => {
				const newOrderTypes = orderTypes.filter((f) => !ignoreOrderTypes.includes(f))
				if (!newOrderTypes.length) {
					return []
				}

				return { ...rest, orderTypes: newOrderTypes }
			})
	}

	@action
	public async fetchInternalCoupons(): Promise<void> {
		this.coupons = await this.dependencies
			.getInternalCoupons()
			.then((coupons) => this.prepareCouponsToAdd(this.coupons, coupons, [CouponSource.INDIVIDUAL, CouponSource.INTERNAL]))
	}

	@action
	public async fetchExternalCoupons(): Promise<void> {
		this.coupons = await this.dependencies
			.getExternalCoupons()
			.then((coupons) => this.prepareCouponsToAdd(this.coupons, coupons, [CouponSource.EXTERNAL]))
	}

	private static hasCacheExpired(coupon: Pick<Coupon, 'timestamp'>, expiry = minutesToMilliseconds(5)): boolean {
		return coupon.timestamp.getTime() <= new Date().getTime() - expiry
	}

	private getCachedCoupon(code: string): Coupon | null {
		const coupon = this.coupons.find(({ code: id }) => id === code) ?? null

		if (!coupon || CouponsStore.hasCacheExpired(coupon)) {
			return null
		}

		return coupon
	}

	@action
	clearCoupons(): void {
		this.coupons = []
	}

	@action
	async getCoupon(code: string, checkCouponOn: CheckCouponOn = CheckCouponOn.CHAIN): Promise<Coupon | null> {
		const cachedCoupon = this.getCachedCoupon(code)
		if (cachedCoupon) {
			return cachedCoupon
		}

		const coupon = await this.dependencies.getCoupon(code, checkCouponOn)

		if (!coupon) {
			return null
		}

		if (isIndividual(coupon)) {
			const couponOfSameParent = this.coupons.find((c) => isIndividual(c) && c.parentCode === coupon.parentCode)
			if (couponOfSameParent) {
				this.dependencies.showSnackbar({
					snackId: '',
					message: getTranslatedTextByKey(
						'eCommerce.coupons.snackbar.couponAlreadyExists',
						'There is already an individual coupon of that parent'
					),
					status: SnackbarStatus.INFO,
					isAttachedToElement: false,
					duration: 5000,
				})
				return couponOfSameParent
			}
		}

		this.mergeCoupon(coupon)
		this.coupons = this.coupons.filter(({ code: existingCode }) => existingCode !== coupon.code).concat(coupon)

		return this.coupons.find(({ code: id }) => id?.toLocaleLowerCase() === code.toLocaleLowerCase()) ?? null
	}

	static validateCoupon(coupon: Coupon): void {
		if (isCouponExpired(coupon)) {
			throw new CouponError('Coupon is expired', ErrorCode.COUPON_EXPIRED)
		}

		if (coupon.status === CouponStatus.REDEEMED) {
			throw new CouponError('Coupon is redeemed', ErrorCode.COUPON_REDEEMED)
		}
	}

	private async normaliseAddToWalletError(code: string, error: CouponError): Promise<CouponError> {
		const valdiateAndReturn = async (): Promise<CouponError> => {
			const coupon = await this.getCoupon(code)

			if (!coupon) {
				return new CouponError('Coupon not found while normalising error', ErrorCode.COUPON_NOT_FOUND)
			}

			try {
				CouponsStore.validateCoupon(coupon)
				return error
			} catch (err) {
				return err as CouponError
			}
		}

		switch (error.code) {
			case ErrorCode.USERS_COUPON_INDIVIDUAL_COUPON_NOT_AVAILABLE: {
				return valdiateAndReturn()
			}

			case ErrorCode.USERS_COUPON_INDIVIDUAL_COUPON_EXISTS:
			case ErrorCode.USERS_COUPON_INDIVIDUAL_PARENT_COUPON_EXISTS: {
				const newErr = await valdiateAndReturn()
				return new CouponError(error.message, error.code, newErr === error)
			}

			default:
				return error
		}
	}

	@action
	async addCouponToWalletBulk(codes: string[]): Promise<IndividualCoupon[]> {
		const partialCoupons = await this.dependencies.addCouponToWalletBulk(codes).catch(() => {})
		if (!partialCoupons) {
			return []
		}

		const results = await Promise.all(
			partialCoupons.map(async (partialCoupon) => {
				try {
					return this.createIndividualCoupon(partialCoupon)
				} catch (error) {
					return error
				}
			})
		)

		return results.filter((result): result is IndividualCoupon => !(result instanceof Error))
	}

	@action
	async addCouponToWallet(code: string): Promise<IndividualCoupon> {
		const partialCoupon = await this.dependencies.addCouponToWallet(code).catch(async (error: CouponError) => {
			throw await this.normaliseAddToWalletError(code, error)
		})

		return this.createIndividualCoupon(partialCoupon)
	}

	@action
	private async createIndividualCoupon(partialCoupon: AddCouponToWalletResponse): Promise<IndividualCoupon> {
		let cachedParent = this.getCachedCoupon(partialCoupon.parentCode)

		if (!cachedParent) {
			cachedParent = await (this.getCoupon(partialCoupon.parentCode) as Promise<IndividualCoupon>)
		}

		const { parentCode, id, usesLeft, totalUses } = partialCoupon

		const result = {
			...cachedParent,
			flags: { ...cachedParent.flags, wallet: { value: true } },
			code: partialCoupon.code,
			expiration: new Date(partialCoupon.expiration),
			source: CouponSource.INDIVIDUAL,
			status: CouponStatus.AVAILABLE,
			parentCode,
			id,
			usesLeft,
			totalUses,
		} as IndividualCoupon

		this.coupons.push(result)

		return result
	}

	private readonly filterMap: Partial<Record<Filter, (coupon: Coupon) => boolean>> = {
		[CouponFilter.ALL]: () => true,
		[CouponFilter.MY_COUPONS]: ({ flags: { wallet }, source }) => source === CouponSource.INDIVIDUAL && !!wallet?.value,
	}

	assertCouponAvailable(coupon: Coupon, { date, channel, orderType }: { date: Date; channel: Channel; orderType: OrderType }): void {
		if (!isCouponAvailableAtDate(coupon, date)) {
			throw new CouponError('Coupon not available at date', ErrorCode.COUPON_NOT_AVAILABLE_FOR_TIME)
		}

		if (!coupon.channels.includes(channel)) {
			throw new CouponError('Coupon not available for channel', ErrorCode.COUPON_NOT_AVAILABLE_FOR_CHANNEL)
		}

		if (!coupon.orderTypes.includes(orderType) && this.dependencies.isLocalized()) {
			throw new CouponError('Coupon not available for order type', ErrorCode.COUPON_NOT_AVAILABLE_FOR_ORDER_TYPE)
		}
	}

	filterCoupons(filter: Filter): Coupon[] {
		const defaultFilter = ({ orderTypes }: Coupon) => orderTypes.includes(filter as OrderType)

		const result = this.coupons
			.filter(
				(coupon) =>
					coupon.flags.wallet?.value &&
					(this.filterMap[filter] ?? defaultFilter)(coupon) &&
					(!isIndividual(coupon) || coupon.status === CouponStatus.AVAILABLE || coupon.flags.applied?.value) &&
					!isCouponExpired(coupon)
			)
			.sort((a, b) => {
				const aIsExternal = isExternal(a)
				const bIsExternal = isExternal(b)

				if (!aIsExternal || !bIsExternal) {
					return aIsExternal ? -1 : 1
				}

				if (!a.sequence || Number.isNaN(+a.sequence)) {
					if (!b.sequence || Number.isNaN(+b.sequence)) {
						return 0
					}

					return 1
				}

				if (!b.sequence || Number.isNaN(+b.sequence)) {
					return -1
				}

				return a.sequence - b.sequence
			})

		// Make sure parent and child don't both appear
		return result.filter((coupon, _, array) => isIndividual(coupon) || array.every((c) => !isIndividual(c) || c.parentCode !== coupon.code))
	}

	getGroupedCoupons(filter: Filter): Record<Filter, Coupon[]> {
		const grouped = groupBy(this.filterCoupons(filter), ({ orderTypes: type }) => (filter === CouponFilter.ALL ? type.join() : filter)) as Record<
			Filter,
			Coupon[]
		>

		;(Object.keys(grouped) as (keyof typeof grouped)[]).forEach((key) =>
			grouped[key].sort((a, b) => {
				const aDate: number = (isIndividual(a) && a.attachedAt?.getTime()) || Infinity
				const bDate: number = (isIndividual(b) && b.attachedAt?.getTime()) || Infinity

				return bDate - aDate
			})
		)

		return grouped
	}

	static localizeCoupon(coupon: Coupon, locale: LanguageLocale): LocalizedCoupon {
		// return addVirtualFields({ ...coupon, title: coupon.title[locale], description: coupon.description[locale] })
		return { ...coupon, title: coupon.title[locale], description: coupon.description[locale] }
	}

	async getPrbCode(id: string): Promise<PrbCoupon> {
		const cachedCoupon = this.externalCouponsCache.get(id)
		const EXPIRY = minutesToMilliseconds(1)

		if (cachedCoupon && !CouponsStore.hasCacheExpired(cachedCoupon, EXPIRY)) {
			return cachedCoupon
		}

		return this.dependencies.getPrbCode(id).then((result) => {
			this.externalCouponsCache.set(id, { ...result, timestamp: new Date() })
			return result
		})
	}
}
