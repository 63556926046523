import { action, observable } from 'mobx'
import { CheckCouponOn, type CouponFlowDependencies, type CouponToApply, type StartCouponFlowProps } from './types'
import { Page } from 'utils/constants'
import routeToPage from 'utils/routeToPage'
import type { Coupon } from 'shared-types/Coupon'
import { ApplyTo, Channel, CouponSource, CouponStatus, OfferType, OrderType } from 'shared-types/Coupon'
import CouponError, { ErrorCode } from 'mobx/Coupons/errors'
import { sendCustomEvent } from 'utils/analytics/analytics'
import { isIndividual, toCouponOrderType, translateChannel, translateOrderType, isCoupon } from 'mobx/Coupons/utils'
import { getCurrentChannel } from './utils'
import { getLocaleStr, getTranslatedTextByKey, getUserLanguage } from 'utils/utils'
import { SnackbarStatus } from 'mobx/Infra/Infra.type'

export default class CouponFlowStore {
	private readonly dependencies: CouponFlowDependencies

	@observable couponToApply: CouponToApply | null = null

	@observable couponToUnapply = ''

	constructor(dependencies: CouponFlowDependencies) {
		this.dependencies = dependencies
	}

	@action
	setCouponToApply(couponToApply: CouponToApply): void {
		this.couponToApply = couponToApply
	}

	@action
	isCouponToApplyAvailableFor(orderType: OrderType) {
		return this.couponToApply?.coupon?.orderTypes.includes(orderType) ?? false
	}

	@action
	clearCouponToApply(): void {
		this.couponToApply = null
	}

	@action
	async applyFromCouponModal(coupon: Coupon): Promise<boolean> {
		this.dependencies.setCouponModal(null)

		sendCustomEvent({
			category: 'apply_from_popup',
			action: 'clicked',
			coupon_name: coupon.title,
			coupon_code: coupon.code,
			price: coupon.price,
			order_type: [...coupon.orderTypes],
			offer_type: coupon.offerType,
			page_path: window.location.pathname,
		})

		// throw errors that needs to be displayed in the modal

		if (!this.couponToApply) {
			// const { code } = coupon
			// this.start({ code, orderTypeToForce: null, openModal: false })

			const validated = this.validateCoupon(coupon, { modal: true, showBackToCoupons: false })

			if (!validated) {
				return false
			}

			this.setCouponToApply({ coupon, orderTypeToForce: null, ready: false })
		}

		return this.handleLocalization()
	}

	validateCoupon(coupon: Coupon, { modal, showBackToCoupons }: { modal: boolean; showBackToCoupons: boolean }): boolean {
		try {
			this.dependencies.assertCouponAvailable(coupon, {
				date: new Date(),
				orderType: toCouponOrderType(this.dependencies.getOrderType()),
				channel: getCurrentChannel(),
			})
		} catch (error) {
			if (!(error instanceof CouponError)) {
				this.dependencies.showSnackbar({
					status: SnackbarStatus.INFO,
					message: new CouponError('Unknwon error', ErrorCode.DEFAULT).text(),
					key: 'coupons-error-snackbar',
					snackId: 'coupons-error-snackbar',
					isAttachedToElement: false,
				})
				return false
			}

			this.dependencies.showError({
				title: getTranslatedTextByKey('eCommerce.coupons.availability.unavailable', 'Coupon Unavaialable'),
				message: error.text({
					orderType: translateOrderType(toCouponOrderType(this.dependencies.getOrderType())),
					channels: coupon.channels.map(translateChannel).join(', '),
					channel: translateChannel(getCurrentChannel()),
				}),
				cancelText: getTranslatedTextByKey('eCommerce.coupons.availability.viewDetails', 'View Details'),
				showCancelButton: !modal,
				cancelAction: () => {
					setTimeout(() => this.dependencies.setCouponModal(coupon))
				},
				...(showBackToCoupons && { okText: getTranslatedTextByKey('eCommerce.coupons.availability.backToCoupons', 'Back to Coupons') }),
			})

			return error.continueFlow
		}
		return true
	}

	@action
	async start({ code, orderTypeToForce, openModal = true, checkCouponOn = CheckCouponOn.CHAIN }: StartCouponFlowProps): Promise<boolean> {
		const [trimmedCode] = code.split('§§')

		const loggedIn = this.dependencies.isUserLoggedIn()

		const coupon = await this.dependencies.getCoupon(trimmedCode, checkCouponOn).catch((err: CouponError) => {
			if (err.code === ErrorCode.ORG_WITH_INTEGRATION_DISCOUNTS_ERROR && checkCouponOn === CheckCouponOn.STORE) {
				return {
					code,
					title: { en_US: 'Organization with integration discounts' },
					description: { en_US: 'This organization has discounts for integration orders. Please use the integration to place your order.' },
					orderTypes: [OrderType.DELIVERY, OrderType.PICKUP],
					channels: [Channel.WEB, Channel.APP, Channel.KIOSK, Channel.MESSENGER, Channel.TELEGRAM, Channel.WHATSAPP],
					flags: {
						requireLogin: { value: false },
						wallet: { value: false },
						applied: { value: false },
						active: { value: false },
					},
					offerType: OfferType.PERCENTAGE_DISCOUNT,
					price: 0,
					status: CouponStatus.AVAILABLE,
					id: code,
					img: '',
					source: CouponSource.EXTERNAL,
					timestamp: new Date(),
					applyTo: ApplyTo.ALL,
					itemOrSectionIds: [],
					minOrderPrice: 0,
					availability: {
						weekly: [
							{
								minuteOfWeek: 0,
								durationMins: 10080,
							},
						],
						exceptions: [],
					},
				} as Coupon
			}

			const errorMessage =
				coupon && isCoupon(coupon)
					? getTranslatedTextByKey('itemAvailabilityNotAvailable', 'Item not available').replace(
							'%itemName%',
							getLocaleStr(coupon.title, getUserLanguage())
					  )
					: err.text?.() || err.message
			this.dependencies.showError({ message: errorMessage })

			return null
		})

		if (!coupon) {
			sendCustomEvent({
				category: 'discount',
				action: 'redeem failure',
				label: trimmedCode,
			})

			return false
		}

		const { router } = this.dependencies
		let validated
		if (!router) {
			console.warn('Router is not set')
			validated = this.validateCoupon(coupon, { modal: false, showBackToCoupons: false })
		} else {
			const { pathname } = router
			const currentPage = routeToPage(pathname, true)
			validated = this.validateCoupon(coupon, { modal: false, showBackToCoupons: currentPage === Page.COUPONS })
		}

		if (!validated) {
			sendCustomEvent({
				category: isCoupon(coupon) ? 'coupon' : 'discount',
				action: 'redeem failure',
				label: trimmedCode,
			})

			return false
		}

		this.setCouponToApply({ coupon, orderTypeToForce, ready: false })

		/**
		 * Since the modal is public, we can always open it, regardless if user is logged in or not.
		 * The guard to prevent not logged-in users to start the flow should never be reached,
		 * since we always should prefer to open the login modal
		 */
		if (openModal || (coupon.flags.requireLogin?.value && !loggedIn)) {
			this.dependencies.setCouponModal(coupon)
		} else if (this.couponToApply?.coupon.flags.requireLogin?.value && !loggedIn) {
			const error = new CouponError('You must be logged in to use this coupon', ErrorCode.COUPON_REQUIRES_LOGIN)
			this.dependencies.showError({ message: error.text() })

			sendCustomEvent({
				category: isCoupon(coupon) ? 'coupon' : 'discount',
				action: 'redeem failure',
				label: trimmedCode,
			})

			return false
		} else {
			const localized = await this.handleLocalization()
			
			if (!localized) {
				sendCustomEvent({
					category: isCoupon(coupon) ? 'coupon' : 'discount',
					action: 'redeem failure',
					label: trimmedCode,
				})
			}

			return localized
		}

		return true
	}

	@action
	async unapply(coupon: Coupon): Promise<void> {
		if (!coupon) {
			const error = new CouponError('Coupon not found', ErrorCode.COUPON_NOT_FOUND)
			this.dependencies.showError({ message: error.text() })
			return
		}
		if (isIndividual(coupon)) {
			coupon.usesLeft += 1
		}
		coupon.status = CouponStatus.AVAILABLE
		await this.dependencies.removeDiscount(coupon.code)
	}

	async redirectToHomeAndRestartFlow() {
		const { router } = this.dependencies
		if (!router) {
			console.error('Router is not set')
			return
		}
		await router.push(`/home`)
		await this.handleLocalization()
	}

	async resetLocalizationAndRestartFlow() {
		this.dependencies.resetLocalization()
		await this.handleLocalization()
	}

	userIsLocalizedExitPoint(menuUrl: string) {
		const { router } = this.dependencies
		if (!router) {
			console.error('Router is not set')
			return
		}
		const { pathname } = router
		const currentPage = routeToPage(pathname, true)
		const pagesWithNoRedirect = [Page.MENU, Page.CHECKOUT]

		if (!currentPage || !pagesWithNoRedirect.includes(currentPage)) {
			router?.push(`${menuUrl}`)
		}
	}

	@action
	async handleLocalization(): Promise<boolean> {
		const { isChainClosed, isLocalized, isStoreClosed, openLocalizationModal, router, isPageLegal, isUserLoggedIn } = this.dependencies

		if (!router) {
			console.error('Router is not set')
			return false
		}

		if (!this.couponToApply) {
			const error = new CouponError('Coupons store is not set', ErrorCode.COUPON_NOT_FOUND)
			this.dependencies.showError({ message: error.text() })
			return false
		}

		if (isChainClosed()) {
			const error = new CouponError('Chain is closed', ErrorCode.CHAIN_CLOSED)
			this.dependencies.showError({ message: error.text() })
			return false
		}

		if (this.couponToApply?.coupon.flags.requireLogin?.value && !isUserLoggedIn()) {
			const error = new CouponError('You must be logged in to use this coupon', ErrorCode.COUPON_REQUIRES_LOGIN)
			this.dependencies.showError({ message: error.text() })
			return false
		}

		if (isLocalized()) {
			const storeClosed = await isStoreClosed()

			const session = await this.dependencies.getAndUpdateSession()

			// if menuUrl is null, we stop the flow here because we are on an order lock scenario that redirects to order-confirmation
			if (!session?.menuUrl) {
				return false
			}

			if (storeClosed) {
				const error = new CouponError('Your store is closed, you must choose another one', ErrorCode.STORE_CLOSED)
				this.dependencies.showError({ message: error.text() })
				await this.resetLocalizationAndRestartFlow()
				return false
			}
			this.userIsLocalizedExitPoint(session.menuUrl)
		} else {
			const legalPage = isPageLegal(router.pathname)
			if (!legalPage) {
				await this.redirectToHomeAndRestartFlow()
				return false
			}
			openLocalizationModal(this.couponToApply)
		}

		this.couponToApply.ready = true
		return true
	}
}
