// @ts-nocheck
import type { Page } from './constants'
import { CONSTANTS, REQUEST_COOKIE_EXPIRY_IN_HOURS, REQUEST_PARAM, HTTP_CODES, ORDER_TYPES, ITEM_CODE_ORDER_TYPE_SEPARATOR } from './constants'
import Big from 'big.js'
import { useState, useEffect, useRef } from 'react'
import { startCase, isNumber, capitalize, merge, isEmpty, isEqual, differenceWith, omitBy } from 'lodash-es'
import queryString from 'query-string'
import axios, { AxiosError } from 'axios'
import type { AxiosRequestHeaders, Method } from 'axios'
import Infra from '../mobx/Infra'
import User from '../mobx/User'
import Account from '../mobx/Account'
import MobileApplication from '../mobx/MobileApplication'
import Session from 'mobx/Session'
import currencies from '../staticData/currencies'
import Cookies from 'js-cookie'
import axiosRetry from 'axios-retry'
import type { Theme } from '@material-ui/core/styles'
import { createTheme } from '@material-ui/core/styles'
import nextCookiesState from '../../utils/nextCookiesState'
import AddressManager from '../mobx/AddressManager'
import { datadogRum } from '@datadog/browser-rum'
import { CustomError } from '../components/errors/CustomError'
import { queueableSendRequest } from './requestsQueue'
import type { NextPageContext } from 'next'
import type { LanguageCode, LanguageLocale } from 'utils/language'
import type { Variation } from 'types/MenuItem'
import type { Menu } from 'types/Menu'
import { v5 as uuidv5, v4 as uuidv4 } from 'uuid'
import type { NextRouter } from 'next/router'
import { localeToCode, codeToLocale, localeToDir, getTranslatedTextByKey, getLocaleStr } from './language'
import type { AppParams } from 'mobx/Infra/Infra.type'
import type MenuSection from 'types/MenuSection'
import { AUTHENTICATION_TYPE } from 'constants/signUpProcess'
import { sendCustomEvent } from './analytics/analytics'
import type CartStore from 'mobx/Cart'
import type { Store, StoreMetaData } from 'types/StoreContext'
import type _Home from 'mobx/Home'
import type AddressManagerV2Store from 'mobx/AddressManagerV2/store'

export { default as routeToPage } from './routeToPage'
export const SERVER_ANDROID_PLATFORM_ID = 'android'
export const SERVER_IOS_PLATFORM_ID = 'ios'
export const SERVER_MOBILE_WEB_PLATFORM_ID = 'mobileweb'
export const SERVER_KIOSK_PLATFORM_ID = 'kiosk'
export const isNodeJsEnv = typeof window === 'undefined'
export const IS_HEADLESS_BROWSER_QUERY_PARAM_KEY = 'headlessBrowser'
export * from './language'

let parsed = {}

// for Node js env
if (typeof window !== 'undefined') {
	parsed = queryString.parse(window.location.search)
}

let arrayToSendForCalcGTOrWebViewAPIs = null

export function debugMobX(observableVar) {
	// const a = toJS(observableVar)
	// see if this helps speed up staging
	// console.log(`** debugMobX: \n${JSON.stringify(a)}`)
}

export function configAxios() {
	// our retry-strategy is to attempt 5 retries with ever increasing time between retries of an extra second
	axiosRetry(axios, {
		retries: 5,
		retryDelay: (retryCount) => retryCount * 1000,
		retryCondition: (error) => {
			let responseURL = error?.request?.responseURL as string | undefined
			
			if (responseURL?.includes('/coupons') || responseURL?.includes('/keep-alive')) {
				return false
			}

			if (responseURL?.includes('/carts')) {
				const errorCodesToRetry = [HTTP_CODES.INTERNAL_SERVER_ERROR, HTTP_CODES.BAD_GATEWAY, HTTP_CODES.SERVICE_UNAVAILABLE]
				return errorCodesToRetry.includes(error.response.status)
			}

			if (error?.response?.status && !responseURL?.endsWith('/access-tokens')) {
				// don't retry if request is phone verification
				if (isJsonString(error.config.data) && Object(JSON.parse(error.config.data)).hasOwnProperty('authType')) {
					return JSON.parse(error.config.data).authType !== AUTHENTICATION_TYPE.VERIFICATION_CODE
				}
				const urlsToAvoidRetry = ['/auth/verifications', 'accountSettings']
				if (urlsToAvoidRetry.some((partialUrl) => responseURL?.includes(partialUrl))) {
					return false
				}
				// only getting here if the request fails with 4xx or 5xx
				return error.response.status !== 200
			}
			if (error?.message?.includes('timeout')) {
				return true
			}
			if (error && error.response && !error.response.status) {
				// if the response status code is missing or 0 - this happens when the fb-listener drops a request
				console.error(`Axios response status is missing or 0 - target server dropped the connection??`)
				return true
			}
			if (error?.message) {
				console.error(`${error.message}`)
			}

			return false
		},
		onRetry: (retryCount, error, requestConfig) => {
			console.error(
				`Error '${error}' occurred when requesting '${requestConfig.url}', firing retry attempt: ${retryCount} in ${retryCount * 1000}ms`
			)
		},
	})

	// request interceptor
	axios.interceptors.request.use(
		(config) => {
			// Do something before request is sent

			// This is for redirectURL,
			// adding a uuid property to the payload of "/webFlowAddress" which contains the payload type "getBranchByAddress"

			if (
				config?.headers['content-type'] === 'application/x-www-form-urlencoded;charset=utf-8' &&
				config.url.includes('webFlowAddress') &&
				!isEmpty(config.data)
			) {
				const payload = typeof config.data === 'string' ? JSON.parse(config.data) : config.data
				if (payload.type === 'getBranchByAddress' || (payload.type === 'getBranchesList' && !payload.noFilter)) {
					config.data.uuid = getUUID()
				}
			}
			return config
		},
		(error) => {
			// Do something with request error
			console.error(`Axios error in Request interceptor: ${error}`)
			datadogRum.addError(error)
			return Promise.reject(error)
		}
	)

	// response interceptor
	axios.interceptors.response.use(
		(response) => {
			// Any status code that lie within the range of 2xx cause this function to trigger
			// Do something with response data

			// This is for redirectURL, checking the urls and if there is an error
			// and redirectURL exists - redirect to that page
			const responseUrlsToCheck = ['check_field', 'check_request', 'webFlowAddress']
			if (responseUrlsToCheck.some((item) => response.config.url.includes(item)) && response?.data?.error && response?.data?.redirectURL) {
				sendDDLog({ level: 'info', message: 'order-lock-redirect_interceptors', data: response?.data?.redirectURL })
				window.location = response?.data?.redirectURL
			}
			return response
		},
		(error) => {
			// Any status codes that falls outside the range of 2xx cause this function to trigger
			// Do something with response error
			// This error handler fires AFTER all of the retries have finished unsuccessfully.
			console.error(`Axios error in Response interceptor: ${error}`)
			datadogRum.addError(error)
			return Promise.reject(error)
		}
	)
}

/**
 * Capitalise the given content
 *
 * @param text
 * @param capitaliseOnlyFirstWord - only the first word in a string is effected
 * @returns {*}
 */
export function formatTitle(text, capitaliseOnlyFirstWord = false) {
	return capitaliseOnlyFirstWord ? capitalize(text?.toLowerCase()) : startCase(text?.toLowerCase())
}

export function formatPrice(rawPrice = 0, currency = '', countryCode = '', quantity = 1, withCurrencySymbol = true, forAnalytics = false) {
	// 1.
	// const currencySymbol = getCurrencySymbol(currency)

	// 2.
	let formattedPrice

	if (rawPrice === 0) {
		// console.warn(`rawPrice is 0 (possibly when calculating the increase in price from the original price to the total price with variations) !!!`)
		formattedPrice = '0.00'
	} else {
		try {
			formattedPrice = new Big(rawPrice).times(quantity)
			formattedPrice = formattedPrice.div(100)
			formattedPrice = formattedPrice.toFixed(2)
		} catch (e) {
			console.error(e)
			formattedPrice = '0.00'
		}
	}

	// 2. b)
	if (['CL', 'CO'].includes(countryCode) || ['CLP'].includes(currency)) {
		// eg "32900.00" => "32.900"
		formattedPrice = formattedPrice.replace(/\.00$/, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.')

		if (forAnalytics && (['CL'].includes(countryCode) || ['CLP'].includes(currency))) {
			// for Chile (and Colombia?) we must remove the '.' from the item price (see TT-6987 and EC-287) since '.' is a
			// thousand-separator for Chile and GA is rounding eg. 9.899 to 9 when it's really 9899
			formattedPrice = formattedPrice.replace('.', '')
		}
	}
	if (countryCode === 'AR' || countryCode === 'MX' || currency === 'MXN') {
		// eg "479.00" => "479" - remove the cents since these 2 countries don't have cents in their prices
		// in static pages we don't know the country code but we do know the currency from the params.json
		formattedPrice = formattedPrice.replace(/\.00$/, '')
	}
	if (countryCode === 'BR') {
		// a brazilian formatted amount is eg 'R$ 1.000,20'

		// eg amount is 42345255.45 so add ',' every 3 digits so it will be 42.345.255.45
		formattedPrice = formattedPrice.replace(/\d(?=(\d{3})+\.)/g, '$&.')

		// replace the last '.' with a ','
		formattedPrice = formattedPrice.replace(/.([^.]*)$/, ',$1')
	}

	if (withCurrencySymbol) {
		return addCurrencySymbolToFormattedPrice(formattedPrice, currency)
		// return `${currencySymbol}${formattedPrice}`
	}
	// GTM events don't have the currency symbol since the currency is sent as a separate field
	return `${formattedPrice}`

	// return `${currencySymbol}${formattedPrice}`
}

/**
 * A separte functon that ONLY adds the required currency symbol to an already formatted price
 * Eg the calcgt API response contains an already formatted price and the client-code just needs to add the currency's
 * symbol.
 *
 * @param formattedPrice
 * @param currency
 * @returns {`${string}${string}`}
 */
export const addCurrencySymbolToFormattedPrice = (formattedPrice, currency) => {
	const currencySymbol = getCurrencySymbol(currency)
	return `${currencySymbol}${formattedPrice}`
}

export const formatPriceAsFloat = (...args) => {
	const _priceAsString = formatPrice(...args)
	const _priceAsFloat = parseFloat(_priceAsString)
	return _priceAsFloat
}

export function getCurrencySymbol(currency) {
	return currencies[currency] && currencies[currency].symbol ? currencies[currency].symbol : currency
}

export function getPriceNumberStr(number, region) {
	if (isNumber(number)) {
		try {
			const baseNumber = (number / 100).toFixed(2)
			if (region === 'CO') {
				return baseNumber.replace(/\.00$/, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.')
			}
			if (region === 'AR') {
				return baseNumber.replace(/\.00$/, '')
			}
			return baseNumber
		} catch (e) {
			return '0'
		}
	} else {
		return '0'
	}
}

export function arrayWithItems(arr: unknown): arr is unknown[] {
	return Array.isArray(arr) && arr.length > 0
}

export function spaceToDash(str: string): string {
	return str.replaceAll(/\s/g, '-')
}

export function getByLocaleCode(map: Record<LanguageLocale, string>, locale: LanguageLocale): string {
	const code = localeToCode[locale]
	try {
		return map.hasOwnProperty(code) ? map[code] : map[Object.keys(map)[0]] || '' // we use the user's current locale which is undefined at htmlGen.openRestMenu, if its not defined, we take the first one
	} catch (err) {
		return ''
	}
}

export function getByLangCode(map, langCode) {
	try {
		return Object.hasOwn(map, langCode) ? map[langCode] : map[Object.keys(map)[0]] || '' // we use the user's current locale which is undefined at htmlGen.openRestMenu, if its not defined, we take the first one
	} catch (err) {
		return ''
	}
}

export function firstLetterUpperCase(value) {
	// this function solves design issues for cases which the first letter should
	// be uppercase and the rest lowercase, which cannot be solved with css
	return `${value[0].toUpperCase() + value.slice(1).toLowerCase()}`
}

export function debounce(callback, wait) {
	let timeout
	return (...args) => {
		const context = this
		clearTimeout(timeout)
		timeout = setTimeout(() => callback.apply(context, args), wait)
	}
}

// debouce hook see https://dev.to/gabe_ragland/debouncing-with-react-hooks-jci
export function useDebounce(value, delay) {
	// State and setters for debounced value
	const [debouncedValue, setDebouncedValue] = useState(value)

	useEffect(
		() => {
			// Set debouncedValue to value (passed in) after the specified delay
			const handler = setTimeout(() => {
				setDebouncedValue(value)
			}, delay)

			// Return a cleanup function that will be called every time ...
			// ... useEffect is re-called. useEffect will only be re-called ...
			// ... if value changes (see the inputs array below).
			// This is how we prevent debouncedValue from changing if value is ...
			// ... changed within the delay period. Timeout gets cleared and restarted.
			// To put it in context, if the user is typing within our app's ...
			// ... search box, we don't want the debouncedValue to update until ...
			// ... they've stopped typing for more than 500ms.
			return () => {
				clearTimeout(handler)
			}
		},
		// Only re-call effect if value changes
		// You could also add the "delay" var to inputs array if you ...
		// ... need to be able to change that dynamically.
		[value]
	)

	return debouncedValue
}

const mouseClickEvents = ['mousedown', 'click', 'mouseup']

export function simulateMouseClick(element) {
	mouseClickEvents.forEach((mouseEventType) =>
		element.dispatchEvent(
			new MouseEvent(mouseEventType, {
				view: window,
				bubbles: true,
				cancelable: true,
				buttons: 1,
			})
		)
	)
}

/**
 * Programmatically click on the back arrow link
 */
export function clickBackLink() {
	const element = document.getElementById('backArrowLink')
	simulateMouseClick(element)
}

export function clickCartLink() {
	const element = document.getElementById('cartLink')
	simulateMouseClick(element)
}

export function usePrevious(value) {
	const ref = useRef()
	useEffect(() => {
		ref.current = value
	})
	return ref.current
}

export function findTitle(titleArray, locale) {
	if (titleArray) {
		if (titleArray[locale]) {
			return titleArray[locale]
		}

		const [firstKey] = Object.keys(titleArray)
		return titleArray[firstKey]
	}
	console.error(`no title for this item/variation!`)
	return ''
}

export function buildOrderItem(item, context, qty) {
	// todo: check if it should be 00
	qty = qty || 1
	const orderItem = {}
	orderItem.itemId = item.id
	orderItem.count = qty
	orderItem.title = cloneObj(item.title)
	orderItem.desc = cloneObj(item.description)
	// orderItem.media = item.media;
	orderItem.itemQuantity = item.itemQuantity
	orderItem.couponCode = item.description?.en_ID

	// if (item.priceLabel)
	//	orderItem.priceLabel = item.priceLabel;
	if (item.counter) {
		orderItem.counter = item.counter
	} else {
		orderItem.counter = getRandomUUID()
	}

	if (context == null) {
		orderItem.price = isNumber(item.price) ? item.price : 0
	} else {
		const priceOverride = context.prices ? context.prices[item.id] : null
		orderItem.price = priceOverride != null ? priceOverride : 0
	}

	orderItem.variations = arrayWithItems(item.variations) ? JSON.parse(JSON.stringify(item.variations)) : []
	orderItem.variationsChoices = []
	for (let i = 0; i < orderItem.variations.length; i++) {
		orderItem.variationsChoices.push([]) // push array of order items
	}
	// orderItem.html = true;

	// const parsed = queryString.parse(location.search)

	if (parsed.shared) {
		// if the item was added through a friend
		orderItem.shared = true
	}
	return orderItem
}

export const checkMinimumOrder = () => {
	/* The ‘value’ param needs to be present and to have a non-empty-string value and ‘Cash’ is used.
    But this has no impact on the result from the server. */
	const { request } = queryString.parse(window.location.search)

	// see https://tictuk.atlassian.net/browse/TT-994 as to why Messenger can't call the above API
	if (Infra.appParams.eCommerce) {
		return queueableSendRequest(sendRequest)(
			false,
			`${getDomainByEnv()}check_field?cust=openRest&request=${request}&field=validateMinOrder&value=Cash`,
			'get'
		)
	}
	return {}
}

export const validateAddress = async (address, showLoader = false) => {
	const { request, cust } = queryString.parse(window.location.search)

	if (typeof address === 'string') {
		address = encodeURIComponent(address)
	} else if (address?.addressObject?.formattedAddress) {
		address = JSON.stringify(address)
		address = encodeURIComponent(address)
	}

	const response = await queueableSendRequest(sendRequest)(
		showLoader,
		`${getDomainByEnv()}check_field?cust=${cust}&request=${request}&field=validateAddress&value=${address}`,
		'get'
	)

	return response
}

export function getRandomUUID() {
	function s4() {
		return Math.floor((1 + Math.random()) * 0x10000)
			.toString(16)
			.substring(1)
	}

	return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`
}

export function cloneObj(o) {
	if (o) {
		return JSON.parse(JSON.stringify(o))
	}
	return o
}

export function mergeObjects<T extends object, U extends object[]>(target: T, ...source: U): T & U[number] {
	Object.assign(target, ...source)

	return target
}

/**
 * Generic AJAX method. Can be used for get and post.
 *
 * @param url
 * @param httpMethod
 * @param postDataJSON
 * @param extraheaders
 * @params stopLoading
 * @params timeout - default is 90 seconds or 1.5 mins
 * @params onCompleteCallback - if defined gets called as soon as we get any response from the server (including non 2xx status codes)
 * @returns {Promise<any>}
 */
export async function sendRequest(
	showLoader: boolean,
	url: string,
	httpMethod: Method,
	postDataJSON?: unknown,
	extraheaders?: AxiosRequestHeaders | null,
	stopLoading? = true,
	timeout? = 90000,
	onCompleteCallback: null | ((errorOrData: unknown) => void) = null,
	useCacheBuster = true,
	extraAxiosOptions?: unknown = { withCredentials: false },
	onResponseHeaders: null | ((headers) => void) = null
): Promise<unknown> {
	Infra.setIsRequestPending(true)

	if (useCacheBuster) {
		url += `${url.includes('?') ? '&' : '?'}cb=${Date.now()}`
	}

	// Add testMode flags to every request to the server
	if (typeof Infra.testMode === 'object') {
		const testQueryString = Object.entries(Infra.testMode)
			.map((item) => `${item[0]}=${item[1]}`)
			.join('&')
		if (testQueryString !== '') {
			url += (url.includes('?') ? '&' : '?') + testQueryString
		}
	}

	const throwResponseError = (err: unknown) => {
		if (showLoader) {
			Infra.setLoading(false)
		}
		Infra.setIsRequestPending(false)
		if (onCompleteCallback) {
			onCompleteCallback(err)
		}
		throw err
	}

	const sendResponse = <T>(data: T): T => {
		if (showLoader && stopLoading) {
			Infra.setLoading(false)
		}
		Infra.setIsRequestPending(false)
		if (onCompleteCallback) {
			onCompleteCallback(data)
		}
		return data
	}
	const _mergeHeaders: AxiosRequestHeaders = {
		...{
			// Accept: 'application/json',
		},
		...extraheaders,
	}

	try {
		const _options: { url: string; method: Lowercase<Method>; headers: AxiosRequestHeaders; timeout: number; data?: unknown } = {
			url,
			method: httpMethod.toLowerCase() as Lowercase<Method>,
			headers: _mergeHeaders,
			timeout,
			...extraAxiosOptions,
		}

		if (postDataJSON) {
			_options.data = postDataJSON
		}

		if (showLoader) {
			Infra.setLoading(true)
		}

		const response = await axios(_options)
		if (typeof response.data === 'string' && response.data.includes('403 in Cloudfront')) {
			// found '403' which means the text 'This requested url threw a 403 in Cloudfront' which means the fetched file doesn't exist on S3 but
			// CloudFront is configured to return a 200 and redirect to web-flow-redirect.html. But we need to throw an error for this inside the app
			const err = new Error(`The file '${url}' does not exist!`)
			return throwResponseError(err)
		}

		const { data, headers: responseHeaders } = response

		if (onResponseHeaders) {
			onResponseHeaders(responseHeaders)
		}

		if ((_mergeHeaders.Accept === 'application/json' && typeof data === 'object') || _mergeHeaders.Accept !== 'application/json') {
			return sendResponse(data)
		}

		const err = new Error(`sendRequest ${httpMethod.toUpperCase()} '${url}' did not return JSON but returned '${data}'`)
		return throwResponseError(err)
	} catch (e) {
		return throwResponseError(e)
	}
}
/**
 * Return the server domain for nodejs API calls.
 *
 * For e-commerce customers read this value from the params.json.
 *
 * For non e-commerce customers read it from the QS.
 *
 * Override any value with a QS 'wru' param.
 *
 * If still null then provide default values per environment.
 *
 */
export const getDomainByEnv = (params?: { wru: string }): string => {
	// https://fb-dev1.lji.li
	// https://staging-facebook.tictuk.com

	let wru = params?.wru || Infra?.appParams?.wru
	const { host } = window.location

	const parsed = queryString.parse(window.location.search)

	if (parsed?.wru) {
		// override what is in the params.json with the qs.
		wru = parsed.wru
	}

	if (!wru) {
		// there is no wru param so here are the defaults
		switch (process.env.NODE_ENV) {
			case null:
				// dev
				wru = 'https://staging-facebook.tictuk.com/'
				break
			case 'development':
				// dev
				wru = 'https://staging-facebook.tictuk.com/'
				break
			case 'none':
				// staging
				wru = 'https://staging-facebook.tictuk.com/'
				break
			case 'production':
				if (host.indexOf('tictuk.com') > -1) {
					// non e-commerce customers eg chat, pure web-flow
					wru = 'https://fb.tictuk.com/'
				} else {
					// e-commerce customers (initially germany) so use European server - THIS NEEDS TO BE MORE SOPHISTICATED
					wru = 'https://fb-eu.tictuk.com/'
				}
				break

			default:
				console.error(`unknown process.env.NODE_ENV value: ${process.env.NODE_ENV}`)
		}
	}

	return wru.endsWith('/') ? wru : `${wru}/`
}

export async function webview(cartItems, allItems, showLoader = false, friendName = false, sync = false) {
	// if calcgt has not been called then arrayToSendForCalcGTOrWebViewAPIs = null. calcgt is not called when leaving the menu loaded by a chat app
	const arrayToSend = arrayToSendForCalcGTOrWebViewAPIs || makeJsonItemArrayForAPIRequestNew(cartItems, allItems, (friendName = false))

	const parsed = queryString.parse(window.location.search)
	parsed.tictuk_listener = getDomainByEnv()

	const msg = encodeURIComponent(
		JSON.stringify({
			text: {
				msg: arrayToSend,
				btnClicked: parsed.btnClicked,
				shared: friendName, // is the friend's nickname or is false
			},
			ais: parsed.ais || '6',
		})
	)

	let response = null

	if (sync) {
		const config = `value=${msg}&cust=${parsed.cust || 'openRest'}&request=${parsed.request || User?.session?._id}&field=setItems`

		// the order is added to the user's cart sync.
		console.log(`sending the user's cart to the server synchronously...`)
		response = await queueableSendRequest(sendRequest)(
			showLoader,
			`${parsed.tictuk_listener}check_field?cust=${parsed.cust || 'openRest'}&request=${parsed.request || User?.session?._id}&field=setItems`,
			'post',
			config,
			{
				'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
			}
		)
	} else {
		const config = `message=${msg}&cust=${parsed.cust || 'openRest'}&request=${
			parsed.request || User?.session?._id
		}&webviewFlow=${isWebviewFlow()}`

		// async on the server so calling validateMinOrder after may return the order has not met the min order!
		// NB the response is HTML that even the existing web-flow ignores so I also ignore the response. This API call is purely to get the Cart into the server's Cart
		// so the getOrderSummary API returns the correct data
		response = await sendRequest(showLoader, `${parsed.tictuk_listener}webview`, 'post', config, {
			'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
			Accept: 'text/html',
		})
	}

	// this method is called by the a) webflow (doesn't process the response) and by b) chat when returning to the chat
	// (does process the response)

	// reset this field for next order
	console.log(`reset arrayToSendForCalcGTOrWebViewAPIs for next order`)
	arrayToSendForCalcGTOrWebViewAPIs = null

	return response
}

const makeJsonItemArrayForAPIRequestNew = (cartItems, allItems, friendName = false) => {
	const parsed = queryString.parse(window.location.search)
	parsed.tictuk_listener = parsed.tictuk_listener || parsed.wru

	const noVariations = false

	const courseListArray = []

	if (noVariations) {
		// true when its pizzaHut
	} else {
		// TODO - convert cart items to expected format

		Object.keys(cartItems).map((_itemId) => {
			cartItems[_itemId].map((_cartItem) => {
				const _originalItem = allItems[_itemId]
				const locale = User.preferredLanguage ? codeToLocale[User.preferredLanguage] : User.session.locale

				const variations = _originalItem?.variations || []
				let variationsChoices = []

				if (
					_cartItem.additionsNew &&
					_cartItem.additionsNew[_itemId] &&
					_cartItem.additionsNew[_itemId].variationsChoices &&
					_cartItem.additionsNew[_itemId].variationsChoices.length > 0
				) {
					variationsChoices = buildNestedBillingItems(variations, _cartItem.additionsNew[_itemId].variationsChoices, allItems)
				}

				// check if there's a comment to add as a menu-item (this is how comments are sent to the nodejs!)
				if (_cartItem.comment) {
					// find the array element where this comment should be inserted into the VCs
					const _commentIndex = variations.findIndex((_element) => _element.itemIds.includes(_cartItem.comment.itemId))

					if (_commentIndex > variationsChoices.length - 1) {
						// fill in the missing array elements with empty []
						for (let i = variationsChoices.length; i < _commentIndex; i++) {
							variationsChoices[i] = []
						}
					}

					variationsChoices[_commentIndex] = [
						{
							itemId: _cartItem.comment.itemId,
							count: 1,
							title: {
								[locale]: _cartItem.comment.text,
							},
							desc: {},
							counter: getRandomUUID(),
							price: 0,
							variations: [],
							variationsChoices: [],
							html: true,
						},
					]
				}

				if (variations.length > variationsChoices.length) {
					// add the missing element with an empty array of [] to the variationsChoices array
					variationsChoices = addMissingEmptyVariationChoice(variations, variationsChoices)
				}

				const title = _originalItem?.title
				if (friendName) {
					title[locale] = `${title[locale]} - ${friendName}`
				}

				// the parent item
				const _itemToSend = {
					itemId: _originalItem?.id,
					title,
					price: _originalItem?.price || 0,
					desc: _originalItem?.description,
					count: 1,
					counter: _originalItem?.counter || getRandomUUID(),
					variations,
					variationsChoices,
					html: true,
					// edited: true,
				}

				if (variations.length > 0) {
					_itemToSend.edited = true
				}

				// now push the correct quantiy of this item
				for (let i = 0; i < _cartItem.quantity; i++) {
					courseListArray.push(_itemToSend)
				}
			})
		})
	}

	return courseListArray
}

/**
 * Some variations are optional so perhaps the user didn't seelct a variation-choice for it.
 *
 * This method is called to add an empty [] in the correct index of the variationsChoices.
 * @param variations
 * @param shortVariationsChoices
 * @returns {*}
 */
const addMissingEmptyVariationChoice = (variations: any, shortVariationsChoices: any) => {
	const missingVariationIndices: any[] = []

	variations.forEach((_variation: any, _variationIndex: any) => {
		if (
			(_variation.minNumAllowed && _variation.minNumAllowed === 0) ||
			(!_variation.minNumAllowed && _variation.maxNumAllowed && _variation.maxNumAllowed > 0) ||
			(_variation.minNumAllowed && _variation.maxNumAllowed && _variation.minNumAllowed === _variation.maxNumAllowed)
		) {
			// this variation is optional so check if the user selected a variation-choice for it

			if (shortVariationsChoices[_variationIndex]) {
				if (shortVariationsChoices[_variationIndex].length > 0) {
					const _vc = shortVariationsChoices[_variationIndex]

					// the _vc can be a) an array itself OR b) a variation and have itemId
					if (Array.isArray(_vc)) {
						// a) is an array of choices
						let _vcSelectionIsForV = false
						for (const _vcElement of _vc) {
							if (_variation.itemIds.includes(_vcElement.itemId)) {
								_vcSelectionIsForV = true
								break
							}
						}

						if (!_vcSelectionIsForV) {
							// this VC (which is an array of choices) is not related to the variation so the a user selection for this variation is missing so add an empty [] at this index
							missingVariationIndices.push(_variationIndex)
						}
					} else if (!_variation.itemIds.includes(_vc.itemId)) {
						// this VC is not related to the variation so the a user selection for this variation is missing so add an empty [] at this index
						missingVariationIndices.push(_variationIndex)
					}
				} else {
					console.log(`the array at this index ${_variationIndex} has been set to [] by buildNestedBillingItems() so no need to replace it`)
				}
			} else {
				// this  is missing so add an empty [] at this index
				missingVariationIndices.push(_variationIndex)
			}
		}
	})

	missingVariationIndices.forEach((_missingVariationIndex) => {
		shortVariationsChoices.splice(_missingVariationIndex, 0, [])
	})

	return shortVariationsChoices
}

let safetyNet = 0

/**
 * Recursive method for building nestesd billing variationsChoices JSON
 *
 * @param variationsChoices
 * @param allItems
 * @returns {[]}
 */
const buildNestedBillingItems = (variations, variationsChoices, allItems) => {
	safetyNet++

	const result = []

	if (safetyNet < 10) {
		// a) loop over array of variations/additions
		variationsChoices.forEach((_variationsAssociatedArray) => {
			// const _elem = _cartItem.additionsNew[_itemId].variationsChoices[_idx]

			if (_variationsAssociatedArray) {
				const multiSelectVariations = []

				// b) loop over associated array of items (group them in a single array for all of the associated array items under this array
				Object.keys(_variationsAssociatedArray).forEach((_variationId) => {
					const _variation = _variationsAssociatedArray[_variationId]
					// console.log(_variation)

					const _billingVariation = convertVariationIntoBillingVariation(_variation, allItems)

					if (_variation.quantity) {
						// build quantity VC
						_billingVariation.variationsChoices = [[buildQuantityVariationChoice(_variation, allItems)]]
					} else if (_variation.variationsChoices) {
						// trigger recursion
						const _variationsChoices = buildNestedBillingItems(_billingVariation.variations, _variation.variationsChoices, allItems)
						_billingVariation.variationsChoices = _variationsChoices
						if (_billingVariation.variations.length > _billingVariation.variationsChoices.length) {
							// add the missing element with an empty array of [] to the variationsChoices array
							_billingVariation.variationsChoices = addMissingEmptyVariationChoice(
								_billingVariation.variations,
								_billingVariation.variationsChoices
							)
						}
					} else {
						const _originalItem = JSON.parse(JSON.stringify(allItems[_variationId]))
						if (_originalItem?.variations?.length > 0) {
							// there are OPTIONAL variations, so add an equal number of empty arrays for the variationsChoices
							_billingVariation.variationsChoices = []
							_originalItem?.variations.forEach((_variation) => {
								_billingVariation.variationsChoices.push([])
							})
						} else {
							// variations is an empty array so add a single empty [] for the variationsChoices
							_billingVariation.variationsChoices = []
						}
					}

					multiSelectVariations.push(_billingVariation)
				})

				result.push(multiSelectVariations)
			} else {
				// value is null (eg an item has 5 options, they are all mandatory and have default options except for the 4th option.
				// so the 4th option is not added to the ItemAdditions. But when the 5th option is added to the array, they 4th element
				// is added automatically with a value of null.
				// console.log(`While building the billing json, array elemnt ${_choiceIdx} is null - probably an optional variation`)
				result.push([])
			}
		})
	} else {
		console.error(`breaking out of recursion!!!`)
	}

	safetyNet = 0

	return result
}

const buildQuantityVariationChoice = (quantityVariationChoice, allItems) => {
	const _fullAddition = JSON.parse(JSON.stringify(allItems[quantityVariationChoice.id]))
	const _additionQuantityItem = _fullAddition.variations[0].itemIds[quantityVariationChoice.quantity]

	if (_additionQuantityItem) {
		const { description, displayCondition, condition, id, ..._additionVariationChoice } = allItems[_additionQuantityItem]

		_additionVariationChoice.itemId = id
		_additionVariationChoice.count = 1
		_additionVariationChoice.counter = getRandomUUID()
		_additionVariationChoice.price = 0
		_additionVariationChoice.html = true
		_additionVariationChoice.variations = []
		_additionVariationChoice.variationsChoices = []

		return _additionVariationChoice
	}

	console.error(
		`The variation choice with id: '${quantityVariationChoice.id}', title: '${JSON.stringify(
			_fullAddition.title
		)}' does not have a quantity of: ${
			quantityVariationChoice.quantity
		}. Please check if the data for this Quantity Select option should have this quantity amount. This order will fail!`
	)

	return null
}

const convertVariationIntoBillingVariation = (item, allItems) => {
	const _fullAddition = JSON.parse(JSON.stringify(allItems[item.id]))

	const billingItem = {
		itemId: item.id,
		title: _fullAddition.title,
		desc: _fullAddition.description,
		price: item.price || 0,
		// todo: check if it should be 00
		count: item.quantity || 1,
		counter: _fullAddition.counter || getRandomUUID(),
		variations: _fullAddition.variations || [],
		// variationsChoices: _additionvariationsChoices.length > 0 ? [_additionvariationsChoices] : [], // [_additionvariationsChoices]
		// variationsChoices,
		html: true,
		// ...partsWeWant,
	}

	return billingItem
}

export function isWebviewFlow() {
	// const parsed = queryString.parse(location.search)
	return [
		CONSTANTS.APP.TYPES.WEB.toString(),
		CONSTANTS.APP.TYPES.WEB_MOBILE.toString(),
		CONSTANTS.APP.TYPES.ANDROID_APP.toString(),
		CONSTANTS.APP.TYPES.IOS_APP.toString(),
	].includes(parsed.app)
}

export function isInscription(txt: string): boolean {
	return txt.toLowerCase().indexOf('inscription') > -1 || txt.toLowerCase().indexOf(getTranslatedTextByKey('type').toLowerCase()) > -1
}

export const getGeoLocationPermissionState = async (): Promise<'denied' | 'prompt' | 'granted'> => {
	try {
		const permission = await navigator.permissions.query({ name: 'geolocation' })
		return permission.state
	} catch (error) {
		console.warn('Error fetching geolocation permission state:', error)
		return 'denied'
	}
}

export const getCurrentLocation = (onSuccess, onError) => {
	if (navigator.geolocation) {
		navigator.geolocation.getCurrentPosition((position) => {
			onSuccess({ lat: position.coords.latitude, long: position.coords.longitude })
		}, onError)
	}
}

export const onLocationPermissionDenied = async (err) => {
	Infra.setLoading(false)
	if (
		err?.message === 'User denied Geolocation' ||
		MobileApplication.userLocationPermissionStatus === CONSTANTS?.MOBILE_APP?.PERMISSIONS?.LOCATION?.DENIED
	) {
		const title = getTranslatedTextByKey('webviewFlow.locationPermission', 'Location Permission')
		const message = getTranslatedTextByKey('webviewFlow.pleaseAllowLocationPermission', 'Please allow the location permission on your settings.')

		Infra.setNotification({
			title,
			open: true,
			message,
			okAction: () => {
				Infra.closeNotification()
			},
			okText: getTranslatedTextByKey('webviewFlow.ok', 'OK'),
			showCancelButton: false,
		})

		sendCustomEvent({
			category: 'error',
			action: 'notification',
			label: title,
			message,
		})
		MobileApplication.setLocationPermissionsStatus(CONSTANTS.MOBILE_APP.PERMISSIONS.LOCATION.DENIED)
	}
}

export const isiOS = () => /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream

export function isIPhoneX() {
	if (typeof window !== 'undefined') {
		const iOS = isiOS()
		const ratio = window.devicePixelRatio || 1
		const screen = {
			width: window.screen.width * ratio,
			height: window.screen.height * ratio,
		}

		const iphoneX = screen.width === 1125 && screen.height === 2436
		const iphoneXr = screen.width === 828 && screen.height === 1792
		const iphoneXMax = screen.width === 1242 && screen.height === 2688
		const iphone12Pro = screen.width === 1170 && screen.height === 2532
		const iphone12Max = screen.width === 1284 && screen.height === 2778
		const iphone12Mini = screen.width === 1080 && screen.height === 2340

		if (iOS) {
			if (iphoneX || iphoneXr || iphoneXMax || iphone12Pro || iphone12Max || iphone12Mini) {
				console.log('iPhoneX Detected')
				return true
			}
		}
	}

	return false
}

/**
 * This condition will only check for screen size, not for the actual mobile app
 */
export function isMobile(skipForStaticPages = true): boolean {
	if (typeof window !== 'undefined') {
		if (skipForStaticPages && window.isGeneratedStatically) {
			return false
		}

		if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
			return true
		}

		// the iframe that opens in Messenger is mobile width but the user agent is not for mobile so check the width to show mobile view
		// NB Material UI 'sm' is 600px wide
		if (window.innerWidth < 600) {
			return true
		}
	}

	return false
}

export function isAndroid() {
	if (typeof navigator === 'undefined') {
		return false
	}

	if (/Android/i.test(navigator.userAgent)) {
		return true
	}
	return false
}

export function isIphone() {
	if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
		return true
	}
	return false
}

export function isMobileApp(): boolean {
	if (typeof window !== 'undefined') {
		const mobileApp = !!(window as Window & { ReactNativeWebView?: boolean }).ReactNativeWebView // undefined in a browser, should be set before loading any code
		const forceMobileAppFromQS = ['true', true].includes(queryString.parse(window.location.search).forceMobileApp)
		const forceMobileAppFromLS = localStorage.getItem('forceMobileApp')
		const forceMobileAppFromCookies = nextCookiesState.fromClient.get('forceMobileApp', Cookies)
		const forceMobileAppFromCookiesEnabled = ['true', true].includes(forceMobileAppFromCookies)

		const forceMobileApp = forceMobileAppFromQS || forceMobileAppFromLS || forceMobileAppFromCookiesEnabled

		return !!forceMobileApp || mobileApp
	}
	return false
}

export const getPlatformId = () => {
	if (isMobileApp()) {
		const userAgent = getUserAgent()

		if (userAgent.includes('android')) {
			return SERVER_ANDROID_PLATFORM_ID
		}

		return SERVER_IOS_PLATFORM_ID
	}

	if (isMobile()) {
		return SERVER_MOBILE_WEB_PLATFORM_ID
	}

	if (isKiosk()) {
		return SERVER_KIOSK_PLATFORM_ID
	}
}

export const getUserAgent = () => (window?.navigator?.userAgent ?? '').toLowerCase()

export const isKiosk = () => {
	const isNodeJsEnv = typeof window === 'undefined'

	if (isNodeJsEnv) {
		return false
	}

	return window.location.pathname.includes('/kiosk')
}

export function initGlobalErrorHandler(sendCustomEvent) {
	window.onerror = async function (msg, url, line, col, error) {
		let extra = !col ? '' : `\ncolumn: ${col}`
		extra += !error ? '' : `\nerror: ${error}`

		const errorMessage = `${msg}\nurl: ${url}\nline: ${line}${extra}`
		// alert(`${msg}\nurl: ${url}\nline: ${line}${extra}`)

		if (sendCustomEvent) {
			sendCustomEvent({
				category: 'error',
				action: 'javascript-error',
				label: errorMessage,
			})
		}

		const suppressErrorAlert = false
		// If you return true, then error alerts (like in older versions of
		// Internet Explorer) will be suppressed.
		return suppressErrorAlert
	}
}

export function overrideConsoleError(sendCustomEvent) {
	if (window.console && console.error) {
		const ce = window.console.error

		console.error = function () {
			if (arguments.length && arguments.length > 0) {
				// an error can be a string, an Error object or any other type of objecet. toString() on an object can produce '[object Object]'
				// see https://stackoverflow.com/questions/27731303/why-object-prototype-tostring-return-object-object
				const _label = arguments[0].toString()
				const label = _label === '[object Object]' ? JSON.stringify(arguments[0]) : _label

				// send console.error string to analytics
				if (sendCustomEvent) {
					sendCustomEvent({
						category: 'error',
						action: 'console-error',
						label, // the string sent to console.error('....')
					})
				}
			}

			// call existing console.error
			ce.apply(this, arguments)
		}
	}
}

// pass locale for example "en_US"
export const isRTL = (locale) => {
	if (typeof window !== 'undefined') {
		const { l, lang } = queryString.parse(window.location.search)
		const language = l || lang

		return locale ? localeToDir[locale] === 'rtl' : localeToDir[codeToLocale[language]] === 'rtl'
	}
	return false
}

export const isMenuPage = () => window.location.pathname.includes('/menu')

/**
 * Replaces the token marked by {<key>} in the i18n text
 *
 * @param txt
 * @param params - JSON with {<key>: <value>}
 * @returns {*}
 */
export const replaceTokenInText = (txt, params) => {
	if (!txt) {
		return
	}
	if (arrayWithItems(Object.keys(params))) {
		txt = txt.replace(/{\w+}/g, (all) => params[all.substring(1, all.length - 1)] || '')
	}
	return txt
}

export function convertObjectToArray(object: object): { id: string; [key: string]: unknown }[] {
	let newArray = []
	if (object) {
		for (const [key, value] of Object.entries(object)) {
			newArray = [...newArray, { ...value, id: key }]
		}
	}
	return newArray
}

const generateUUID = () => {
	const GLOBAL_UUID = '4ef35987-934f-4c96-a79a-212d7851eab9'
	const chainId = Infra.appParams.c

	let chainNamespace = uuidv5('', GLOBAL_UUID)
	if (chainId) {
		chainNamespace = uuidv5(chainId, GLOBAL_UUID)
	}

	const mobileAppUniqueId = window.uniqueId
	const uniqueId = localStorage.getItem('deviceUniqueId') || mobileAppUniqueId || uuidv4()

	localStorage.setItem('deviceUniqueId', uniqueId)

	return uuidv5(uniqueId, chainNamespace)
}

export const getUUID = (): string => {
	const { appParams } = Infra

	if (appParams.eCommerce && Account.getUser()?.userPlatformId) {
		// has sign-in feature so check for a signed-in user in local-storage (where Haim stores it)
		return Account.getUser().userPlatformId
	}

	let userUUID = localStorage.getItem('userUUID')
	if (!userUUID) {
		userUUID = generateUUID()
		localStorage.setItem('userUUID', userUUID)
	}

	return userUUID
}

/* this function takes a number which represents minutes and formats it to hours and minutes
  example:
  90 minutes => 1 hour 30 minutes
  45 minutes => 45 minutes
  60 minutes => 1 hour
 */
export function convertMinutes(numOfMinutes) {
	if (numOfMinutes % 60) {
		const hours = Math.floor(numOfMinutes / 60)
		const minutes = numOfMinutes - hours * 60
		return hours && minutes ? { hours, minutes } : hours ? { hours } : { minutes }
	}
	return { hours: Math.floor(numOfMinutes / 60) }
}

export const emphasizeLog = (value, title?, type = 'log') => {
	title ||= 'Value: '
	console[type](`%c${title}%o`, 'color: red;background-color:yellow;font-size: 15px;', value)
}

export const addSelectedItemToUrl = (itemMap) => {
	const firstItemId = Object.keys(itemMap).length > 0 ? Object.keys(itemMap)[0] : null
	if (firstItemId) {
		const courseList = JSON.stringify([firstItemId])
		return `&mode=homePageItem&courseList=${courseList}`
	}
	return ''
}

export const isJsonString = (str) => {
	try {
		JSON.parse(str)
	} catch (e) {
		return false
	}
	return true
}

export const getJsonFromString = (str) => {
	try {
		return JSON.parse(str)
	} catch (e) {
		return {}
	}
}

export const getDecodedString = (str) => {
	try {
		return decodeURIComponent(str)
	} catch (err) {
		return str
	}
}

export const isHamburgerMenuShown = (Store, rest) => {
	const isMetaDataExist = !(Store?.metaData === null)
	const deliveryInfo = isMetaDataExist
		? rest?.deliveryInfo.length > Store?.metaData.deliveryInfo.length
			? rest?.deliveryInfo
			: Store?.metaData.deliveryInfo
		: rest?.deliveryInfo
	const availableDeliveryAreas = deliveryInfo?.filter((item) => item.type === ORDER_TYPES.DELIVERY) || []
	const isDeliveryAreasAvailable = availableDeliveryAreas.length > 0

	// The name is confusing here, because it is a 2 letters code and not en_US
	const locale = User.preferredLanguage ? User.preferredLanguage : localeToCode[User.session.locale]
	const storeHours = Store?.data?.openingHours[0][locale]

	return isDeliveryAreasAvailable || storeHours
}

export const reverseGeoCode = (location, options = {}) => {
	const geocoder = new window.google.maps.Geocoder()
	return new Promise((resolve) => {
		geocoder.geocode({ location, ...options }, (responses) => {
			resolve(responses?.[0])
		})
	})
}

export const setRequestInCookie = (request) => {
	Cookies.set(REQUEST_PARAM, request, { expires: REQUEST_COOKIE_EXPIRY_IN_HOURS / 24 })
}

/**
 * Add an object to an existing cookie with an object structure, if the cookie doesn't exist
 * a new cookie will be created with the of "objectData" parameter which need to be an object
 * the function also handling the parsing to fetch/store the cookie data.
 * so no need to consider parsing when using this function.
 * @param name: string | the name of the cookie
 * @param objectData: plain object | the object to add/overwrite to the cookie
 * @param options: plain object | an object of properties
 * @returns undefined
 */
export const addToCookieObject = (name, objectData, options = {}) => {
	const cookieObject = Cookies.get(name)
	let parsedCookieObjectData = {}
	if (Cookies.get(name)) {
		parsedCookieObjectData = JSON.parse(cookieObject)
	}
	const newCookieObjectData = {
		...parsedCookieObjectData,
		...objectData,
	}
	Cookies.set(name, JSON.stringify(newCookieObjectData), options)
}

/**
 * get the field's value from the cookie which its structure is an object.
 * if the cookie contains the field, its value will be returned
 * @param name: string | the name of the cookie
 * @param field: string | the object to add/overwrite to the cookie
 * @returns any
 */
export const getFromCookieObject = (name, field) => {
	const cookieObject = Cookies.get(name)
	if (cookieObject) {
		const parsedCookieObjectData = JSON.parse(cookieObject)
		return parsedCookieObjectData[field]
	}
}

export const removeCookie = (name) => {
	Cookies.remove(name)
}

export const getTenantInfo = () => {
	const queryParams = Infra.appParams
	const { c, pc } = queryParams

	return c || pc
}
/**
 * if we have the user's name, phone, e-mail and address either from a cookie or from the server, we return true to show the returning user design
 * @param User
 * @param deliveryType
 * @returns {boolean}
 */
export const checkUserDetailsExistence = (User, deliveryType, addressManagerV2Store: AddressManagerV2Store) => {
	if (deliveryType === CONSTANTS.DELIVERY_METHODS.DELIVERY) {
		const orderType = deliveryType === CONSTANTS.DELIVERY_METHODS.DELIVERY ? ORDER_TYPES.DELIVERY : ORDER_TYPES.PICKUP
		const address = addressManagerV2Store.getFormattedAddressByOrderType(orderType)
		if (User.session.forceAddrInstructions && !User.session.addressComments) {
			return false
		}
		return !!(User.session.nickname && User.session.email && User.session.phone && address)
	}
	return !!(User.session.nickname && User.session.email && User.session.phone)
}

/**
 * Checking if the current store which is denoted by pc for chain id and j for store id is the same or not
 * if it's the same it means the store is not part of a chain and stand by itself, otherwise part of a chain
 * @returns {boolean}
 */
export const isCurrentStorePartOfChain = (storeId) => {
	const parsed = queryString.parse(window.location.search)
	return parsed?.pc !== storeId
}

export const getECommerceDomainByEnv = (params?: object) => {
	let wruec = params?.wruec || Infra?.appParams?.wruec

	const parsed = queryString.parse(location.search)

	if (parsed?.wruec) {
		// override what is in the params.json with the qs.
		wruec = parsed.wruec
	}

	if (!wruec) {
		// there is no wruec param so here are the defaults
		switch (process.env.NODE_ENV) {
			case null:
				// dev
				wruec = 'https://staging-ecommerce.tictuk.com/'
				break
			case 'none':
				// staging
				wruec = 'https://staging-ecommerce.tictuk.com/'
				break
			case 'production':
				// e-commerce customers (initially germany) so use European server - THIS NEEDS TO BE MORE SOPHISTICATED
				wruec = 'https://ecom-eu.tictuk.com/'
				break
			default:
				console.error(`unknown process.env.NODE_ENV value: ${process.env.NODE_ENV}`)
		}
	}

	return wruec
}

// call check_field...setItems which adds the cart to the server sync. AND checks if it has reached the min order
export const setItemsAPI = async (items, rest) => {
	const setItemsResponse = await webview(items, rest.items, true, false, true)

	// a) check cart was successfully added to the user's order on the server
	if (setItemsResponse.error) {
		Infra.setErrorNotification(setItemsResponse.msg, true, true)
		return false
	}

	// b) check the min order was met
	if (setItemsResponse.isMinMet && setItemsResponse.isMinMet[1]) {
		if (setItemsResponse.isMinMet[1].error) {
			Infra.setErrorNotification(setItemsResponse.isMinMet[1].msg, true, true)
			return false
		}
	}

	return true
}

export const getStore = async (queryParams, addressManagerV2Store: AddressManagerV2Store = null, extraHeaders: object = null, cookies: object = null) => {
	queryParams.tictuk_listener = queryParams.tictuk_listener || queryParams.wru
	queryParams.tictuk_listener = queryParams.tictuk_listener.endsWith('/') ? queryParams.tictuk_listener : `${queryParams.tictuk_listener}/`
	const orderType = User.getOrderType() === CONSTANTS.DELIVERY_METHODS.DELIVERY ? ORDER_TYPES.DELIVERY : ORDER_TYPES.PICKUP
	
	const addressManager = addressManagerV2Store || AddressManager
	const { lat, lng } = addressManager.getAddressCoordinatesByOrderType(orderType, cookies)

	const latLngOfAddr = {
		addr: {
			lat,
			lng,
		},
	}

	const getStoreResponse = await sendRequest(
		true,
		`${queryParams.tictuk_listener}check_field?cust=${queryParams.cust}&request=${queryParams.request}&field=getStore&value=${
			latLngOfAddr ? JSON.stringify(latLngOfAddr) : ''
		}`,
		'get',
		false,
		extraHeaders
	)

	if (getStoreResponse?.msg?.id) {
		const storeMetaData = getStoreResponse.msg
		localStorage.setItem('orderConfirmationLink', storeMetaData?.orderConfirmationJSONLink || '')

		return storeMetaData as StoreMetaData

		// TODO - update hours and delivery options under the menu once the design for the menu has been agreed
	}
	return null
}

export const checkRequest = async (queryParams, stopLoading = true, extraHeaders: object = null) => {
	queryParams.tictuk_listener = getDomainByEnv()

	const msg = await sendRequest(
		true,
		`${queryParams.tictuk_listener}check_request?cust=${queryParams.cust}&request=${queryParams.request}`,
		'get',
		null,
		extraHeaders,
		stopLoading
	)

	if (msg && !msg.OK) {
		// Master right now is printing "Session has expired", so this is constant behaviour
		throw new CustomError(HTTP_CODES.SESSION_EXPIRED, 'your session has expired so loading the home page in english')
	}
	return msg
}

export const redirect = (path: string) => {
	const url = `${window.location.protocol}//${window.location.host}${path}`
	console.log(`Redirection to "${url}" ...`)
	window.location.href = url // simulate a mouse click
}

export const redirectToHomepage = (router) => {
	const parsed = Infra.appParams
	const envPath = (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'none') && !Infra.appParams.eCommerce ? '/web-flow/' : '/home'

	let homepageUrl = `${envPath}`

	if (location.host.toLowerCase().indexOf('tictuk.com') > -1) {
		// for chat
		homepageUrl = `${envPath}home?l=${User.preferredLanguage}&c=${parsed.pc || parsed.c}`
		window.location.href = `${homepageUrl}`
	} else if (location.pathname.indexOf('.html') > -1) {
		// on a static page so load the SPA
		window.location.href = `${homepageUrl}`
	} else {
		// in the SPA so just re-route to the home page
		if (router) {
			router.push(homepageUrl)
		} else {
			window.location.href = `${homepageUrl}`
		}
	}
}

// TT-1796
// receives google places object and converts it to the format that validateAddress API expects
// this is a workaround to a server issue which doesn't validate strings from google autocomplete correctly
// please refer to the task on JIRA to see the expected format
export const convertGooglePlaceObjToServerObj = (googlePlacesObj) => {
	const validateAddressAddressObj = { addressObject: {} }
	if (googlePlacesObj.formatted_address && googlePlacesObj.geometry.location) {
		validateAddressAddressObj.addressObject.formattedAddress = googlePlacesObj.formatted_address
		if (typeof googlePlacesObj.geometry.location.lat === 'function') {
			validateAddressAddressObj.addressObject.latitude = googlePlacesObj.geometry.location.lat() || googlePlacesObj.geometry.location.lat
			validateAddressAddressObj.addressObject.longitude = googlePlacesObj.geometry.location.lng() || googlePlacesObj.geometry.location.lng
		} else {
			validateAddressAddressObj.addressObject.latitude = googlePlacesObj.geometry.location.lat || googlePlacesObj.geometry.location.latitude
			validateAddressAddressObj.addressObject.longitude = googlePlacesObj.geometry.location.lng || googlePlacesObj.geometry.location.longitude
		}
	}
	validateAddressAddressObj.addressObject.types = googlePlacesObj.types
	if (googlePlacesObj.address_components) {
		googlePlacesObj.address_components.forEach((component) => {
			switch (component.types[0]) {
				case 'street_number':
					validateAddressAddressObj.addressObject.streetNumber = component.long_name
					break
				case 'subpremise':
					validateAddressAddressObj.addressObject.subpremise = component.long_name
					break
				case 'route':
					validateAddressAddressObj.addressObject.streetName = component.long_name
					break
				case 'locality':
					validateAddressAddressObj.addressObject.city = component.long_name
					break
				case 'country':
					validateAddressAddressObj.addressObject.countryCode = component.short_name
					validateAddressAddressObj.addressObject.country = component.long_name
					break
				case 'sublocality_level_1':
				case 'political':
				case 'sublocality':
				case 'neighborhood':
					if (!validateAddressAddressObj.addressObject.extra) {
						validateAddressAddressObj.addressObject.extra = { neighborhood: component.long_name }
					}
					break
				case 'administrative_area_level_1':
					validateAddressAddressObj.addressObject.state = component.short_name
					validateAddressAddressObj.addressObject.stateLong = component.long_name
					break
				case 'administrative_area_level_2':
					validateAddressAddressObj.addressObject.district = component.long_name
					break
				case 'postal_code':
					validateAddressAddressObj.addressObject.zipcode = component.long_name
					break
				default:
					break
			}
		})
	}
	if (!validateAddressAddressObj.addressObject.city) {
		validateAddressAddressObj.addressObject.city =
			validateAddressAddressObj.addressObject.district || validateAddressAddressObj.addressObject.state
	}
	if (!validateAddressAddressObj.addressObject.streetName) {
		validateAddressAddressObj.addressObject.streetName =
			validateAddressAddressObj.addressObject.zone || validateAddressAddressObj.addressObject.city
	}

	return validateAddressAddressObj
}

/**
 * Language file location syntax:
 * https://cdn.tictuk.com/conversationTexts/${orgID}/${lang}/conversationText.json
 * OR
 * https://cdn.tictuk.com/staging/conversationTexts/${orgID}/${lang}/conversationText.json
 *
 * Example:
 * https://cdn.tictuk.com/conversationTexts/7646423017805410/es/conversationText.json
 *
 *
 * If the file can't be found then load the fallback file at:
 * https://cdn.tictuk.com/conversationTexts/${lang}/conversationText.json
 *
 * Eg detault Spanish language file is at:
 * https://cdn.tictuk.com/conversationTexts/es/conversationText.json
 *
 *
 * @param chainId
 * @param languageCode
 */
export const fetchLanguageFile = async (chainId, languageCode: LanguageCode, appParams: AppParams = null) => {
	const useProductionMenu = appParams?.useProductionMenu || Infra.appParams?.useProductionMenu

	const staging = (process.env.NODE_ENV === 'production' && process.env.NEXT_PUBLIC_ENV === 'PROD') || useProductionMenu ? '' : 'staging/'
	const url = `https://cdn.tictuk.com/${staging}conversationTexts/${chainId}/${languageCode}/conversationText.json`

	try {
		// 1. get language file for this chain
		const languageFile = await sendRequest(true, url, 'get')
		return languageFile
	} catch (e) {
		const defaultUrl = `https://cdn.tictuk.com/${staging}conversationTexts/${languageCode}/conversationText.json`

		try {
			// 2. a chain language file was not found so get the language file for this language
			const defaultLanguageFile = await sendRequest(true, defaultUrl, 'get')
			console.log(`Loaded default conversation-text file for lang: ${languageCode}`)
			return defaultLanguageFile
		} catch (defaultE) {
			console.error(`Cannot find the default language file: ${languageCode} ${defaultE}`)
		}
	}
}

/**
 * check if field has orderType key and check it matches the ot param, else return true if it has no orderType key at all
 * @param fieldObj - object that holds the additional field data
 * @param parsedOT - holds the ot param from the URL which indicates the order type
 * @returns {boolean|*}
 */
export const isAdditionalFieldShownForOrderType = (fieldObj, parsedOT) => {
	const [ot] = parsedOT.split('-')
	if (fieldObj.orderType) {
		return fieldObj.orderType.includes(ot)
	}
	return !Object.keys(fieldObj).includes('orderType')
}

/**
 * If one of a QS options has a default value, then this QS is required.
 *
 * @param options
 * @returns {boolean}
 */
export const getDefaultQuantitySelections = (options) => {
	const response = { requiredTotal: 0 }

	for (const option of options) {
		if (option.variations && option.variations.length > 0) {
			for (const variation of option.variations) {
				if (variation.defaults && variation.defaults.length > 0) {
					// the QS is required since one of its options has a default value
					const requiredQuantityForThisItem = variation.itemIds.findIndex((element) => element === variation.defaults[0])
					response[option.id] = {
						requiredQuantityForThisItem,
					}
					response.requiredTotal += requiredQuantityForThisItem
					// return true
				}
			}
		}
	}

	// the QS is optional
	// return false
	return response
}

export const isHeadlessBrowser = () => !!queryString.parse(location?.search ?? '')[IS_HEADLESS_BROWSER_QUERY_PARAM_KEY]

// taken from nodejs generalUtil.initAddressFormParams this function builds the text param which is then passed to the checkout map iframe
export const buildCheckoutMapTextParam = () => {
	const addressFormTexts = {}
	if (getTranslatedTextByKey('dragMapForAccuracy')) {
		addressFormTexts.dragMapForAccuracy = getTranslatedTextByKey('dragMapForAccuracy')
		addressFormTexts.pleaseFillWhatIsRelevantToYou = getTranslatedTextByKey('pleaseFillWhatIsRelevantToYou')
		addressFormTexts.city = getTranslatedTextByKey('firstOrder.state3[0]')
		addressFormTexts.street = getTranslatedTextByKey('firstOrder.state3[1]')
		addressFormTexts.houseNumber = getTranslatedTextByKey('houseNumber')
		addressFormTexts.district = getTranslatedTextByKey('district')
		addressFormTexts.state = getTranslatedTextByKey('state')
		addressFormTexts.zone = getTranslatedTextByKey('zone')
		addressFormTexts.zipcode = getTranslatedTextByKey('addr.zipcode')
		addressFormTexts.submit = getTranslatedTextByKey('submit')
		addressFormTexts.closeWindowManually = getTranslatedTextByKey('closeWindowManually')
		addressFormTexts.instructions = getTranslatedTextByKey('images.instructions')
		addressFormTexts.instructionsLabel = getTranslatedTextByKey('webviewFlow.getAddrCommentsSubtitle')
		addressFormTexts.getAddrTitle = getTranslatedTextByKey('webviewFlow.getAddrTitle')
		addressFormTexts.enterAddressAndSelectFromTheDropdown = getTranslatedTextByKey('enterAddressAndSelectFromTheDropdown')
		addressFormTexts.centerMeDisabledLink = getTranslatedTextByKey('centerMeDisabledLink')
		addressFormTexts.centerMeDisabledEnd = getTranslatedTextByKey('centerMeDisabledEnd')
		addressFormTexts.centerMeTitle = getTranslatedTextByKey('centerMeTitle')
		addressFormTexts.addressValidationError = getTranslatedTextByKey('addressValidationError')
	}
	if (getTranslatedTextByKey('webviewFlow.whereTo')) {
		addressFormTexts.whereTo = getTranslatedTextByKey('webviewFlow.whereTo').toUpperCase()
	}
	return JSON.stringify(addressFormTexts)
}

// example on how to use:
// 	injectVarsToTemplateString('/task/{module}?taskId={taskId}#{hash}', {
// 		module: 'foo',
// 		taskId: 2,
// 		hash: 'bar'
// 	});
// will produce the following string '/task/foo?taskId=2#bar'
// taken from: https://stackoverflow.com/questions/36994853/javascript-to-replace-variable-in-a-string
export const injectVarsToTemplateString = (string, obj) => {
	let s = string
	for (const prop in obj) {
		s = s.replace(new RegExp(`{${prop}}`, 'g'), obj[prop])
	}
	return s
}

/**
 * Error handler to load a default image for the given event (e). If the default image is also not found then output
 * the error
 * @param e
 */

export const getBase64MimeType = (encoded) => {
	let result = null

	if (typeof encoded !== 'string') {
		return result
	}

	const mime = /data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/.exec(encoded)

	if (mime?.length) {
		result = mime[1]
	}

	return result
}

// this function builds dynamically the buttons which will be displayed in the store locator start my order popup
// this buttons are time dependant and will only show if the store is open currently for the given order type
export const buildStoreLocatorPopupObj = (chainStores = null, store = null, storeId = null) => {
	const popupChildrenObj = {}
	const selectedStore = store || chainStores?.find((currStore) => currStore.id === storeId)
	const hasPreOrder = selectedStore?.availability?.preOrder
	if (Object.hasOwn(selectedStore?.availability, ORDER_TYPES.DELIVERY) && (selectedStore?.availability?.delivery?.availableNow || hasPreOrder)) {
		popupChildrenObj.delivery = true
	}
	if (Object.hasOwn(selectedStore?.availability, ORDER_TYPES.PICKUP) && (selectedStore?.availability?.pickup?.availableNow || hasPreOrder)) {
		popupChildrenObj.peakup = true
	}
	return popupChildrenObj
}

/**
 * The old website has cookies and LS that needs to be cleaned from the browser to enable the cooie GDPR banner to work.
 * We search for the [oldCookies] array of cookies in the browser. If any one is present then we know this is the 1st
 * time a user now visiting the new website. So we must delete all Cookies and LS before proceeding.
 *
 * @param oldCookies
 */
export const checkIfNeedToCleanOldWebSiteCookiesAndStorage = (oldCookies) => {
	let oldCookieFound = false

	oldCookies.forEach((_cookie) => {
		if (Cookies.get(_cookie)) {
			oldCookieFound = true
			console.log(`old site's cookie called '${_cookie}' found so clean the cookies`)
		}
	})

	if (oldCookieFound) {
		// at least 1 old cookie is present so clean the site

		// a) reset cookies
		Object.keys(Cookies.get()).forEach((cookieName) => {
			const neededAttributes = {
				// TODO - if the old site uses a specific path or domain or http only etc. then we cannot know these attributes
				// so we cannot delete these old cookies!
				// see https://github.com/js-cookie/js-cookie
				// - 'When deleting a cookie and you're not relying on the default attributes, you must pass the exact
				// same path and domain attributes that were used to set the cookie:'
				// Here you pass the same attributes that were used when the cookie was created
				// and are required when removing the cookie
			}
			Cookies.remove(cookieName, neededAttributes)
		})

		// b) reset LS
		localStorage.clear()

		console.log('All cookies and local storage have been cleared')
	} else {
		// no need to clean cookies no old cookies found so  this is not the first time this user has visited the
		// new web-site OR they have never visited the old web-site
		console.log(
			`None of the old site's cookies were found so the user either never visited the old site OR this is not the 1st time they are visiting the new site`
		)
	}
}

/**
 * The default mui theme contains styling for mui components which we use. It's too much work to completely remove this
 * now. So we must merge the brand's theme into the default MUI theme to be backwards compatible.
 *
 * @param brandTheme - contains {fonts, icons, palette, typogrpahy}
 * @param muiTheme - from mui
 * @param direction - 'ltr' or 'rtl'
 * @returns {Theme}
 */
export const buildTheme = (brandTheme, muiTheme, direction): Theme => {
	const { fonts, icons, ...ourBrandTheme } = brandTheme

	// TODO a) load fonts and b) icons

	// c) merge the brandTheme into the default MUI theme
	merge(muiTheme, ourBrandTheme)

	// create the combined MUI theme
	const _muiTheme = createTheme({
		direction,
		...muiTheme,
	})

	return _muiTheme
}

export const hideOneTrustLogo = (eCommerceFooter) => {
	if (eCommerceFooter) {
		const findAllItemTypes = (sections) =>
			sections
				.map((section) => section.items)
				.flat()
				.map((item) => item.itemType)
		const isCookieConsent = (type) => type === 'cookieConsent'

		const hideOneTrustDiv = () => {
			const oneTrustDiv = document.getElementById('ot-sdk-btn-floating')
			if (oneTrustDiv) {
				oneTrustDiv.style.display = 'none'
			}
			return !!oneTrustDiv
		}

		if (findAllItemTypes(eCommerceFooter.sections).some(isCookieConsent)) {
			const _t = setInterval(() => {
				const oneTrustIsHidden = hideOneTrustDiv()
				if (oneTrustIsHidden) {
					clearInterval(_t)
				}
			}, CONSTANTS.COOKIE_CONSENT_INTERVAL_CHECK_MS)

			// if the OneTrust div was not found after 10 seconds, kill the interval
			setTimeout(() => {
				clearInterval(_t)
			}, 10000)
		}
	}
}

export const sanitizeStoreId = (storeId: string): string => storeId.replace('_dev', '')

export function normalizeFileNameStr(name) {
	// remove accents/diacritics in a string
	let normalizedName = name.normalize('NFD') // unicode normal form decomposes combined graphemes into the combination of simple ones
	normalizedName = normalizedName.replace(/[\u0300-\u036f]/g, '') // using a regex character class to match the U+0300 → U+036F range and then get rid of the diacritics
	// replace all not alphabetic and not numeric characters
	normalizedName = normalizedName.replace(/[^a-z0-9]/gi, '_')
	// to lowercase
	return normalizedName.toLowerCase()
}

export const getMenuItemPageName = (itemName, itemId) => {
	const displayedIdLength = 4
	const endIndex = itemId.length
	const startIndex = endIndex - displayedIdLength

	return `${normalizeFileNameStr(itemName)}_${itemId.substring(startIndex, endIndex)}`
}

export const sanitizeId = (text) => {
	if (!text) {
		return ''
	}
	text = `${text}`.trim()
	// this function allows all alpha-numeric and characters with accents, the rest will be replaced with underscore.
	const regexToExcludeNonAlphanumeric = /[^a-zA-Zčćçaāńēėęžźżuūōœśš\d\s:\u00C0-\u00FF]/g

	return text?.replace(regexToExcludeNonAlphanumeric, '_').replace(/ /g, '_').trim()
}

/**
 * The 'universal' ID of an item is only correct for the same item among stores of the same delivery type. When moving
 * between stores we need to update this value by changing the last character if there is a '§§' delimeter.
 *
 * @param en_ID - can be eg '30202§§d' (for delivery store) or '30202§§p' (for pickup store)
 * @param orderType - can be 'delivery', 'peakup'
 */
export const updateItemENIDPerOrderType = (en_ID, orderType) => {
	const pos = en_ID.indexOf('§§')
	if (pos > -1) {
		en_ID = en_ID.slice(0, -1) + (orderType === ORDER_TYPES.DELIVERY ? 'd' : 'p')
	}

	return en_ID
}

/**
 * An example url can have a has eg:
 * https://pizzahut-tt.com/#item_LENT2022_1_1
 *
 * We need to split the hash on the 1st occurrence of the given delimiter (currently '_') and extract everything on the right of it.
 *
 * @param hash
 */
export const getCodeFromUrlHash = (hash, delimiter = '_') => {
	const posOfFirstDelimiter = hash.indexOf(delimiter)
	const rawCode = hash.substr(posOfFirstDelimiter + 1)
	const [code] = rawCode.split(ITEM_CODE_ORDER_TYPE_SEPARATOR)
	return code
}

export const getVariationSelectionObj = (variation: Variation) => {
	const len = variation.itemIds ? variation.itemIds.length : 0
	let min = variation.minNumAllowed ? variation.minNumAllowed : 0
	let max = variation.maxNumAllowed ? variation.maxNumAllowed : len
	if (min > len) {
		min = len
	}
	if (max > len) {
		max = len
	}

	return {
		min,
		max,
		len,
	}
}

export const areAllVariationsHidden = (itemVariations: Variation[], rest: { items: Record<string, { variations: Variation[] }> }) => {
	let countHiddenVariations = 0
	const itemVariationsLen = itemVariations.length

	// 1. loop over the variations
	// eslint-disable-next-line no-labels
	loop1: for (const _variation of itemVariations) {
		const variationSelectionObj = getVariationSelectionObj(_variation)

		// the code in MenuItemPageSections.jsx (line 79) has a different check to see if a variation is hidden which seems
		// to be limited to only variations with a min of 1!

		// check if the min and max and num of defaults are the same length, if so then the variation will be hidden
		if (
			variationSelectionObj.min === variationSelectionObj.len &&
			_variation.defaults &&
			_variation.defaults.length === variationSelectionObj.len
		) {
			countHiddenVariations += 1

			// 2. now loop over the default items' variations
			for (const _defaultItemId of _variation.defaults) {
				const _defaultItem = rest.items[_defaultItemId]

				if (!_defaultItem) {
					continue
				}

				if (_defaultItem.variations) {
					const innerVariationsAreHidden = areAllVariationsHidden(_defaultItem.variations, rest)

					if (!innerVariationsAreHidden) {
						// force the method to return false since an inner variation is not hidden
						countHiddenVariations = -10000
						console.log(`inner level of variations is not hidden`)
						// eslint-disable-next-line no-labels
						break loop1
					}
				}
			}
		} else {
			break
		}
	}
	return itemVariationsLen === countHiddenVariations
}

// we update the limited time offer JSON to have an additional ‘deliveryType’ key/value so we can know in the home-page which tab to highlight.
export const editLimitedOffersToHaveDeliveryType = (limitedOffers) => {
	if (limitedOffers.peakup && limitedOffers.peakup.length) {
		limitedOffers.peakup.forEach((limitedItem) => {
			limitedItem.deliveryType = CONSTANTS.DELIVERY_METHODS.PICKUP
		})
	}

	if (limitedOffers.delivery && limitedOffers.delivery.length) {
		limitedOffers.delivery.forEach((limitedItem) => {
			limitedItem.deliveryType = CONSTANTS.DELIVERY_METHODS.DELIVERY
		})
	}

	return limitedOffers
}

/**
 * We need to provide the store's POS ID for the item for their analytics
 *
 * @param item
 * @returns {string|null|string|*}
 */
export const getItemId = (item) => {
	// menu JSON has item.description
	if (item.description?.integrationNumber && item.description.integrationNumber !== '') {
		// the store's POS ID
		return item.description.integrationNumber
	}

	if (item.description?.en_ID && item.description.en_ID !== '') {
		// the universal ID
		return item.description.en_ID
	}

	// courseList JSON returned from the /orderConfirm API uses item.desc
	if (item.desc?.integrationNumber && item.desc.integrationNumber !== '' && item.desc.integrationNumber !== 'XXX') {
		// the store's POS ID
		return item.desc.integrationNumber
	}

	if (item.desc?.en_ID && item.desc.en_ID !== '' && item.desc.en_ID !== 'XXX') {
		// the universal ID
		return item.desc.en_ID
	}

	if (item.en_ID && item.en_ID !== '') {
		return item.en_ID
	}

	// our ID
	return item.id || item.itemId
}

export const getSectionTitleForItem = (itemId: string, sections: MenuSection[], locale: LanguageLocale): string => {
	let sectionTitle = 'UNKNOWN SECTION'

	// eslint-disable-next-line no-labels
	outerLoop: for (const section of sections) {
		const _itemIdsArray = section.itemIds
		for (const elementItemId of _itemIdsArray) {
			if (elementItemId === itemId) {
				sectionTitle = getLocaleStr(section.title, locale)
				// eslint-disable-next-line no-labels
				break outerLoop
			}
		}
	}

	return sectionTitle
}

export const getSectionIdForItem = (itemId, sections) => {
	let sectionId = ''

	if (itemId && sections?.length > 0) {
		for (const section of sections) {
			const _itemIdsArray = section.itemIds
			for (const _itemId of _itemIdsArray) {
				if (_itemId === itemId) {
					sectionId = section.id
					break
				}
			}
		}
	}

	return sectionId
}

export const getStoreName = (store, orderType = '', locale = '', addressManagerV2Store: AddressManagerV2Store = null) => {
	let localeStoreName = ''

	try {
		if (store && store.data) {
			const {
				data: { locale: storeLocale, name: storeName },
			} = store
			if (storeLocale && storeName) {
				localeStoreName = storeName[storeLocale]
			}
		} else if (orderType && locale) {

			const addressManager = addressManagerV2Store || AddressManager

			const localizedAddress = addressManager.getAddressFromLocaleStorage()
			const title = localizedAddress[orderType]?.title
			localeStoreName = title ? title[locale] : ''
		}

		if (!localeStoreName) {
			localeStoreName = localStorage.getItem('storeId') || ''
		}
	} catch {}

	return localeStoreName
}

export const isMenuAutoLoad = (params) => params.j && params.c && params.ot

/**
 *
 * @param locale - is eg 'es_ES' which needs to be converted to 'es-ES'
 */
export const formatLocalDateTime = (locale) => {
	const _dateTime = new Date()
	let _localDateTime = ''
	const _locale = locale.replace('_', '-')
	const _options = {
		year: 'numeric',
		month: 'short',
		day: 'numeric',
		hour: '2-digit',
		minute: '2-digit',
		second: '2-digit',
	}
	try {
		_localDateTime = _dateTime.toLocaleDateString(_locale, _options)
	} catch (e) {
		console.error(e)
		_localDateTime = _dateTime
	}

	return _localDateTime
}

/**
 * Taken from the node-js repo.
 *
 * This checks if an item exists, if its variations exist and if its price has not changed.
 *
 * @param itemToValidate
 * @param rest
 * @param dontAllowOneTimeItems
 * @returns {boolean}
 */
export const isItemValidForReorder = (itemToValidate, menu, dontAllowOneTimeItems) => {
	try {
		const restCurrentItem = menu.items[itemToValidate.itemId]
		// if still exists and part of the menu
		if (restCurrentItem && restCurrentItem.sectionItem) {
			// if both has no variations return true. OR if both has the same variations return true as well
			if (
				(!arrayWithItems(restCurrentItem.variations) && !arrayWithItems(itemToValidate.variations)) ||
				isEqual(restCurrentItem.variations, itemToValidate.variations)
			) {
				if (restCurrentItem.price && restCurrentItem.price > 0 && itemToValidate.price && restCurrentItem.price === itemToValidate.price) {
					// and the price of the item hasn't changed and its also above 0 (Free items will not appear in the history)
					if (!dontAllowOneTimeItems || !restCurrentItem.description || !restCurrentItem.description.oneTime) {
						return true
					}
				}
			} else {
				// either the item has no variations OR its variations are different to what's in the menu
				console.warn(`The item with id: ${itemToValidate.itemId} is different in the latest menu as follows:`)
				const dif = differenceWith(restCurrentItem.variations, itemToValidate.variations, isEqual)
				console.warn(dif)
			}
		}
	} catch (e) {
		console.error(
			`An exception occurred during item validation. rest(menu) id: ${menu && menu._id ? menu._id : 'not found'}, item id: ${
				itemToValidate && itemToValidate.itemId ? itemToValidate.itemId : 'not found'
			}, exception stack: ${e.stack}`
		)
	}

	return false
}

export const scrollToElement = (elem: HTMLElement | null, callback?: () => void) => {
	if (elem?.scrollIntoView) {
		elem.scrollIntoView({ behavior: 'smooth', block: 'center' })
	} else {
		const elemPosition = elem?.getBoundingClientRect()
		window.scrollTo({
			top: elemPosition.y,
			left: elemPosition.x,
			behavior: 'smooth',
		})
	}
	elem?.focus()
	callback?.()
}

export const redirectToChainMenu = (lang, router) => {
	router.push(`/${lang}/menu`)
}

/**
 * Function for redirection to the relevant chat platform
 * */
export const getTargetChatUrl = (targetChat, appId) => {
	let path = ''
	switch (appId) {
		case CONSTANTS.APP.TYPES.MESSENGER.toString():
			path = `https://m.me/${targetChat}`
			break

		case CONSTANTS.APP.TYPES.WHATSAPP.toString():
			// path = `https://wa.me/${targetChat}?text=${getTranslatedTextByKey(
			// 	'eCommerce.errorPages.sessionExpiredChatMessage',
			// 	SESSION_EXPIRED_MESSAGE
			// )}`
			path = `https://wa.me/${targetChat}`
			break

		case CONSTANTS.APP.TYPES.TELEGRAM.toString():
			path = `https://telegram.me/${targetChat}`
			break

		case CONSTANTS.APP.TYPES.SMS.toString():
			path = `sms:${targetChat}`
			break

		default:
			// Instead of closing the window, let’s send empty string as a default.
			// The function that using this function will choose which action to use on this scenario
			path = ''
	}
	return path
}

export const eCommerceByAppID = (appId) =>
	appId !== CONSTANTS.APP.TYPES.MESSENGER &&
	appId !== CONSTANTS.APP.TYPES.WHATSAPP &&
	appId !== CONSTANTS.APP.TYPES.TELEGRAM &&
	appId !== CONSTANTS.APP.TYPES.SMS

/**
 * Inject a 3rd party script either by url OR by adding Javascript directly into the <script> tag
 *
 * @param scriptAttribute - 'src' needs a url, 'text' needs javascript
 * @param value - url or actual javascript
 */
export const injectExternalScript = (scriptAttribute, value) => {
	const head = document.querySelector('head')
	const script = document.createElement('script')

	switch (scriptAttribute) {
		case 'src':
			script.setAttribute('src', value)
			break
		case 'text':
			script.text = value
			break
		default:
			console.error(`unknown scriptAttribute: '${scriptAttribute}'`)
	}

	head.appendChild(script)
}

export const removeHashFromUrl = () => {
	const newUrl = `${window.location.pathname}${window.location.search}`
	window.history.replaceState({ ...window.history.state, as: newUrl, url: newUrl }, '', newUrl)
}

export const getNewRefObject = (newRefObject: object = {}): object => {
	const refObjectToUpdateWithoutEmptyValues = omitBy(newRefObject, (val) => !val)

	// ref from Infra
	const refObjectFromInfra = getRefObjectFromString((Infra.appParams as any).ref)

	// ref from Query String
	const refObjectFromQueryString = getRefObjectFromString(queryString.parse(window.location.search).ref as string)

	return { ...refObjectFromInfra, ...refObjectFromQueryString, ...refObjectToUpdateWithoutEmptyValues }
}

// Current behavior is that ref can be both: 1. a string value or 2. a string representing JSON object
export const getRefObjectFromString = (refString = ''): object => {
	if (!refString) {
		return {}
	}

	const refFromQueryStringDecoded = getDecodedString(refString)
	return isJsonString(refFromQueryStringDecoded) ? getJsonFromString(refFromQueryStringDecoded) : { ref: refFromQueryStringDecoded }
}

// Current behavior is that ref can be both: 1. a string value or 2. a string representing JSON object
export const getUrlUpdatedWithNewRef = (refStringEncoded: string): string => {
	const parsed = queryString.parse(window.location.search)
	const [baseUrl] = window.location.href.split('?')
	return `${baseUrl}?${queryString.stringify({
		...parsed,
		ref: refStringEncoded,
	})}`
}

export const getHotfixRedirectUrlForNextJS = (context: NextPageContext) => {
	const { host } = context.req.headers
	const { url } = context.req
	const { resolvedUrl } = context

	// This line is added specifically because of KFC DomRep for a Hotfix
	// The server redirected to this strange url, causing the 404 page to be
	// displayed as the landing page
	if (url.includes('%C2%A0[R=301,L]')) {
		return '/'
	}

	// Hot fix for pizzahut DE
	if (host.includes('pizzahut.de') && (url.includes('/coupons') || host.includes('/coupons'))) {
		return 'https://pizzahut.de/de/coupons.html'
	}
	return null
}

export const getHotfixRedirectUrlForReact = (fullUrl: string) => {
	const urlObject = new URL(fullUrl)
	const { origin } = urlObject

	if (fullUrl.includes('%C2%A0[R=301,L]')) {
		return origin
	}

	if (origin.includes('pizzahut.de') && fullUrl.includes('/coupons')) {
		return `https://pizzahut.de/de/coupons.html`
	}

	return null
}

export const getItemIdList4StaticPages = (menuData: Menu | undefined) => {
	if (!menuData) {
		return []
	}
	// new rules add here
	const visibleItems = [...new Set(menuData.sections?.flatMap((section) => section.itemIds))]
	return visibleItems
}

export const formatUrlForSitemap = (url: string) => {
	const encodedUrl = encodeURI(url)

	return encodedUrl.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/'/g, '&apos;').replace(/"/g, '&quot;')
}

export const pageIsHiddenFromSearchEngines = (pageName: Page | string, appParams: AppParams): boolean =>
	!!appParams?.pageText?.[pageName]?.hiddenFromSearchEngines

interface InitSessionAndLoadMenuProps {
	isGeneratedStatically: boolean
	storeId: string
	router: NextRouter
	setStore: (store: Store) => void
	Home: typeof _Home
	cartStore: CartStore
	urlHashValue?: string
	numberedOrderType?: number
	shouldSetUserAddress?: boolean
	didOrderTypeChange?: boolean
}
// TODO - this method MUST be refactored and broken up and tests added. It was in 3 places before being moved here
export const initSessionAndLoadMenu =
	(loadMenu) =>
	async ({
		isGeneratedStatically,
		storeId,
		router,
		setStore,
		Home,
		cartStore,
		urlHashValue,
		numberedOrderType = User.getOrderType(),
		shouldSetUserAddress = false,
		didOrderTypeChange = true,
	}: InitSessionAndLoadMenuProps) => {
		try {
			// todo: need to check here if the user localized already or not and use the localized order type, otherwise - from User.orderType
			const orderType = numberedOrderType === CONSTANTS.DELIVERY_METHODS.DELIVERY ? ORDER_TYPES.DELIVERY : ORDER_TYPES.PEAKUP
			const oldSessionId = User?.session?._id || ''

			const { wru = getDomainByEnv(), c: chainId } = Infra.appParams
			// we must call this API even if the request session id hasn't changed because the order-type may have changed and we need to update the server
			let newMenuPath = await Session.initOrUpdateSession({
				refObject: { orderType },
				storeId,
				stopLoading: false,
			})

			if (urlHashValue) {
				newMenuPath += urlHashValue
			}
			const newMenuQueryParams = queryString.parse((newMenuPath ?? '').split('?')[1])

			// When a redirect occurs from init new session above, we get en empty newMenuPath
			// Therefore, the execution of the function can be stopped here
			if (!newMenuPath) {
				console.warn('No new menu path received')
				return
			}

			setRequestInCookie(newMenuQueryParams.request)

			/* the code below is a part-copy from app.js for when the apps mounts with the menu/checkout page. It's hard
                 to move it to a separate method since it requires component methods too eg setStore and history */
			const serverSession = await checkRequest(
				{
					wru,
					request: newMenuQueryParams.request,
					cust: newMenuQueryParams.cust,
					j: newMenuQueryParams.j,
					tictuk_listener: newMenuQueryParams.tictuk_listener,
				},
				false
			)

			// if giftId is available it means that it was shared from someone else and the
			// user who got it should see it already in the cart when opening the menu
			if (serverSession?.giftId) {
				if (!cartStore.items[serverSession?.giftId]) {
					localStorage.setItem('giftId', serverSession?.giftId)
				}
			}

			await User.setSession(serverSession)

			storeId = process.env.NODE_ENV === 'production' ? storeId : `${storeId}`

			const menu = await loadMenu({
				chainId,
				storeId,
				orderTypeFromQS: newMenuQueryParams.ot,
				appId: User.session.appid,
				stopLoading: false,
			})

			if (menu.sections?.length === 0) {
				const alternateType = orderType === ORDER_TYPES.DELIVERY ? ORDER_TYPES.PICKUP : ORDER_TYPES.DELIVERY

				Infra.setLoading(false)
				Infra.setErrorNotification(`We couldn't find any items for '${orderType}'. You may order '${alternateType}'`)
				return
			}

			const storeMetaData = await getStore({
				wru,
				request: newMenuQueryParams.request,
				cust: newMenuQueryParams.cust,
				tictuk_listener: newMenuQueryParams.tictuk_listener,
			})

			datadogRum.setUser({
				name: storeMetaData?.orderId,
				id: serverSession.uuid,
			})

			if (isGeneratedStatically) {
				window.location.replace(`${window.location.origin}${newMenuPath}`)
			} else if (storeMetaData) {
				setStore((store) => ({ ...store, data: menu, metaData: storeMetaData }))

				if (shouldSetUserAddress) {
					const formattedAddress = { ...storeMetaData.address, formatted_address: storeMetaData.address.formatted }
					AddressManager.setFullAddress(formattedAddress, orderType, false, true)
					AddressManager.setMatchingStoreIdToAddressByOrderType(storeId, orderType)
				}

				// reset the Home clicked featured item so that if the user presses back from the menu page, the same featured item is not clicked
				Home.setBackdropEnabled(false)
				Home.setClickedFeaturedItem(null)
				sessionStorage.clear()

				const [, query] = newMenuPath.split('?')
				const tenantId = getTenantInfo()
				if (!query.includes(`showMenu_${tenantId}`) && !query.includes(`j=${tenantId}`)) {
					AddressManager.storeMenuUrlToLS(`?${query}`)
				}
				// a) now load the /menu page
				await router.push(newMenuPath)

				MobileApplication.setMenuPath(newMenuPath)

				if (cartStore.ready && (didOrderTypeChange || oldSessionId !== User.session._id)) {
					cartStore.setReady(false)
					const { cartModified } = await cartStore.dryCartRelocation(orderType, oldSessionId)
					await cartStore.relocateCart(orderType, oldSessionId)

					if (cartModified) {
						const title = getTranslatedTextByKey('webviewFlow.cartUpdate')
						const message = getTranslatedTextByKey('webviewFlow.storeDoesntHaveAllItems')
						Infra.setNotification({
							title: `${title}`,
							message,
							okAction: () => {
								Infra.closeNotification()
							},
						})

						sendCustomEvent({
							category: 'error',
							action: 'notification',
							label: title,
							message,
						})
					}
				}
			}
		} catch (error) {
			console.log(error)
		}
	}

export const safeStringify = (obj: unknown): string => {
	try {
		if (obj instanceof AxiosError) {
			return JSON.stringify({
				name: obj.name,
				message: obj.message,
				stack: obj.stack,
				config: obj.config,
				code: obj.code,
				response: obj.response
					? {
							status: obj.response.status,
							statusText: obj.response.statusText,
							headers: obj.response.headers,
							data: obj.response.data,
					  }
					: undefined,
				request: obj.request ? String(obj.request) : undefined,
			})
		}

		if (obj instanceof Error) {
			return JSON.stringify({
				name: obj.name,
				message: obj.message,
				stack: obj.stack,
				...obj,
			})
		}

		return JSON.stringify(obj)
	} catch {
		return String(obj)
	}
}

export const sendDDLog = async (log: { level: string; message: string; data: any }) =>
	sendRequest(false, `${Infra.appParams.wruec}/v1/log`, 'post', log)
