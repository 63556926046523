export const CONSTANTS = {
	DELIVERY_METHODS: {
		DELIVERY: 0,
		PICKUP: 1,
	},
	// Note: from query params we get those values appended with -class
	ORDER_TYPE: {
		PEAKUP: 'peakup',
		TAKEAWAY: 'takeAway',
		DINE_IN: 'dineIn',
		DRIVE_THRU: 'driveThru',
		PICKUP: 'pickup',
		DELIVERY: 'delivery',
		CURB_SIDE: 'curbside',
		TABLE_SIDE: 'tableside',
	},
	CHANNEL_TYPE: {
		WHATSAPP: 'WhatsApp',
		MESSENGER: 'Messenger',
		TELEGRAM: 'Telegram',
		WEB: 'Web',
		APP: 'App',
		KIOSK: 'Kiosk',
		ALL: 'all',
	},
	calcGTKey: '~calcgt~',
	redeemDiscountKey: '~redeemdiscount~',
	removeDiscountKey: '~removediscount~',
	redeemCouponKey: '~redeemcoupon~',
	APP: {
		TYPES: {
			// NOT-E-COMMERCE
			WHATSAPP: 1,
			MESSENGER: 8,
			TELEGRAM: 9,
			SMS: 12,
			// E-COMMERCE
			WEB: 10,
			WEB_MOBILE: 11,
			IOS_APP: 13,
			ANDROID_APP: 14,
			KIOSK: 15,
		},
	},
	DEFAULT_MAP_ZOOM: 13,
	FOOTER: {
		ITEM_TYPES: {
			INTERNAL: 'internal',
			DOWNLOAD: 'download',
			EXTERNAL: 'external',
			TOOL_GENERATED: 'toolGenerated',
		},
	},
	USER: {
		PREFERRED_LANGUAGE: 'preferredLanguage',
	},
	PAYMENTS: {
		REQUEST_TIMEOUT_IN_MS: 180000,
	},
	CHECKOUT_STEPS: {
		GOTO_CHECKOUT_PAGE: 1,
		DELIVERY_FORM_VALID: 2,
		OPENED_MAP_PAGE: 3,
		ADDED_ORDER_COMMENTS: 4,
		SELECTED_PAYMENT_METHOD: 5,
		ALLOW_MARKETING_EMAILS: 6,
		AGREE_TERMS_AND_CONDITIONS: 7,
	},
	COUPONS: {
		YOU_ALREADY_ADDED_THIS_COUPON: 'youAlreadyAddedThisCoupon',
		COULD_NOT_FIND_COUPON: 'couldntFindCoupon',
		DISCOUNT_APPLIED: 'discountApplied',
	},
	DEALS_TYPES: {
		COUPON: 'coupon',
		ITEM: 'item',
		BANNER: 'banner',
		OFFER: 'offer',
	},
	DEAL_TARGETS: {
		LP: 'lp',
		COUPON: 'coupon',
		ITEM: 'item',
		MENU_SECTION: 'menu-section',
		COUPON_WALLET: 'coupon-wallet',
		EXTERNAL_LINK: 'external-link',
	},
	URL_QUERY_HASH_KEY: {
		/**
		 * Hash key used in URL query string to handle item coupon
		 */
		ITEM: '#item',
		/**
		 * Hash key used in URL query string to handle discount code
		 */
		DISCOUNT: '#cc',
		/**
		 * Hash key used in URL query string to handle item coupon from static pages
		 */
		STATIC: '#static',
		/**
		 *  Hash key used in URL query string to handle menu item pop-up
		 */
		MENU_ITEM: '#menuItem',
	},
	MOBILE_APP: {
		PERMISSIONS: {
			LOCATION: {
				NOT_ASKED: 0,
				GRANTED: 1,
				DENIED: 2,
			},
		},
		VERSION_LOCAL_STORAGE_KEY: 'mobileApplicationVersion',
	},
	LOCALES: ['en_US', 'he_IL', 'ar_IL', 'ru_RU', 'pt_PT', 'es_ES', 'fr_FR', 'pap_AW', 'de_DE'],
	REGIONS: ['US', 'IL', 'RU', 'PT', 'ES', 'FR', 'AW', 'DE', 'FI', 'MX'],
	ANALYTICS: {
		ENHANCED_ECOMMERCE_EVENTS: {
			addMenuItemImpression: 'addMenuItemImpression',
			sendProductImpressionEvent: 'sendProductImpressionEvent',
			sendProductClickEvent: 'sendProductClickEvent',
			sendViewProdutDetailEvent: 'sendViewProdutDetailEvent',
			sendAddToCartEvent: 'sendAddToCartEvent',
			sendRemoveFromCartEvent: 'sendRemoveFromCartEvent',
			sendViewCartEvent: 'sendViewCartEvent', // only in GA4
			sendCheckOutEvent: 'sendCheckOutEvent',
			sendPurchaseEvent: 'sendPurchaseEvent',
			sendSelectPromotionEvent: 'sendSelectPromotionEvent',
			sendViewPromotionEvent: 'sendViewPromotionEvent',
		},
		OTHER_EVENTS: {
			custom: 'custom',
			pageView: 'pageView',
		},
	},
	LOCAL_STORAGE_ADDRESS_NAME: 'localizedAddress',
	COOKIE_CONSENT_INTERVAL_CHECK_MS: 1000,
	MENU_URL: 'menuUrl',
	STORE_ID: 'storeId',
} as const

export const REQUEST_PARAM = 'requestParam'
export const SESSION_EXPIRED = 'sessionExpired'
export const REQUEST_COOKIE_EXPIRY_IN_HOURS = 2
export const EXTERNAL_ADDRESSBOX_ID = 'static-address-box'

export const ACCOUNT_TAB = 'accountTab'
export const AUTHENTICATOR = 'authenticator'

export const HTTP_METHODS = {
	GET: 'GET',
	POST: 'POST',
	PUT: 'PUT',
	DELETE: 'DELETE',
}

export const ACTIONS_KEY = {
	ENTER: 'Enter',
}

export const HTTP_CODES = {
	OK: 200,
	NOT_FOUND: 404,
	BAD_REQUEST: 400,
	METHOD_FAILURE: 420,
	SESSION_EXPIRED: 440,
	INTERNAL_SERVER: 500,
}

export enum Page {
	HOME = 'home',
	MENU = 'menu',
	LEGAL = 'legal',
	STATIC_MENU = 'static-menu',
	MENU_ITEM = 'menu-item',
	MENU_SECTION = 'menuSection',
	DEALS = 'deals',
	CHECKOUT = 'checkout',
	VERSION = 'version',
	ORDER_SUMMARY = 'summary',
	ORDER_CONFIRMATION = 'order-confirmation',
	CONTACT_US = 'contact-us',
	STORE_LOCATOR = 'store-locator',
	SELECTED_STORE = 'selected-store',
	ACCOUNT = 'account',
	COUPONS = 'coupons',
	HEADER_FOOTER = 'header-footer',
}

// alert legacy, we should get rid of this one
export const ORDER_TYPES = {
	DELIVERY: 'delivery',
	PEAKUP: 'peakup',
	PICKUP: 'pickup',
	STORE: 'store',
} as const

export const ORDER_TYPES_QUERY_PARAMS = {
	DELIVERY_CLASS: 'delivery-class',
	PEAK_UP_CLASS: 'peakup-class',
}

export const ADDRESS_ATTRIBUTES = ['address_components', 'name', 'formatted_address', 'geometry']
export const GOOGLE_MAPS_BASE_URL = `https://maps.googleapis.com/maps/api/js`
export const GOOGLE_MAPS_AUTOCOMPLETE_OPTIONS = ['geocode', 'establishment']
export const GOOGLE_API_KEY = 'AIzaSyBnea1oOS9Wif_l-zV6WfxjA4sZs5kpt-k'

export const STORE_STATUS = {
	UNAVAILABLE: 0, // not available for online order (does not neccesarily mean physically closed!)
	PRE_ORDER: 1,
	AVAILABLE: 100, // available for online order
}

export const LOCALIZATION_TYPE = {
	NONE: 0,
	FROM_SELECT: 1,
	FROM_MY_LOCATION: 2,
}

/**
 * This is used in eg filtering discounts by channel. A 'channel' can map to more than 1 app-id.
 */
export const APPID_TO_CHANNEL_MAP = {
	[CONSTANTS.APP.TYPES.WHATSAPP]: CONSTANTS.CHANNEL_TYPE.WHATSAPP,
	[CONSTANTS.APP.TYPES.MESSENGER]: CONSTANTS.CHANNEL_TYPE.MESSENGER,
	[CONSTANTS.APP.TYPES.TELEGRAM]: CONSTANTS.CHANNEL_TYPE.TELEGRAM,
	[CONSTANTS.APP.TYPES.WEB]: CONSTANTS.CHANNEL_TYPE.WEB,
	[CONSTANTS.APP.TYPES.WEB_MOBILE]: CONSTANTS.CHANNEL_TYPE.WEB,
	[CONSTANTS.APP.TYPES.IOS_APP]: CONSTANTS.CHANNEL_TYPE.APP,
	[CONSTANTS.APP.TYPES.ANDROID_APP]: CONSTANTS.CHANNEL_TYPE.APP,
	[CONSTANTS.APP.TYPES.KIOSK]: CONSTANTS.CHANNEL_TYPE.KIOSK,
}

export const ITEM_CODE_ORDER_TYPE_SEPARATOR = '§§'

export const HISTORY_WINDOW_STATE_ID = 'login'

export enum BackendChannel {
	DesktopWeb = 'WEB',
	MobileWeb = 'MOBILE_WEB',
	IosApp = 'IOS',
	AndroidApp = 'ANDROID',
}
