import { ECOMMERCE_USER, GUEST_USER } from '../constants/signUpProcess'
import { CONSTANTS } from './constants'
import { ORDERS_IDS_CONFIRMATION_SENT } from 'utils/orderConfirmationUtils'

export const storage = {
	clearStorage: () => {
		const keysToDelete = []
		const keysToExclude = [
			ECOMMERCE_USER,
			GUEST_USER,
			'logo',
			CONSTANTS.LOCAL_STORAGE_ADDRESS_NAME,
			ORDERS_IDS_CONFIRMATION_SENT,
			CONSTANTS.MENU_URL,
			CONSTANTS.STORE_ID,
			'pushNotificationsDeclineDate',
			'pushNotificationsStatus',
			'pushNotificationsToken',
			'forceMobileApp',
			'deviceUniqueId',
			'userUUID',
			'currentDeliveryType',
		]
		for (let i = 0; i < localStorage.length; i++) {
			const _key = localStorage.key(i)
			// remove every key EXCEPT those specified in keysToExclude array
			if (_key && !keysToExclude.includes(_key)) {
				keysToDelete.push(_key)
			}
		}

		keysToDelete.forEach((_key) => {
			localStorage.removeItem(_key)
		})
	},
}

export default storage
