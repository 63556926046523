// @ts-nocheck
import { useEffect, useRef } from 'react'
import { CONSTANTS } from 'utils/constants'

// This hook should be dependent on MobileApplication mobx userLocationPermissionStatus param, that every call for user location we change the status of
export const useLocationMobileApp = (onSuccess, onError, userLocationPermissionStatus, userLocation) => {
	const isFirstRender = useRef(true)

	useEffect(() => {
		const handleUserLocationPermissionResponse = async () => {
			if (userLocationPermissionStatus === CONSTANTS.MOBILE_APP.PERMISSIONS.LOCATION.DENIED) {
				onError()
			} else if (userLocationPermissionStatus === CONSTANTS.MOBILE_APP.PERMISSIONS.LOCATION.GRANTED) {
				onSuccess({ lat: userLocation.latitude, long: userLocation.longitude })
			}
		}

		if (isFirstRender.current && userLocationPermissionStatus === CONSTANTS.MOBILE_APP.PERMISSIONS.LOCATION.DENIED) {
			isFirstRender.current = false
			return
		}

		if (userLocationPermissionStatus !== CONSTANTS.MOBILE_APP.PERMISSIONS.LOCATION.NOT_ASKED) {
			handleUserLocationPermissionResponse()
		}

		isFirstRender.current = false
	}, [userLocationPermissionStatus])
}
