/* eslint-disable react/prop-types */
import type { ChangeEvent } from 'react'
import React, { forwardRef, useRef } from 'react'
import styled from 'styled-components'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import CheckIcon from '@material-ui/icons/Check'
import TextFieldBase, { type TextFieldProps } from '@material-ui/core/TextField'
import FormHelperText from '@material-ui/core/FormHelperText'
import CircularProgress from '@material-ui/core/CircularProgress'

interface CssProps {
	$isErrorBackground?: boolean
}

interface CustomProps extends CssProps {
	errorMessage?: string
	$errQaData?: string
	endAdornmentUseOnTouch?: boolean
	loading?: boolean
	success?: boolean
	noResetButton?: boolean
	readonly?: boolean
	field?: TextFieldProps
	startAdornment?: string | JSX.Element
	endAdornment?: string | JSX.Element
	onResetValue?: (value: string) => void
	setValueFunction?: (value: string) => void
	iconTestId?: string
}

type Props = CustomProps & TextFieldProps

/**
 * you can send here list of props (all list on the bottom), and you can get more from material-ui documentation
 * every case like error, disabled, readOnly, required, etc... => has a boolean prop you can send and get what you need easily
 * clear button already included here so you dont need to send it each time, just send the setState function with onResetValues parameter set it empty on Clicked
 * input variant now can replace by prop "variant" (default is "outlined")
 * styling taken from: https://www.figma.com/file/q1PwkMEO8kp6yv00o67R0I/Taco-Bell-1.16?node-id=4039%3A224745
 */

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`

const TextFieldStyle = styled(TextFieldBase)<CssProps>`
	label {
		color: var(--inactive);
	}

	${({ theme }) => theme.typography.BodySmall};

	.MuiInputBase-root {
		height: ${(props) => props.variant !== 'standard' && !props.multiline && '35px'};
		border-radius: var(--borderRadiusInput);
		padding: ${(props) => (props.variant !== 'standard' && !props.multiline ? '11px' : props.multiline && '9px 11px')};
		max-height: ${(props) => !props.multiline && '60px'};
		margin-top: ${(props) => props.variant === 'standard' && '7px'};
		color: ${(props) => props.variant === 'standard' && 'var( --footerAndDarkBackgrounds)'};
		background-color: var(--clear);
		${({ theme }) => theme.typography.BodyRegular};
		box-shadow: ${(props) => props.$isErrorBackground && 'var(--defaultShadow1)'};
		@media (min-width: 1200px) {
			height: ${(props) => props.variant !== 'standard' && !props.multiline && '40px'};
			padding: ${(props) =>
				props.variant !== 'standard' && !props.multiline ? '14px 10px 14px 15px' : props.multiline && '12px 10px 11px 15px'};

			${({ theme }) => theme.typography.BodySmall};
		}
		&.MuiInput-underline {
			${({ theme }) => theme.typography.BodySmall};
			&:not(.Mui-error) {
				&:before,
				&:after {
					border-bottom: 1px solid var(--footerAndDarkBackgrounds);
				}
				&.Mui-focused {
					&:before,
					&:after {
						border-bottom: 2px solid var(--footerAndDarkBackgrounds);
					}
				}
			}
		}
		&::placeholder {
			color: var(--disable);
		}
		& fieldset {
			color: var(--fonts);
		}
		&:hover {
			& fieldset {
				border-color: var(--inactive);
			}
		}
		&.Mui-error fieldset {
			border-color: var(--err);
		}
		&.Mui-focused fieldset {
			border-color: var(--fonts);
			border-width: 1px;
		}
		,
		&.Mui-error,
		&.Mui-focused,
		&.Mui-focused.Mui-error {
			color: var(--fonts);
		}
		&.Mui-disabled fieldset {
			border-color: var(--disable);
			background: var(--optionalBackground);
			opacity: 0.5;
			color: var(--fonts);
		}

		&.MuiIconButton-root,
		&.MuiSvgIcon-root {
			padding: ${(props) => (props.variant === 'standard' ? 0 : '10px')};
		}
	}
`

const ErrorMessage = styled(FormHelperText)<CssProps>`
	display: flex;
	align-items: center;
	${({ theme }) => theme.typography.BodySmall};
	line-height: 1;
	margin-block-start: 5px;
	background-color: ${({ $isErrorBackground }) => $isErrorBackground && 'var(--errorBg)'};
	border-radius: ${({ $isErrorBackground }) => $isErrorBackground && 'var(--borderRadiusInput)'};
	padding: ${({ $isErrorBackground }) => $isErrorBackground && '5px 10px'};
	box-shadow: ${({ $isErrorBackground }) => $isErrorBackground && 'var(--defaultShadow1)'};

	.MuiSvgIcon-root {
		width: 16px;
		margin-inline-end: 5px;
	}
`

const Label = styled.label`
	display: flex;
	align-items: center;
	color: var(--fonts);
	line-height: normal;
	${({ theme }) => theme.typography.BodyRegularHighlighted};
	margin-bottom: 5px;
`

const TextField = forwardRef<HTMLInputElement, Props>(
	(
		{
			type = 'text',
			variant = 'outlined',
			placeholder,
			className,
			label,
			inputProps,
			endAdornment,
			startAdornment,
			errorMessage,
			value,
			$errQaData,
			field,
			onResetValue,
			setValueFunction, // If passed - it is used to set the value of the input on reset button (instead of doing it "manually" using DOM query selection)
			onChange,
			$isErrorBackground = false,
			endAdornmentUseOnTouch = false,
			disabled = false,
			readonly = false,
			required = false,
			error = false,
			loading = false,
			success = false,
			noResetButton = false,
			iconTestId = 'checkmark-icon',
			...rest
		},
		ref
	) => {
		if (loading) {
			readonly = true
		}

		const props: TextFieldProps & CssProps = {
			type,
			label: variant === 'standard' && label,
			variant,
			placeholder: variant !== 'standard' ? placeholder : '',
			inputProps,
			value,
			onChange,
			$isErrorBackground,
			inputRef: ref,
			disabled,
			error,
			...rest,
		}

		const onEndAdornmentClick = (
			event:
				| React.TouchEvent<HTMLButtonElement>
				| React.TouchEvent<HTMLAnchorElement>
				| React.MouseEvent<HTMLButtonElement>
				| React.MouseEvent<HTMLAnchorElement>
		) => {
			type Target = EventTarget & HTMLButtonElement
			;(event.target as Target).value = ''
			onChange?.(event as unknown as ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)

			// If you use setValueFunction, and want to focus the input after it --- do it in that function
			if (setValueFunction) {
				setValueFunction('')
			} else {
				const input: HTMLInputElement | null | undefined = (event.target as Target)
					?.closest('.MuiInputBase-root')
					?.querySelector('input.MuiInputBase-input')
				if (input) {
					input.value = ''
					input.focus()
				}
			}
			if (onResetValue) {
				onResetValue('')
			}
		}

		// It is important to keep tabIndex="-1" on the reset button to have it unfocusable - otherwise it may
		// cause problems when using the tab button to switch between inputs (and triggering blur events)
		const restInputProps = {
			startAdornment,
			endAdornment: (
				<>
					{(loading && (
						<CircularProgress data-testid={`input-loader-${rest.name}`} size={14} disableShrink style={{ color: 'var(--inactive' }} />
					)) ||
						(!noResetButton && value && !readonly && !disabled && !loading && !success && (
							<IconButton
								data-testid="clear-field-value"
								tabIndex="-1"
								className="textfield-reset-button"
								style={{ padding: 0 }}
								onMouseDown={onEndAdornmentClick}
								onTouchStart={(event) => {
									if (!endAdornmentUseOnTouch) {
										return
									}

									onEndAdornmentClick(event)
								}}
							>
								<CloseIcon />
							</IconButton>
						)) ||
						(success && <CheckIcon style={{ color: 'var(--approved)' }} color="primary" data-testid={iconTestId} />)}
					{endAdornment}
				</>
			),
		}

		return (
			<Wrapper>
				{label && variant !== 'standard' && <Label data-testid={`field-label-${rest.name}`}>{required ? `${label} *` : label}</Label>}
				<TextFieldStyle
					disabled={disabled}
					{...props}
					InputProps={{
						...restInputProps,
					}}
					{...field}
					className={className}
				/>
				{error && errorMessage && (
					<ErrorMessage error $isErrorBackground={$isErrorBackground}>
						<InfoOutlinedIcon />
						<span data-testid="input-error-message">{errorMessage}</span>
					</ErrorMessage>
				)}
			</Wrapper>
		)
	}
)

export default TextField
