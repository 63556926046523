import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core'
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import IconComponent from 'themes/IconComponent'
import TypographyPro from 'themes/TypographyPro'

export enum AvailabilityState {
	Available = 'available',
	Partial = 'partial',
	Unavailable = 'unavailable',
}

interface Props {
	icon: JSX.Element
	title: string
	state: AvailabilityState
}

const AccordionStyled = styled(Accordion)`
	&:not(:first-child)::before {
		background-color: var(--optionalBackground);
		opacity: 1;
	}

	&.Mui-expanded {
		margin: 0;
	}

	&.Mui-expanded::after {
		background-color: var(--optionalBackground);
		content: '';
		display: block;
		width: 100%;
		height: 1px;
	}
`

const Summary = styled(AccordionSummary).attrs({
	'aria-label': 'Expand',
	'aria-controls': 'more-availability-details',
})`
	&.Mui-expanded {
		min-height: unset;
		height: 3rem;
	}

	& .MuiAccordionSummary-content.Mui-expanded {
		margin-bottom: 0;
	}
`

const Details = styled(AccordionDetails)`
	display: flex;
	flex-direction: column;
	gap: 0.25rem;
`

const SummaryContent = styled.div`
	display: flex;
	gap: 0.25rem;
	align-items: center;
`

const getColorByState = (state: AvailabilityState): string => {
	switch (state) {
		case AvailabilityState.Available:
			return 'var(--strokeMenuTitle)'
		case AvailabilityState.Partial:
			return 'var(--warningText)'
		case AvailabilityState.Unavailable:
			return 'var(--err)'
		default:
			throw new Error(`Invalid availability state: ${state}`)
	}
}

const getIconByState = (state: AvailabilityState, icon: JSX.Element): JSX.Element => {
	switch (state) {
		case AvailabilityState.Available:
			return icon
		case AvailabilityState.Partial:
			return <IconComponent asset="/icons/partial_availability.svg" />
		case AvailabilityState.Unavailable:
			return <IconComponent asset="/icons/unavailable.svg" />
		default:
			throw new Error(`Invalid availability state: ${state}`)
	}
}

const CouponAvailability: React.FC<React.PropsWithChildren<Props>> = ({ icon, title, state, children }) => {
	const [expanded, setExpanded] = useState(false)

	useEffect(() => {
		if (state === AvailabilityState.Unavailable) {
			setExpanded(true)
		}
	}, [state])

	return (
		<AccordionStyled elevation={0} expanded={expanded}>
			<Summary data-testid="availability-section-header"
				style={{ color: getColorByState(state) }}
				expandIcon={<ExpandMoreIcon style={{ color: getColorByState(state) }} />}
				onClick={() => {
					setExpanded((prev) => !prev)
				}}
			>
				<SummaryContent>
					{getIconByState(state, icon)}
					<TypographyPro variant="BodyRegular">{title}</TypographyPro>
				</SummaryContent>
			</Summary>
			<Details data-testid="availability-section-content">{children}</Details>
		</AccordionStyled>
	)
}

export default CouponAvailability
