import axios from 'axios'
import type { NextRouter } from 'next/router'

import { sendRequest, getECommerceDomainByEnv, getLocaleStr, normalizeFileNameStr, routeToPage, sendDDLog } from 'utils/utils'
import { codeToLocale } from 'utils/language'
import { buildSectionMenuUrl } from 'utils/menu/menuUtils'
import { storeUrls } from 'utils/api/storefrontUrls'

import type { LanguageLocale, LanguageCode } from 'utils/language'
import type _Infra from 'mobx/Infra'
import type _User from 'mobx/User'
import type _Home from 'mobx/Home'
import type _AddressManager from 'mobx/AddressManager'
import type _Session from 'mobx/Session'
import { Page } from 'ttversion/ecommerce-website/src/utils/constants'
import type CartStore from 'mobx/Cart/store'
import type { SetStore } from 'contexts/StoreContext'
import type AddressManagerV2Store from 'mobx/AddressManagerV2/store'

export interface DeepLinkProps {
	Infra: typeof _Infra
	User: typeof _User
	Home: typeof _Home
	Session: typeof _Session
	AddressManager: typeof _AddressManager
	deepLinkId: string
	iosApplicationStoreURL: string
	androidApplicationStoreURL: string
}

interface DeepLinkDependencies {
	Infra: typeof _Infra
	User: typeof _User
	Home: typeof _Home
	setStore: SetStore
	cartStore: CartStore
	addressManagerV2Store: AddressManagerV2Store
}

/**
 * TODO-SERGEY:
 * Duplicate of an enum that is located in ecommerse-server/src/types/DeepLink.type.tx
 * Will be resolved after a proper migration to the nx monorepo
 */

enum DestinationType {
	home = 'home',
	couponWallet = 'couponWallet',
	menuSection = 'menuSection',
	deals = 'deals',
	contactUs = 'contactUs',
	staticMenu = 'staticMenu',
	storeLocator = 'storeLocator',
	account = 'account',
	customPage = 'customPage',
}

/**
 * TODO-SERGEY:
 * Duplicate of an interface that is located in ecommerse-server/src/types/DeepLink.tx
 * Will be resolved after a proper migration to the nx monorepo
 */
interface Destination {
	type: DestinationType
	storeId?: string
	sectionName?: string
	sectionId?: string
	path?: string
}
interface DeepLinkData {
	slug: string
	destination: Destination
	attachment: {
		type: string
		couponCode: string
		itemCoupon: string
	}
	storeId: string
	utm?: {
		id?: string
		source: string
		medium: string
		campaign?: string
		term?: string
		content?: string
	}
	webOnly?: boolean
}

type UtmKeys = keyof DeepLinkData['utm']

const REDIRECTION_TO_APPLICATION_STORE_TIMEOUT = 1500

const getRedirectionPath = (destinationType: DestinationType | undefined, lang: string, path?: string) => {
	switch (destinationType) {
		case DestinationType.contactUs:
			return `/${lang}/contact-us`

		case DestinationType.staticMenu:
			return `/${lang}/menu`

		case DestinationType.storeLocator:
			return `/${lang}/store-locator`

		case DestinationType.couponWallet:
			return `/${lang}/coupons`

		case DestinationType.deals:
			return `/${lang}/deals`

		case DestinationType.home:
			return `/home`

		case DestinationType.account:
			return `/account`

		case DestinationType.customPage:
			return `/${lang}/${path}`

		default:
			console.error('Invalid destination type')
			return `/home`
	}
}

const generateUtmQueryParameters = (utm: DeepLinkData['utm']): URLSearchParams => {
	if (!utm) {
		return new URLSearchParams()
	}

	const utmQueryParameters = new URLSearchParams()
	Object.keys(utm).forEach((key) => {
		if (utm[key as UtmKeys]) {
			utmQueryParameters.set(`utm_${key}`, utm[key as UtmKeys])
		} else {
			console.error(`Invalid UTM key: ${key}`)
		}
	})

	return utmQueryParameters
}

const getNormalizedStoreName = async (storeTitle: Partial<Record<LanguageLocale, string>>, language: LanguageCode) => {
	const langCode = codeToLocale[language as LanguageCode] || 'en_US'
	const storeNameTranslated = getLocaleStr(storeTitle, langCode)

	return normalizeFileNameStr(storeNameTranslated)
}

export const openMobileAppWithAppSchemeOrRedirectToStore = ({
	bundleId,
	deepLink,
	applicationStoreURL,
}: {
	bundleId: string | undefined
	deepLink: string
	applicationStoreURL: string
}) => {
	const start = new Date().getTime()
	const deepLinkElement = document.createElement('a')

	// Navigate to the application if installed
	try {
		deepLinkElement.href = `${bundleId}:/${deepLink}`
		deepLinkElement.style.display = 'none'
		document.body.appendChild(deepLinkElement)
		deepLinkElement.click()
	} catch (error) {
		document.body.removeChild(deepLinkElement)
		window.location.replace(applicationStoreURL)

		return
	}

	setTimeout(() => {
		const end = new Date().getTime()

		if (end - start < REDIRECTION_TO_APPLICATION_STORE_TIMEOUT + 500) {
			window.location.replace(applicationStoreURL)
		}

		document.body.removeChild(deepLinkElement)
	}, REDIRECTION_TO_APPLICATION_STORE_TIMEOUT)
}

const mergeRefParams = (url: string) => {
	try {
		const urlObj = new URL(url, 'https://example.com')
		const params = new URLSearchParams(urlObj.search)

		const refValues = params.getAll('ref')
		if (refValues.length <= 1) {
			return url
		}

		const refObjects = {}

		refValues.forEach((ref) => {
			try {
				Object.assign(refObjects, JSON.parse(ref))
			} catch (e) {
				console.error('Invalid JSON in ref parameter:', ref)
			}
		})

		params.delete('ref')
		params.append('ref', JSON.stringify(refObjects))

		urlObj.search = params.toString()
		return `${urlObj.pathname}${urlObj.search}${urlObj.hash}`
	} catch (error) {
		console.error('Error processing URL:', error)
		return url
	}
}

export const buildRedirectionLink = async ({
	queryParameters,
	deepLinkData,
	language,
	storefrontUrl,
	cartStore,
	addressManagerV2Store
}: {
	queryParameters: URLSearchParams
	deepLinkData: any
	storefrontUrl: string
	language: LanguageCode
	cartStore: CartStore
	addressManagerV2Store: AddressManagerV2Store
}) => {
	const { attachment, destination, storeId, accountTab, utm } = deepLinkData || {}
	const destinationStoreId = destination?.storeId

	generateUtmQueryParameters(utm)?.forEach((value, key) => queryParameters.set(key.toString(), value))

	if (destination?.type === DestinationType.menuSection) {
		const menuUrl = await buildSectionMenuUrl(destination.sectionId || '', destination.sectionName || '', cartStore, addressManagerV2Store, queryParameters.toString())
		return mergeRefParams(menuUrl)
	}

	const path = getRedirectionPath(deepLinkData?.destination?.type, language, deepLinkData?.destination?.path)

	// open selected store page
	if (destinationStoreId && storefrontUrl) {
		try {
			const urls = await storeUrls(destinationStoreId)

			const response = await axios.get(`${urls.storeDetailsUrl}&$pick=seo`)
			const normalizeFileNameStr = await getNormalizedStoreName(response?.data?.title, language)

			return `${path}${normalizeFileNameStr ? `/${normalizeFileNameStr}` : ''}${queryParameters.toString()}`
		} catch (e: any) {
			console.error(e?.message || e.toString())
		}
	}

	// force localization
	const couponOrItemCode = attachment?.couponCode || attachment?.itemCoupon
	if (storeId) {
		queryParameters.set('forceLocalization', 'true')
		queryParameters.set('storeId', storeId)

		if (couponOrItemCode) {
			queryParameters.set('couponCode', couponOrItemCode)
		}
		return `${path}?${queryParameters.toString()}`
	}

	if (accountTab) {
		queryParameters.set('accountTab', accountTab)
	}

	const pathWithQueryParameters = `${path}?${queryParameters.toString()}`

	if (couponOrItemCode) {
		return `${pathWithQueryParameters}#cc_${couponOrItemCode}`
	}

	return pathWithQueryParameters
}

export const fetchDeepLink = async (deepLinkId: string, chainId: string): Promise<DeepLinkData | undefined> => {
	try {
		return (await sendRequest(
			false,
			`${getECommerceDomainByEnv()}/v1/tenants/${chainId}/deepLinks/${deepLinkId}`,
			'get',
			{
				'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
			},
			undefined,
			undefined,
			undefined,
			undefined,
			false
		)) as DeepLinkData | undefined
	} catch (error) {
		console.error(error)
	}
}

export const modifyQueryParameters = (queryParameters: URLSearchParams, deepLinkId: string) => {
	const defaultRefQueryParameterValue = JSON.stringify({ deepLinkId })

	if (!queryParameters) {
		console.error('Invalid query parameters')
		queryParameters = new URLSearchParams({ ref: defaultRefQueryParameterValue })

		return queryParameters
	}

	const ref = queryParameters.get('ref')

	if (!ref) {
		queryParameters.set('ref', defaultRefQueryParameterValue)
	} else {
		try {
			const refObject = JSON.parse(ref)
			const refModifiedObject = { ...refObject, deepLinkId }

			queryParameters.set('ref', JSON.stringify(refModifiedObject))
		} catch (e) {
			console.error(e)

			queryParameters.set('ref', defaultRefQueryParameterValue)
		}
	}

	return queryParameters
}

const filterUtmParameters = (queryString: string): string => {
	const params = new URLSearchParams(queryString)
	const filteredParams = new URLSearchParams()

	for (const [key, value] of params.entries()) {
		if (key.startsWith('utm_')) {
			filteredParams.append(key, value)
		}
	}

	return filteredParams.toString()
}

const getLocalizedStoreMenuPath = async ({
	addressManagerV2Store,
	User,
	cartStore,
	setStore,
}: Omit<DeepLinkDependencies, 'Infra' | 'Home'>): Promise<string> => {
	const res = await addressManagerV2Store.onMenuClickWhenLocalized(cartStore, User)
	if (!res) {
		throw new Error('no localized store menu')
	}

	const { serverSession, menuRes, menuUrl, storeMetaData } = res
	await User.setSession(serverSession)
	setStore((prevStore: any) => ({ ...prevStore, data: menuRes, metaData: storeMetaData }))

	return menuUrl
}

type HandleRedirectionType = (path: string, dependenciesProps: DeepLinkDependencies) => Promise<string>

const handleRedirection: HandleRedirectionType = async (path, dependenciesProps): Promise<string> => {
	const { addressManagerV2Store, User, Infra, Home, setStore, cartStore } = dependenciesProps
	const lang = Infra.appParams.l
	const _path = path.replace(`${lang}/${Page.MENU}`, `[lang]/${Page.MENU}`)
	const page = routeToPage(_path)

	const pathUrl = new URL(path, 'http://some-domain.com')
	const pathSearchParams = pathUrl.searchParams
	const pathHash = pathUrl.hash
	const shouldForceLocalization = pathSearchParams.get('forceLocalization') === 'true'

	switch (page) {
		case Page.STATIC_MENU:
		case Page.MENU: {
			if (shouldForceLocalization) {
				return path.replace(`/${lang}/${Page.MENU}`, '/loading')
			}

			if (addressManagerV2Store.isUserLocalized() && !Home.areAllStoresClosed()) {
				Infra.setAppParams({ ...Infra.appParams, ref: pathSearchParams.get('ref') })
				const menuUrlRes = await getLocalizedStoreMenuPath({ addressManagerV2Store, User, setStore, cartStore })
				const menuUrlSearchParams = new URLSearchParams(menuUrlRes)
				const menuUrlRef = JSON.parse(new URLSearchParams(menuUrlRes).get('ref') || '{}')
				const pathRef = JSON.parse(pathSearchParams.get('ref') || '{}')
				const newRef = { ...menuUrlRef, ...pathRef }
				menuUrlSearchParams.set('ref', JSON.stringify(newRef))

				return `${decodeURIComponent(menuUrlSearchParams.toString())}${pathHash}`
			}

			return path
		}

		default:
			return path
	}
}

export const openDeepLink = async (deepLink: string, router: NextRouter, deepLinkDependencies: DeepLinkDependencies) => {
	const { Infra } = deepLinkDependencies
	try {
		const safeDeepLink = deepLink || '/home'
		const safeRouteToGo = await handleRedirection(safeDeepLink, deepLinkDependencies)

		const url = new URL(safeDeepLink, 'http://some-domain.com')
		const refEncodedData = url.searchParams?.get('ref')

		Infra.setLoading(true)
		Infra.setAppParams({ ...Infra.appParams, ref: encodeURIComponent(refEncodedData || '') })

		sendDDLog({
			level: 'info',
			message: 'deeplink_menu-url-and-path',
			data: {
				menuUrl: localStorage.getItem('menuUrl') ? localStorage.getItem('menuUrl') : 'none',
				path: safeRouteToGo,
			},
		}).catch((err) => console.error(err))

		await router.replace(safeRouteToGo, undefined, { shallow: true })
	} catch (err) {
		if (err instanceof Error) {
			console.error('Error fetching store menu:', err.message)
		} else {
			console.error('Unknown error:', err)
		}
	} finally {
		Infra.setLoading(false)
	}
}
