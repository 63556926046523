import { getTranslatedTextByKey } from 'utils/language'
import { z } from 'zod'

export enum ErrorCode {
	COUPON_NOT_ACTIVE_ERROR = 'COUPON_NOT_ACTIVE_ERROR',
	COUPON_NOT_FOUND = 'COUPON_NOT_FOUND',
	USERS_COUPON_NOT_FOUND = 'USERS_COUPON_NOT_FOUND',
	STORE_SNAPSHOT_NOT_FOUND = 'STORE_SNAPSHOT_NOT_FOUND',
	USERS_COUPON_INDIVIDUAL_COUPON_NOT_FOUND = 'USERS_COUPON_INDIVIDUAL_COUPON_NOT_FOUND',
	USERS_COUPON_INDIVIDUAL_COUPON_NOT_AVAILABLE = 'USERS_COUPON_INDIVIDUAL_COUPON_NOT_AVAILABLE',
	USERS_COUPON_DISCOUNT_INACTIVE = 'USERS_COUPON_DISCOUNT_INACTIVE',
	USERS_COUPON_INDIVIDUAL_PARENT_COUPON_EXISTS = 'USERS_COUPON_INDIVIDUAL_PARENT_COUPON_EXISTS',
	COUPON_AUTHROIZATION_ERROR = 'COUPON_AUTHROIZATION_ERROR',
	USERS_COUPON_INDIVIDUAL_COUPON_EXISTS = 'USERS_COUPON_INDIVIDUAL_COUPON_EXISTS',
	USERS_COUPON_INDIVIDUAL_COUPON_EXISTS_OTHER_USER = 'USERS_COUPON_INDIVIDUAL_COUPON_EXISTS_OTHER_USER',
	TENANT_ORG_NOT_FOUND = 'TENANT_ORG_NOT_FOUND',
	USERS_COUPON_INPUT_VALIDATION_ERROR = 'USERS_COUPON_INPUT_VALIDATION_ERROR',
	USERS_COUPON_TENANT_VALIDATION_ERROR = 'USERS_COUPON_TENANT_VALIDATION_ERROR',
	USERS_COUPON_INTERNAL_SERVER_ERROR = 'USERS_COUPON_INTERNAL_SERVER_ERROR',
	USERS_COUPON_API_INTERNAL_SERVER_ERROR = 'USERS_COUPON_API_INTERNAL_SERVER_ERROR',
	STORE_NOT_FOUND = 'STORE_NOT_FOUND',
	ORG_WITH_INTEGRATION_DISCOUNTS_ERROR = 'ORG_WITH_INTEGRATION_DISCOUNTS_ERROR',
	COUPON_REQUIRES_LOGIN = 'COUPON_REQUIRES_LOGIN',
	CHAIN_CLOSED = 'CHAIN_CLOSED',
	STORE_CLOSED = 'STORE_CLOSED',
	USERS_COUPON_GENERATE_PRB_CODE = 'USERS_COUPON_EXTERNAL_ACTION_GENERATE_QR',

	// Client-side Errors
	COUPON_NOT_AVAILABLE_FOR_CHANNEL = 'COUPON_NOT_AVAILABLE_FOR_CHANNEL',
	COUPON_NOT_AVAILABLE_FOR_TIME = 'COUPON_NOT_AVAILABLE_FOR_TIME',
	COUPON_NOT_AVAILABLE_FOR_ORDER_TYPE = 'COUPON_NOT_AVAILABLE_FOR_ORDER_TYPE',
	COUPON_EXPIRED = 'COUPON_EXPIRED',
	COUPON_REDEEMED = 'COUPON_REDEEMED',
	DEFAULT = 'DEFAULT',
}

const notFoundKey = 'eCommerce.coupons.error.couponNotFound'

const errorTranslations: Record<ErrorCode, string> = {
	[ErrorCode.USERS_COUPON_NOT_FOUND]: notFoundKey,
	[ErrorCode.COUPON_NOT_ACTIVE_ERROR]: 'eCommerce.coupons.error.couponNotActive',
	[ErrorCode.USERS_COUPON_INDIVIDUAL_COUPON_NOT_FOUND]: 'eCommerce.coupons.error.individualCouponNotFound',
	[ErrorCode.TENANT_ORG_NOT_FOUND]: 'eCommerce.coupons.error.tenantNotFound',
	[ErrorCode.STORE_SNAPSHOT_NOT_FOUND]: 'eCommerce.coupons.error.storeSnapshotNotFound',
	[ErrorCode.STORE_NOT_FOUND]: 'eCommerce.coupons.error.storeNotFound',
	[ErrorCode.USERS_COUPON_DISCOUNT_INACTIVE]: 'eCommerce.coupons.error.discountInactive',
	[ErrorCode.USERS_COUPON_API_INTERNAL_SERVER_ERROR]: 'eCommerce.coupons.error.couponApiInternalError',
	[ErrorCode.USERS_COUPON_INPUT_VALIDATION_ERROR]: 'eCommerce.coupons.error.invalidInputParams',
	[ErrorCode.USERS_COUPON_TENANT_VALIDATION_ERROR]: 'eCommerce.coupons.error.tenantIdMissing',
	[ErrorCode.USERS_COUPON_INDIVIDUAL_COUPON_NOT_AVAILABLE]: 'eCommerce.coupons.error.individualCouponNotAvailable',
	[ErrorCode.USERS_COUPON_INDIVIDUAL_COUPON_EXISTS]: 'eCommerce.coupons.error.individualCouponExists',
	[ErrorCode.USERS_COUPON_INDIVIDUAL_PARENT_COUPON_EXISTS]: 'eCommerce.coupons.error.individualParentCouponExists',
	[ErrorCode.USERS_COUPON_INDIVIDUAL_COUPON_EXISTS_OTHER_USER]: 'eCommerce.coupons.error.individualParentCouponExistsOtherUser',
	[ErrorCode.COUPON_AUTHROIZATION_ERROR]: 'eCommerce.coupons.error.authorization',
	[ErrorCode.USERS_COUPON_INTERNAL_SERVER_ERROR]: 'eCommerce.coupons.error.usersCouponInternalError',
	[ErrorCode.COUPON_EXPIRED]: 'eCommerce.coupons.error.expired',
	[ErrorCode.COUPON_REDEEMED]: 'eCommerce.coupons.error.redeemed',
	[ErrorCode.COUPON_NOT_AVAILABLE_FOR_CHANNEL]: 'eCommerce.coupons.error.wrongChannel',
	[ErrorCode.COUPON_NOT_AVAILABLE_FOR_TIME]: 'eCommerce.coupons.error.wrongTime',
	[ErrorCode.COUPON_NOT_AVAILABLE_FOR_ORDER_TYPE]: 'eCommerce.coupons.error.wrongOrderType',
	[ErrorCode.DEFAULT]: 'eCommerce.coupons.error.default',
	[ErrorCode.ORG_WITH_INTEGRATION_DISCOUNTS_ERROR]: notFoundKey,
	[ErrorCode.COUPON_REQUIRES_LOGIN]: 'eCommerce.coupons.error.requiresLogIn',
	[ErrorCode.COUPON_NOT_FOUND]: notFoundKey,
	[ErrorCode.CHAIN_CLOSED]: 'eCommerce.coupons.error.chainClosed',
	[ErrorCode.STORE_CLOSED]: 'eCommerce.coupons.error.storeClosed',
	[ErrorCode.USERS_COUPON_GENERATE_PRB_CODE]: 'eCommerce.coupons.error.inStoreCode',
}

const defaultMessage = 'Something went wrong, please try again.'
const notFoundMessage = 'Invalid coupon code. Please verify the code and try again.'

const defaultErrorTranslations: Record<ErrorCode, string> = {
	[ErrorCode.USERS_COUPON_DISCOUNT_INACTIVE]: 'This discount is not available at the moment.',
	[ErrorCode.USERS_COUPON_INDIVIDUAL_COUPON_NOT_AVAILABLE]: 'This coupon is not available at the moment.',
	[ErrorCode.USERS_COUPON_INDIVIDUAL_COUPON_EXISTS]: 'This coupon is already in your wallet.',
	[ErrorCode.USERS_COUPON_INDIVIDUAL_PARENT_COUPON_EXISTS]: 'This coupon is already in your wallet.',
	[ErrorCode.USERS_COUPON_INDIVIDUAL_COUPON_EXISTS_OTHER_USER]: 'This coupon has been claimed by another user.',
	[ErrorCode.COUPON_AUTHROIZATION_ERROR]: 'This coupon has been claimed by another user.',
	[ErrorCode.COUPON_EXPIRED]: 'This coupon has expired and cannot be used.',
	[ErrorCode.COUPON_REDEEMED]: 'This coupon has already been used and cannot be used again.',
	[ErrorCode.COUPON_NOT_AVAILABLE_FOR_CHANNEL]: 'This coupon is only available through {{channels}}',
	[ErrorCode.COUPON_NOT_AVAILABLE_FOR_TIME]: 'This coupon is not available at the moment',
	[ErrorCode.COUPON_NOT_AVAILABLE_FOR_ORDER_TYPE]: 'This coupon is not available for {{orderType}}',
	[ErrorCode.USERS_COUPON_NOT_FOUND]: notFoundMessage,
	[ErrorCode.COUPON_NOT_ACTIVE_ERROR]: 'This coupon is no longer available.',
	[ErrorCode.COUPON_NOT_FOUND]: notFoundMessage,
	[ErrorCode.USERS_COUPON_INDIVIDUAL_COUPON_NOT_FOUND]: notFoundMessage,
	[ErrorCode.TENANT_ORG_NOT_FOUND]: defaultMessage,
	[ErrorCode.STORE_SNAPSHOT_NOT_FOUND]: defaultMessage,
	[ErrorCode.ORG_WITH_INTEGRATION_DISCOUNTS_ERROR]: notFoundMessage,
	[ErrorCode.STORE_NOT_FOUND]: defaultMessage,
	[ErrorCode.USERS_COUPON_INTERNAL_SERVER_ERROR]: defaultMessage,
	[ErrorCode.USERS_COUPON_API_INTERNAL_SERVER_ERROR]: defaultMessage,
	[ErrorCode.USERS_COUPON_INPUT_VALIDATION_ERROR]: defaultMessage,
	[ErrorCode.USERS_COUPON_TENANT_VALIDATION_ERROR]: defaultMessage,
	[ErrorCode.DEFAULT]: defaultMessage,
	[ErrorCode.COUPON_REQUIRES_LOGIN]: 'You must be logged in to use this coupon',
	[ErrorCode.CHAIN_CLOSED]: 'Chain is closed',
	[ErrorCode.STORE_CLOSED]: 'Store is closed',
	[ErrorCode.USERS_COUPON_GENERATE_PRB_CODE]: 'There was an error loading this coupon, please try again later.',
}

const schema = z.object({ error: z.object({ message: z.string(), code: z.string() }) })

export interface Translation {
	key: string
	fallback: string
}

export const translate = (code: ErrorCode): Translation => ({
	key: errorTranslations[code],
	fallback: defaultErrorTranslations[code],
})

export default class CouponError extends Error {
	constructor(message: string, public code: ErrorCode, public continueFlow = false) {
		super(message)
		this.code = code
		this.continueFlow = continueFlow
	}

	translate(): Translation {
		return translate(this.code)
	}

	text(variables?: Record<string, string>): string {
		const translation = getTranslatedTextByKey(this.translate().key, this.translate().fallback)

		if (!variables) {
			return translation
		}

		return Object.entries(variables).reduce((str, [key, value]): string => str.replace(`{{${key}}}`, value), translation)
	}

	static isCouponError(maybeError: unknown): maybeError is z.infer<typeof schema> {
		return schema.safeParse(maybeError).success
	}
}
