import type { ShowSnackbarProps } from 'mobx/Infra/Infra.type'
import type { NextRouter } from 'next/router'
import type { Channel, Coupon, OrderType } from 'shared-types/Coupon'

export type StoreStatus = 'Active' | 'Closed'

export interface AvailabilitySegment {
	minuteOfWeek: number
	durationMins: number
}
export interface Availability {
	weekly: AvailabilitySegment[]
	exceptions?: AvailabilityException[]
}

export interface ExceptionDate {
	year: number
	month: number
	day: number
	hour: number
	minute: number
}

export default interface AvailabilityException {
	comment?: object
	start: ExceptionDate
	end: ExceptionDate
	available: boolean
}

export interface CouponToApply {
	coupon: Coupon
	orderTypeToForce: OrderType | null
	ready: boolean
}

export enum CheckCouponOn {
	CHAIN = 'chain',
	STORE = 'store',
}

export interface StartCouponFlowProps {
	code: string
	orderTypeToForce: OrderType | null
	openModal?: boolean
	checkCouponOn?: CheckCouponOn
}

export interface CouponFlowDependencies {
	router: NextRouter | null
	getCoupon(code: string, checkCouponOn: CheckCouponOn): Promise<Coupon | null>
	isLocalized(): boolean
	resetLocalization(): void
	isPageLegal(currentPath: string): boolean
	openLocalizationModal(couponToApply: CouponToApply): void
	isStoreClosed(): Promise<boolean>
	setCouponModal(coupon: Coupon | null): void
	showError(props: {
		message: string
		title?: string
		cancelText?: string
		okText?: string
		showCancelButton?: boolean
		okAction?: () => void
		cancelAction?: () => void
	}): void
	showConfirm(message: string, okAction: () => void, cancelAction?: () => void): void
	isChainClosed(): boolean
	isCouponForChannel(couponChannels: Channel[]): boolean
	isUserLoggedIn(): boolean
	getTranslatedTextByKey(path: string, fallback?: string): string
	assertCouponAvailable(coupon: Coupon, { date, channel, orderType }: { date: Date; channel: Channel; orderType: OrderType }): void
	getOrderType(): number
	showSnackbar({ key, snackId, message, status, isAttachedToElement, duration }: ShowSnackbarProps): void
	getAndUpdateSession(): Promise<
		| {
				menuRes: any
				storeMetaData: any
				serverSession: any
				menuUrl: any
		  }
		| undefined
	> | undefined
	removeDiscount(code: string): Promise<void>
}
