// @ts-nocheck
import React from 'react'
import { observable, action } from 'mobx'
import {
	LOCATION_NOT_GRANTED,
	LOCATION_GRANTED,
	APPLICATION_STATE_CHANGED,
	SPLASH_SCREEN_HIDDEN,
	MOBILE_PUSH_ACCEPTED,
	MOBILE_PUSH_REJECTED,
	MOBILE_PUSH_ERROR,
	MOBILE_PUSH_CLICKED,
	PUSH_NOTIFICATION_SUPPORTED,
} from 'constants/mobileEvents'
import { CONSTANTS } from 'utils/constants'
import { getECommerceDomainByEnv, getTranslatedTextByKey, isNodeJsEnv } from 'utils/utils'
import ReactNativeComms from 'utils/reactNativeComms'
import { getMobileOperatingSystem, applicationRequiresUpdate, showUpdateLinkFromVersionButton } from 'utils/mobileUtils'
import { sendCustomEvent } from 'utils/analytics/analytics'
import MobileApplicationDependencies from './MobileApplicationDependencies'
import { handlePushNotificationReceived } from 'utils/pushNotifications/pushNotificationsUtils'
import type { Stores } from 'contexts/StoresContext'

class MobileApplication {
	dependencies = null

	/**
	 * Store the path created by StartOrder so the mobile app's 'menu' footer button can open the menu page with the correct url
	 * @type {null}
	 */
	@observable menuPath: string | null = null

	@observable pushNotificationSupported = false

	@observable userLocation = null

	@observable userLocationPermissionStatus = CONSTANTS.MOBILE_APP.PERMISSIONS.LOCATION.NOT_ASKED

	constructor(dependencies) {
		this.dependencies = dependencies
	}

	@action setMenuPath = (path) => {
		this.menuPath = path
	}

	@action setLocationPermissionsStatus = (newStatus) => {
		this.userLocationPermissionStatus = newStatus
	}

	/**
	 * The method openMobileAppUpdateDialog is called when an event is sent from
	 * the React Native layer.
	 * To simulate receiving an event from a browser:
	 *     - Set 'forceMobileApp' true in localStorage and
	 *     - Execute this code in the console:
	 *       window.postMessage({ eventName: 'foobar', dataObject: {}})
	 */
	openMobileAppUpdateDialog = async (Infra, mobileAppEvent) => {
		const operatingSystem = getMobileOperatingSystem()
		const ios = 'ios'
		const appUrl = operatingSystem === ios ? Infra?.eCommerceFooter?.apps?.ios?.link : Infra?.eCommerceFooter?.apps?.android?.link

		const version = mobileAppEvent?.data?.dataObject?.version
		const showUpdateLinkFromVersion = Infra.getParams()?.mobileApp?.showUpdateLinkFromVersion

		const showForceUpdateButton =
			showUpdateLinkFromVersion && showUpdateLinkFromVersionButton(version, showUpdateLinkFromVersion) && showUpdateLinkFromVersion !== version

		if (appUrl && version) {
			let MobileAppUpdate
			if (!isNodeJsEnv) {
				import('components/common/customDialogBox/MobileAppUpdate').then((module) => {
					MobileAppUpdate = module.default
					const notificationParams = {
						isPermanent: true,
						open: true,
						message: <MobileAppUpdate />,
						closable: false,
						showCancelButton: false,
					}

					if (showForceUpdateButton) {
						notificationParams.okAction = () => window.open(appUrl, '_blank')
						notificationParams.okText = getTranslatedTextByKey('update', 'update')
						// notificationParams = {
						// 	...notificationParams,
						// 	okAction: () => window.open(appUrl, '_blank'),
						// 	okText: getTranslatedTextByKey('update', 'update'),
						// }
					}

					Infra.setNotification(notificationParams)
				})
			}
		}
	}

	// TODO: temporary code to remove once all users of KFC Philippines app will using the new app (from tictuk)
	openMobileForceAppUpdateDialog = async (Infra) => {
		const operatingSystem = getMobileOperatingSystem()
		const enabled = Infra?.appParams.mobileApp?.forceUpdate?.enabled === true
		const closable = Infra?.appParams.mobileApp?.forceUpdate?.closable === true
		const appUrl = operatingSystem === 'ios' ? Infra?.eCommerceFooter?.apps?.ios?.link : Infra?.eCommerceFooter?.apps?.android?.link

		if (enabled && appUrl) {
			let MobileAppUpdate
			import('components/common/customDialogBox/MobileAppUpdate').then((module) => {
				MobileAppUpdate = module.default
				Infra.setNotification({
					isPermanent: true,
					open: true,
					message: <MobileAppUpdate />,
					closable,
					showCancelButton: false,
					okText: getTranslatedTextByKey('update', 'update'),
				})
			})
		}
	}

	getSessionId = () => this.dependencies.getSessionId()

	saveUserConsistentIdExpoTokenPair = async (Infra) => {
		const host = getECommerceDomainByEnv()
		const chainId = Infra.getParams().c
		const requestUrl = `${host}/v2/tenants/${chainId}/push-notification-tokens`
		const token = this.getPushNotificationsToken()

		if (!host) {
			throw new Error('Authorization was not granted (no host)')
		}
		if (!chainId) {
			throw new Error('Authorization was not granted (no chain)')
		}
		if (!token || token === 'undefined') {
			console.warn('Authorization was not granted (no token)')
			return
		}

		try {
			const sessionId = this.getSessionId()

			if (!sessionId) {
				return
			}

			await this.dependencies.sendRequestDependency(false, requestUrl, 'post', {
				pushNotificationToken: token,
				sessionId,
			})
		} catch (error) {
			console.error(error.message)
		}
	}

	handleMobileApplicationEvent = (stores: Stores, Infra, Account, Application) => async (mobileAppEvent) => {
		switch (mobileAppEvent.data?.eventName) {
			case APPLICATION_STATE_CHANGED: {
				const version = mobileAppEvent.data?.dataObject?.version
				const minimalRequiredVersion = Infra.getParams()?.mobileApp?.minimalRequiredVersion

				const condition1 = minimalRequiredVersion && applicationRequiresUpdate(version, minimalRequiredVersion)
				// condition2 can be deprecated, we have to check which market is using this flag and use the new condition to force update
				const condition2 = !this.pushNotificationSupported && Infra.getParams()?.features?.showMobileAppUpdateDialog

				if (condition1 || condition2) {
					this.openMobileAppUpdateDialog(Infra, mobileAppEvent)
				} else {
					Infra.closeNotification(false, true)
				}

				break
			}
			case SPLASH_SCREEN_HIDDEN: {
				const version = mobileAppEvent.data?.dataObject?.version || ''
				const minimalRequiredVersion = Infra.getParams()?.mobileApp?.minimalRequiredVersion

				localStorage.setItem(CONSTANTS.MOBILE_APP.VERSION_LOCAL_STORAGE_KEY, version)

				const condition1 = minimalRequiredVersion && applicationRequiresUpdate(version, minimalRequiredVersion)
				// condition2 can be deprecated, we have to check which market is using this flag and use the new condition to force update
				const condition2 = !this.pushNotificationSupported && Infra.getParams()?.features?.showMobileAppUpdateDialog

				if (condition1 || condition2) {
					this.openMobileAppUpdateDialog(Infra, mobileAppEvent)
					break
				}

				const isDeepLink = window.location.pathname.includes('tt-links')
				// Open a sign-up popup
				if (!Account.userExists && !Infra.getParams()?.features?.disableSignUp && !isDeepLink) {
					Account.openSignUpPopUp(true)
				}

				break
			}
			case MOBILE_PUSH_ACCEPTED:
				try {
					// For now, the request only works for identified users (or with an active guest session)
					const host = getECommerceDomainByEnv()
					const chainId = Infra.getParams().c
					const requestUrl = `${host}/v2/tenants/${chainId}/push-notification-tokens`
					const token = mobileAppEvent?.data?.dataObject?.pushNotificationToken ?? ''
					if (!host) {
						alert('Authorization was not granted (no host)')
						return
					}
					if (!chainId) {
						alert('Authorization was not granted (no chain)')
						return
					}
					if (!token) {
						alert('Authorization was not granted (no token)')
						return
					}

					try {
						const sessionId = this.getSessionId()

						if (!sessionId) {
							return
						}

						await this.dependencies.sendRequestDependency(false, requestUrl, 'post', {
							pushNotificationToken: token,
							sessionId,
						})

						this.setPushNotificationsStatus('accepted')
						this.setPushNotificationsToken(token)
						this.resetPushNotificationsDeclineDate()
					} catch (error) {
						alert(error.message)
					}
				} catch (error) {
					alert(error.message)
					console.error(error)
				}
				break
			case MOBILE_PUSH_ERROR:
				// reset everything related to push notifications, so it will open the popup next time the app starts
				this.setPushNotificationsStatus('')
				this.setPushNotificationsToken('')
				this.resetPushNotificationsDeclineDate()
				break
			case MOBILE_PUSH_REJECTED:
				this.setPushNotificationsStatus('rejected')
				this.dependencies.setPushNotificationsDeclineDate()
				break
			case LOCATION_NOT_GRANTED:
				this.setLocationPermissionsStatus(CONSTANTS?.MOBILE_APP?.PERMISSIONS?.LOCATION?.DENIED ?? 0)
				break
			case MOBILE_PUSH_CLICKED:
				try {
					await handlePushNotificationReceived(mobileAppEvent.data?.dataObject, stores, Infra, Account, Application)
					break
				} catch (error) {
					alert(error.message)
					console.error(error)
					break
				}
			case LOCATION_GRANTED:
				this.userLocation = mobileAppEvent.data?.dataObject?.coords
				this.setLocationPermissionsStatus(CONSTANTS?.MOBILE_APP?.PERMISSIONS?.LOCATION?.GRANTED ?? 0)
				break
			case PUSH_NOTIFICATION_SUPPORTED:
				sendCustomEvent({ category: 'push notifications', action: 'event received', label: 'supported' })
				this.pushNotificationSupported = true
				break

			default:
				console.warn(`Event not supported: ${mobileAppEvent.data?.eventName}`)
				break
		}
	}

	resetPushNotificationsDeclineDate = () => this.dependencies.resetPushNotificationsDeclineDate()

	getPushNotificationsDeclineDate = () => this.dependencies.getPushNotificationsDeclineDate()

	setPushNotificationsDeclineDate = () => {
		this.dependencies.setPushNotificationsDeclineDate()
	}

	setPushNotificationsStatus = (value) => {
		this.dependencies.setPushNotificationsStatus(value)
	}

	getPushNotificationsStatus = () => this.dependencies.getPushNotificationsStatus()

	setPushNotificationsToken = (value) => {
		this.dependencies.setPushNotificationsToken(value)
	}

	getPushNotificationsToken = () => this.dependencies.getPushNotificationsToken()
}

export default new MobileApplication(MobileApplicationDependencies)
