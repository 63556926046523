import type React from 'react'
import { useEffect, useContext } from 'react'
import { useStores } from 'hooks/useStores'
import { observer } from 'mobx-react'
import { StoreContext } from 'contexts/StoreContext'
import { useRouter } from 'next/router'
import routeToPage from 'utils/routeToPage'
import { Page } from 'utils/constants'

const CouponFlowService: React.FC = () => {
	const { pathname } = useRouter()
	const { couponFlowStore, couponsStore, cartStore } = useStores()
	const { store } = useContext(StoreContext)
	const { data: menu } = store

	const { couponToApply } = couponFlowStore

	const { ready: couponToApplyReady } = couponToApply || {}

	useEffect(() => {
		const currentPage = routeToPage(pathname, true)
		const pagesToStartApply = [Page.MENU, Page.CHECKOUT]
		if (!currentPage || !pagesToStartApply.includes(currentPage)) {
			return
		}
		if (couponToApply && couponToApplyReady && menu) {
			// start apply flow after localization logic
			cartStore.addCoupon(couponToApply.coupon)
			couponFlowStore.clearCouponToApply()
		}
	}, [couponFlowStore, couponToApply, couponsStore, couponToApplyReady, pathname, menu, cartStore])

	return null // This component does not render anything
}

export default observer(CouponFlowService)
