import queryString from 'query-string'

import sessionDependencies from './repository'

import type { ParsedQuery } from 'query-string'
import type { InitOrUpdateSession } from './types'

type SessionDependencies = typeof sessionDependencies

class Session {
	private readonly dependencies: SessionDependencies

	// SessionId should be used from session storage. Currently this session id property is for internal use only
	private sessionId: string | null

	constructor(dependencies: SessionDependencies) {
		this.dependencies = dependencies
		this.sessionId = null
	}

	getSessionId = () => this.sessionId

	initOrUpdateSession = async ({
		storeId,
		refObject = {},
		wru,
		chainId,
		stopLoading = true,
		shouldRedirectIfError = true,
	}: InitOrUpdateSession) => {
		let newMenuQueryParams: ParsedQuery<string> | null = null
		let menuPath = ''

		try {
			menuPath = await this.dependencies.initOrUpdateSession({
				storeId,
				refObject,
				wru,
				chainId,
				stopLoading,
				shouldRedirectIfError,
			})

			newMenuQueryParams = queryString.parse((menuPath ?? '')?.split('?')[1])
		} catch (error) {
			console.error(error)
		}

		this.sessionId = newMenuQueryParams?.request as string | null

		return menuPath
	}
}

export { Session }

export default new Session(sessionDependencies)
