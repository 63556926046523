// @ts-nocheck
import React, { useContext, useEffect, useState } from 'react'
import { isMobile, isAndroid, areAllVariationsHidden } from '../../utils/utils'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'
import MenuItemPage from './menuItemPage/MenuItemPage'
import { finish } from '../../utils/chatAppUtils'
import queryString from 'query-string'
import classnames from 'classnames'
import { itemNotFoundPopUp } from 'utils/menu/menuUtils'

import { StoreContext } from '../../contexts/StoreContext'

import { useClientSideRendering } from 'hooks/useClientSideRendering'
import { useStores } from 'hooks/useStores'

const Container = styled.div`
	.overlay {
		height: 100%;
		width: 100%;
		left: 0;
		right: 0;
		bottom: -150vh;
		display: flex;
		z-index: 1300;
		position: fixed;
		align-items: center;
		justify-content: center;
		background-color: rgba(0, 0, 0, 0.5);
		-webkit-tap-highlight-color: transparent;
		opacity: 1;
		&.show {
			bottom: 0;
		}
		pointer-events: unset;
		touch-action: none;
	}

	.modal {
		position: fixed;
		bottom: -150vh;
		background-color: #fff;
		left: 0;
		right: 0;
		transition: bottom 0.3s ease-out;
		&.show {
			bottom: 0;
			@media (min-width: 600px) {
				bottom: 3%;
			}
		}
		width: 100%;
		height: 100%;
		flex: 1 0 auto;
		display: flex;
		outline: 0;
		z-index: 1301;
		flex-direction: column;
		margin: 0 auto;

		&.itemHasNoVariations {
			width: 600px;
			height: 70%;
			bottom: 25%;
		}

		@media (min-width: 600px) {
			width: 600px;
			height: 90%;
		}

		@media (min-width: 1280px) {
			width: 1078px;

			height: 90%;
		}
	}
`

const MenuItemOverlay = inject(
	'ItemAdditions',
	'User',
	'Infra',
	'Application'
)(
	observer((props) => {
		const { cartStore } = useStores()
		const _isMobile = isMobile()
		const _isAndroid = isAndroid()
		const isClientSideRendering = useClientSideRendering()

		const { store } = useContext(StoreContext)
		const rest = store.data
		const { ItemAdditions, User, Infra, Application } = props

		const [state, setState] = useState({
			animationStyle: false,
			beenUsedOnce: false,
		})

		const closeModal = () => {
			const parsedQueryString = queryString.parse(window.location.search)
			if (
				(parsedQueryString.mode && parsedQueryString.mode === 'editSingle') ||
				(parsedQueryString.ais && parsedQueryString.ais === 'expressCheckout')
			) {
				// close the popup in a chat app
				finish(true)
			} else {
				cartStore.closeItemModal()
			}
		}

		useEffect(() => {
			const parsedQueryString = queryString.parse(window.location.search)
			if (cartStore.itemModalOpen) {
				if (!rest?.items[cartStore.modalItemId]) {
					cartStore.closeItemModal()
					itemNotFoundPopUp(Infra)
					return
				}

				if (_isAndroid) {
					setTimeout(() => {
						setState({
							animationStyle: true,
							beenUsedOnce: true,
						})
					}, 50)
				} else {
					// setTimeout(() => {
					// setAnimationStyle(true)
					setState({
						animationStyle: true,
						beenUsedOnce: true,
					})

					// }, 50)
				}
			} else if (!cartStore.itemModalOpen && state.beenUsedOnce) {
				if (
					(parsedQueryString.mode && parsedQueryString.mode === 'editSingle') ||
					(parsedQueryString.ais && parsedQueryString.ais === 'expressCheckout')
				) {
					// close the popup in a chat app
					finish(false, cartStore.items, rest.items, true)
				} else {
					setTimeout(() => {
						setState({
							animationStyle: false,
							beenUsedOnce: true,
						})
					}, 100)
				}
			}
		}, [Infra, _isAndroid, cartStore, cartStore.itemModalOpen, rest, rest?.items, state.beenUsedOnce])

		const item = rest?.items[cartStore.modalItemId]
		const itemHasNoVariations =
			(item && !item.variations) ||
			(item && item.variations.length === 0) ||
			(item && item.variations && areAllVariationsHidden(item.variations, rest))

		return (
			<Container>
				<div
					className={classnames(
						'menuItemOverlay modal',
						cartStore.itemModalOpen ? 'show' : '',
						cartStore.itemModalOpen && itemHasNoVariations && !_isMobile ? 'itemHasNoVariations' : ''
					)}
				>
					{cartStore.itemModalOpen && (
						<MenuItemPage
							ItemAdditions={ItemAdditions}
							closeModal={closeModal}
							User={User}
							Infra={Infra}
							itemHasNoVariations={itemHasNoVariations}
						/>
					)}
				</div>

				{isClientSideRendering && !_isMobile && (
					<div className={classnames('menuItemOverlay overlay', cartStore.itemModalOpen ? 'show' : '')} />
				)}
			</Container>
		)
	})
)

export default MenuItemOverlay
