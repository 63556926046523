import type { ReactElement } from 'react'
import React, { useEffect, useState } from 'react'
import Slide from '@material-ui/core/Slide'
import styled from 'styled-components'
import SnackbarBase from '@material-ui/core/Snackbar'
import TypographyPro from 'themes/TypographyPro'
import { inject, observer } from 'mobx-react'
import type { TransitionProps } from '@material-ui/core/transitions'
import AssetsIcon from '../../static/components/StoreLocator/common/AssetsIcon'
import type { SnackbarStatus } from 'mobx/Infra/Infra.type'

const statusObj = {
	success: {
		pathIcon: '/icons/checkIcon.svg',
		bgColor: 'var(--successBg)',
	},
	error: {
		pathIcon: '/icons/errorIcon.svg',
		bgColor: 'var(--errorBg)',
	},
	warning: {
		pathIcon: '/icons/warning.svg',
		bgColor: 'var(--warningBg)',
	},
	info: {
		pathIcon: '/icons/info.svg',
		bgColor: 'var(--infoBg)',
	},
	deal: {
		pathIcon: '/icons/deal.svg',
		bgColor: 'var(--couponBg)',
	},
}

const StyledSnackbar = styled(SnackbarBase)<{ $positionAndSize?: DOMRect; $position: number }>`
	bottom: ${({ $position }) => $position}px;
	transform: translateY(-15px);
	width: ${({ $positionAndSize }) => $positionAndSize?.width}px;
	right: 0;
	left: unset;

	@media (max-width: 576px) {
		transform: translateY(-10px);
	}
`

const StatusIcon = styled(AssetsIcon).attrs(({ $status }: { $status: SnackbarStatus }) => ({
	asset: statusObj[$status]?.pathIcon,
	testId: statusObj[$status]?.pathIcon,
	$status,
	className: 'status-icon',
	alt: 'Status Icon',
}))`
	margin-inline-end: 10px;
	min-width: 20px;
`

const SnackbarContent = styled.div<{ $status: SnackbarStatus; width: number }>`
	background-color: ${({ $status }) => statusObj[$status]?.bgColor};
	width: ${({ width }) => width}px;
	display: flex;
	justify-content: start;
	align-items: center;
	min-height: 52px;
	padding: 10px 20px;
	box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
`

const SnackbarMessage = styled(TypographyPro)`
	color: var(--fonts);
`
interface CurrentSnackBar {
	open: boolean
	onClose: () => void
	message: string | ReactElement
	duration: number
	positionAndSize: DOMRect
	status: SnackbarStatus
}

interface SnackbarProps {
	Infra?: {
		currentSnackBar: CurrentSnackBar
		onCloseSnackbar: () => void
	} & Record<string, any>
}

const Snackbar = inject('Infra')(
	observer(({ Infra }: SnackbarProps) => {
		const { currentSnackBar } = Infra!
		const [position, setPosition] = useState(0)

		useEffect(() => {
			setPosition(window.innerHeight - (currentSnackBar?.positionAndSize?.top ?? 0))
		}, [currentSnackBar?.positionAndSize?.top])

		return (
			<StyledSnackbar
				open={currentSnackBar?.open}
				onClose={currentSnackBar.onClose || (() => Infra?.onCloseSnackbar())}
				TransitionComponent={Slide}
				TransitionProps={{ direction: 'left' } as TransitionProps}
				message={currentSnackBar?.message}
				$position={position}
				$positionAndSize={currentSnackBar?.positionAndSize}
				anchorOrigin={{
					horizontal: 'right',
					vertical: 'bottom',
				}}
				autoHideDuration={currentSnackBar.duration}
				data-testid={`snackbar-status-${currentSnackBar?.status}`}
			>
				<SnackbarContent width={currentSnackBar?.positionAndSize?.width} $status={currentSnackBar?.status} data-testid="snackbar-content">
					<StatusIcon $status={currentSnackBar?.status} />
					<SnackbarMessage variant="BodyRegular" component="div" data-testid="snackbar-text">
						{currentSnackBar?.message}
					</SnackbarMessage>
				</SnackbarContent>
			</StyledSnackbar>
		)
	})
)

export default Snackbar
