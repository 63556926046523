import React, { useEffect, useState } from 'react'
import { getTranslatedTextByKey } from 'utils/utils'
import { ERROR_IMAGE_ROUTE } from 'utils/staticFilesRoutes'
import {
	ErrorPageContainer,
	ErrorPageContent,
	ErrorPageContentButton,
	ErrorPageContentDescription,
	ErrorPageContentImage,
	ErrorPageContentSubTitle,
	ErrorPageContentTitle,
	ErrorPageNoButtonHeader,
	redirectToChat,
	GENERIC_ERROR_DESCRIPTION,
	GENERIC_ERROR_SUBTITLE,
	GENERIC_ERROR_TITLE,
} from 'static/components/errors/ErrorPagesSharedComponents'
import { inject, observer } from 'mobx-react'
import type _Infra from 'mobx/Infra'
import { getThemeUrl } from 'utils/theme/themeManager'
import { THEME_STRATEGY } from 'constants/theme'

interface ChatGenericErrorPageProps {
	Infra: typeof _Infra
	appId: string
	targetChatUrl: string
}

const ChatGenericErrorPage = inject('Infra')(
	observer(({ Infra, appId, targetChatUrl }: ChatGenericErrorPageProps) => {
		const { c: chainId } = Infra.appParams
		const [isValidReturnButton, setIsValidReturnButton] = useState(true)
		const imgPath = `${getThemeUrl(THEME_STRATEGY.GENERIC, chainId)}/assets`

		// Possibly we can get params from querystring so will not pass via props
		// const queryParams = new URLSearchParams(window.location.search)
		// const appId = queryParams.get('app')
		// const targetChatUrl = queryParams.get('path')

		useEffect(() => {
			if (appId && targetChatUrl) {
				return setIsValidReturnButton(true)
			}
			return setIsValidReturnButton(false)
		}, [appId, targetChatUrl])

		return (
			<ErrorPageContainer>
				<ErrorPageNoButtonHeader />
				<ErrorPageContent>
					<ErrorPageContentImage src={`${imgPath}${ERROR_IMAGE_ROUTE}`} alt="error image" />
					<ErrorPageContentTitle variant="HomePageTitle" component="h1">
						{getTranslatedTextByKey('eCommerce.errorPages.genericErrorTitle', GENERIC_ERROR_TITLE).toUpperCase()}
					</ErrorPageContentTitle>
					<ErrorPageContentSubTitle variant="SubheadHighlighted">
						{getTranslatedTextByKey('eCommerce.errorPages.genericErrorSubtitle', GENERIC_ERROR_SUBTITLE)}
					</ErrorPageContentSubTitle>
					<ErrorPageContentDescription variant="Subhead" component="div">
						{getTranslatedTextByKey('eCommerce.errorPages.genericErrorDescription', GENERIC_ERROR_DESCRIPTION)}
					</ErrorPageContentDescription>
					{isValidReturnButton && (
						<ErrorPageContentButton
							onClick={() => {
								redirectToChat(targetChatUrl, appId, setIsValidReturnButton)
							}}
							$size="big"
						>
							{getTranslatedTextByKey('eCommerce.errorPages.newSession', 'Start new session')}
						</ErrorPageContentButton>
					)}
				</ErrorPageContent>
			</ErrorPageContainer>
		)
	})
)

export default ChatGenericErrorPage
