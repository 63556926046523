import { isItemAvailable as isItemAvailableAtDate } from '@tictuk/item-availability'
import type MenuSection from 'types/MenuSection'
import type MenuItem from 'types/MenuItem'
import type { OrderType } from 'types/OrderType'
import { union } from 'lodash-es'
import type { ChannelType } from 'types/ChannelType'
import { CONSTANTS } from 'utils/constants'

const isItemAvailableForChannel = (channelsArray: ChannelType[], channelType: ChannelType): boolean =>
	!channelsArray.length || channelsArray.includes(channelType) || channelType === CONSTANTS.CHANNEL_TYPE.ALL // channelType === 'all' - for static menu
const isItemAvailableForOrderType = (orderTypesArray: OrderType[], orderType: OrderType): boolean =>
	orderType === 'all' || orderTypesArray.includes(orderType)
const isItemAvailable = ({ item, date, channelTypes, orderTypes, channelType, orderType }: any) =>
	isItemAvailableAtDate(item, new Date(date)) &&
	isItemAvailableForChannel(channelTypes, channelType) &&
	isItemAvailableForOrderType(orderTypes, orderType)

export const allChannelTypes = Object.values(CONSTANTS.CHANNEL_TYPE) as ChannelType[]
export const allOrderTypes = Object.values(CONSTANTS.ORDER_TYPE) as OrderType[]

export const filterItemsAndSections = ({
	items,
	sections = [],
	hiddenSections = [],
	date,
	orderType,
	channelType,
}: {
	items: Record<string, MenuItem>
	sections: MenuSection[]
	hiddenSections: MenuSection[]
	date: string
	orderType: OrderType
	channelType: ChannelType
}): {
	filteredItems: Record<string, MenuItem>
	filteredSections: MenuSection[]
} => {
	const allMenuSections = sections.concat(hiddenSections)
	const hiddenSectionsIds = hiddenSections.map((section) => section.id)

	const filteredItems: Record<string, MenuItem> = {}
	const filteredSections: MenuSection[] = []

	for (const section of allMenuSections) {
		const filteredItemIds = []

		for (const itemId of section.itemIds ?? []) {
			if (items[itemId]) {
				const mergedChannelTypes = getItemComputedChannelTypes(items[itemId], section.channelTypes)
				const mergedOrderTypes = getItemComputedOrderTypes(items[itemId], section.orderTypes)

				if (
					isItemAvailable({
						item: items[itemId],
						date,
						channelTypes: mergedChannelTypes,
						orderTypes: mergedOrderTypes,
						channelType,
						orderType,
					})
				) {
					filteredItemIds.push(itemId)
					filteredItems[itemId] = items[itemId]
				}
			}
		}
		if (filteredItemIds.length) {
			section.itemIds = filteredItemIds
			if (!hiddenSectionsIds.includes(section.id)) {
				filteredSections.push(section)
			}
		}
	}

	addAvailableItemsNotInSections(items, date, channelType, orderType, filteredItems)

	return { filteredItems, filteredSections }
}

export const addAvailableItemsNotInSections = (
	items: Record<string, MenuItem>,
	date: string,
	channelType: ChannelType,
	orderType: OrderType,
	filteredItems: Record<string, MenuItem>
) => {
	Object.values(items).forEach((item) => {
		if (
			isItemAvailable({
				item,
				date,
				channelTypes: item.description?.channelTypes?.length ? item.description.channelTypes : allChannelTypes,
				orderTypes: item.description?.orderTypes?.length ? item.description.orderTypes : allOrderTypes,
				channelType,
				orderType,
			}) &&
			!item.sectionItem &&
			item.id
		) {
			filteredItems[item.id] = item
		}
	})
}

const getItemComputedChannelTypes = (item: MenuItem, channelTypesFromSection: ChannelType[] = []) => {
	if (!channelTypesFromSection.length && item.description?.channelTypes?.length) {
		return item.description.channelTypes
	}
	return union(channelTypesFromSection?.length ? channelTypesFromSection : allChannelTypes, item.description?.channelTypes)
}

const getItemComputedOrderTypes = (item: MenuItem, orderTypesFromSection: OrderType[] = []) => {
	if (!orderTypesFromSection.length && item.description?.orderTypes?.length) {
		return item.description?.orderTypes
	}
	return union(orderTypesFromSection?.length ? orderTypesFromSection : allOrderTypes, item.description?.orderTypes)
}
