import type { HourString } from './types'

export default class Hour {
	public constructor(public hour: HourString) {
		Hour.validate(hour)
		this.hour = Hour.normalise(hour)
	}

	static validate(str: string): asserts str is HourString {
		const [hour, minute] = str.split(':').map((v) => parseInt(v, 10))

		if (Number.isNaN(hour)) {
			throw new Error(`Invalid hour: '${str.split(':')[0]}' is not a number`)
		}

		if (Number.isNaN(minute)) {
			throw new Error(`Invalid minute: '${str.split(':')[1]}' is not a number`)
		}

		if (hour < 0 || hour > 23) {
			throw new Error('Invalid hour: out of range')
		}

		if (minute < 0 || minute > 59) {
			throw new Error('Invalid minute: out of range')
		}
	}

	static normalise(str: string): HourString {
		let [hour, minute] = str.split(':')

		if (hour.length === 1) {
			hour = `0${hour}`
		}

		if (minute.length === 1) {
			minute = `0${minute}`
		}

		return `${hour}:${minute}` as HourString
	}

	[Symbol.toPrimitive](hint: 'number' | 'string' | 'default') {
		if (hint === 'number') {
			return this.toNumber()
		}

		return this.hour
	}

	public toNumber(): number {
		const [hour, minute] = this.hour.split(':')
		return parseInt(hour, 10) * 60 + parseInt(minute, 10)
	}

	public toString(): HourString {
		return this.hour
	}
}
