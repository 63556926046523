import type React from 'react'
import { useEffect } from 'react'
import { isMobile } from 'utils/utils'

/**
 * Custom hook to ensure input focus behavior works properly on mobile devices.
 * @param {React.RefObject<HTMLInputElement>} inputRef - A ref pointing to the input element.
 * @param {boolean} extraValidations - Additional conditions to validate before focusing the input.
 */
export const useFocusOnMobile = (inputRef: React.RefObject<HTMLInputElement>, extraValidations = true) => {
	useEffect(() => {
		if (!inputRef?.current || !isMobile() || !extraValidations) {
			return
		}

		// Create a temporary input to handle mobile focus quirks
		const createAndFocusFakeInput = () => {
			const fakeInput = document.createElement('input')
			fakeInput.type = 'text'
			fakeInput.style.position = 'absolute'
			fakeInput.style.opacity = '0'
			fakeInput.style.height = '0'
			fakeInput.style.fontSize = '16px' // Prevents iOS zoom issue
			document.body.prepend(fakeInput)
			fakeInput.focus()
			return fakeInput
		}

		const fakeInput = createAndFocusFakeInput()

		const inputFocusTimer = setTimeout(() => {
			inputRef.current?.focus()
			fakeInput?.remove()
		}, 100)

		return () => {
			clearTimeout(inputFocusTimer)
			fakeInput?.remove()
		}
	}, [inputRef, extraValidations])
}
