import Hour from './hour'
import type { Range } from './types'

export default class SmartRange {
	public ranges: Range[] = []

	public constructor(range?: Range) {
		this.ranges = []

		if (range) {
			SmartRange.validateRange(range)
			this.ranges.push(range)
		}
	}

	public static validateRange(range: Range): void {
		if (range.start.toNumber() >= range.end.toNumber()) {
			throw new Error('Invalid range: end must be after start')
		}
	}

	public equals(other: SmartRange): boolean {
		if (this.ranges.length !== other.ranges.length) {
			return false
		}

		return this.ranges.every((range, index) => {
			const otherRange = other.ranges[index]
			return range.start.toString() === otherRange.start.toString() && range.end.toString() === otherRange.end.toString()
		})
	}

	public add(range: Range): void {
		SmartRange.validateRange(range)

		const existing = this.ranges
			.filter((r) => range.start <= r.end && range.end >= r.start)
			.sort((a, b) => a.start.toNumber() - b.start.toNumber())

		if (!existing.length) {
			this.ranges.push(range)
			this.ranges.sort((a, b) => a.start.toNumber() - b.start.toNumber())
			return
		}

		const [first] = existing
		const last = existing.at(-1)!

		const result: Range = {
			start: new Hour(range.start > first.start ? first.start.toString() : range.start.toString()),
			end: new Hour(range.end < last.end ? last.end.toString() : range.end.toString()),
		}

		this.ranges = [...this.ranges.filter((r) => !existing.includes(r)), result].sort((a, b) => a.start.toNumber() - b.start.toNumber())
	}

	public remove(range: Range): void {
		SmartRange.validateRange(range)

		const newRanges: Range[] = []

		for (const existing of this.ranges) {
			if (range.end <= existing.start || range.start >= existing.end) {
				// No overlap, keep existing range unchanged
				newRanges.push(existing)
			} else if (range.start <= existing.start && range.end >= existing.end) {
				// Existing range is completely removed
				// eslint-disable-next-line no-continue
				continue
			} else if (range.start > existing.start && range.end < existing.end) {
				// Existing range is split into two
				newRanges.push({
					start: existing.start,
					end: new Hour(range.start.toString()),
				})
				newRanges.push({
					start: new Hour(range.end.toString()),
					end: existing.end,
				})
			} else if (range.start <= existing.start && range.end < existing.end) {
				// Remove from start of existing range
				newRanges.push({
					start: new Hour(range.end.toString()),
					end: existing.end,
				})
			} else if (range.start > existing.start && range.end >= existing.end) {
				// Remove from end of existing range
				newRanges.push({
					start: existing.start,
					end: new Hour(range.start.toString()),
				})
			}
		}

		this.ranges = newRanges.sort((a, b) => a.start.toNumber() - b.start.toNumber())
	}

	public isEmpty(): boolean {
		return this.ranges.length === 0 || this.ranges.every((range) => range.end.toNumber() - range.start.toNumber() === 0)
	}

	public toString(): string {
		return this.ranges.map((range) => `${range.start.toString()} - ${range.end.toString()}`).join(' • ')
	}
}
