// @ts-nocheck
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import {
	isInscription,
	isIPhoneX,
	getLocaleStr,
	codeToLocale,
	getDefaultQuantitySelections,
	getTranslatedTextByKey,
	formatTitle,
	findTitle,
} from '../../../utils/utils'
import Comments from '../../common/Comments'
import { StoreContext } from '../../../contexts/StoreContext'
import classnames from 'classnames'
import nestedProperty from 'nested-property'
import styled from 'styled-components/macro'
import TypographyPro from '../../../themes/TypographyPro'
import ItemQuantities from './ItemQuantities'
import ItemQuantitiesForNewDataFormat from './ItemQuantitiesForNewDataFormat'
import MultiSelect from './MultiSelect'
import SingleSelect from './SingleSelect'
import { useStores } from 'hooks/useStores'
import type _Infra from 'mobx/Infra'
import type _ItemAdditions from 'mobx/ItemAdditions'
import type _User from 'mobx/User'

const Container = styled.div`
	&.menuItemPageSections {
		&.root {
			width: 100%;
			margin-bottom: 20px;
		}
	}

	&.menuItemPageSection {
		&.root {
			padding: 0px 22px 20px 22px;
			&.hiddenParent {
				padding: 20px 0 0 0;
			}
		}
		&.hasParentAccordion {
			border: none;
			margin-top: 10px;
			border-left-width: 2px;
			border-left-style: solid;
			padding-top: 0;
			padding-right: 0;
			margin-left: 10px;

			&.parentIsMultiSelect {
				margin-bottom: 10px;
			}
		}
		&.quantityGridRoot {
			flex-grow: 1;
		}
		&.details {
			display: flex;
			justify-content: flex-start;
			flex-wrap: wrap;
		}
	}
`

interface MenuItemPageSectionsProps {
	Infra: typeof _Infra
	ItemAdditions: typeof _ItemAdditions
	User: typeof _User
	itemId: string
	visibleVariationCounter: number
}

const MenuItemPageSections = inject(
	'Infra',
	'ItemAdditions',
	'User'
)(
	observer((props: MenuItemPageSectionsProps) => {
		const { cartStore } = useStores()
		const { store } = useContext(StoreContext)
		const Restaurant = store.data
		const { ItemAdditions, User, itemId, visibleVariationCounter, Infra } = props
		const item = Restaurant.items[itemId]
		const locale = User.preferredLanguage ? codeToLocale[User.preferredLanguage] : Restaurant.locale
		let currentVisibleVariationCounter = visibleVariationCounter

		// an incrementing counter for every MIPSs
		let accordionCounter = -1

		// console.log(`***** props.childIndex: ${props.childIndex}`)

		let mainContent = null
		try {
			mainContent = item.variations?.map((_originalVariation, _childIndex) => {
				// NB when selecting eg Chips, a sub-variant opens showing size of Chips that can be selected so the title is 'Chips > Size'
				// this param is passed as the parentTitle prop to MenuItemPageSections via MenuItemPageSection
				// const parentTitle = props.parentTitle ? `${props.parentTitle} > ` : ''
				// const title = parentTitle + _variation.title[locale]

				/* clone the variation since we alter it on occasion eg maxNumAllowed is changed to item length below.
                        This breaks the re-order which does a deep comparison and if eg maxNumAllowed on variation A is
                        different then the re-order is not allowed for this item! */
				const _variation = JSON.parse(JSON.stringify(_originalVariation))

				if (_variation.displayType === 'hidden') {
					// don't render any component
					return null
				}

				const _variationTitle = getLocaleStr(_variation.title, locale)

				if (isInscription(_variationTitle)) {
					return <Comments key={_variationTitle} itemId={_variation.itemIds && _variation.itemIds[0]} commentOwner={ItemAdditions} />
				}

				accordionCounter++
				const _index = props.parentIndex === undefined ? accordionCounter : props.parentIndex
				let keys = props.keys ? `${props.keys}.` : ''
				// const _idCountKey = `${itemId}_${_index}`

				if (_variation.hideVariation) {
					// add this item to the ItemAdditions so it appears on the cart summary and in the bill
					const [_variationItemId] = _variation.defaults
					const _variationItem = Restaurant.items[_variationItemId]

					if (_variationItem) {
						const itemMap = {
							id: _variationItem.id,
							en_ID: _variationItem.description?.en_ID,
							name: getLocaleStr(_variationItem.title, locale),
							price: (_variation.prices && _variation.prices[_variationItem.id]) || 0, // some items don't have a price since they are free
							hasVariations: !!_variationItem.variations,
							autoSelectedAndHidden: true,
							isVariation: !!props.parentTitle,
							groupHasDefaults: true, // !!props.defaults && Array.isArray(props.defaults) && props.defaults.length > 0, // if this chip belongs to a set of chips that has a default then cannot de-select the chip
						}

						keys += `${itemId}.variationsChoices.${_childIndex}`

						// NB only for non-QS variations
						// for TT_6838 to solve QS being ignored on edit
						if (cartStore.itemModalEditMode) {
							// a) for edit, check if the default variation has already been added to the MobX
							const pathIsValid = nestedProperty.has(ItemAdditions.additionsNew, keys)
							if (pathIsValid) {
								// this default variation has already been added so do nothing here
								console.warn('This default variation has already been added so not adding it again')
							} else {
								// new default variation for the edited parent variation, so add the default variation to the MobX
								ItemAdditions.addAdditionNew(keys, itemMap)
							}
						} else {
							// b) for add, always add the variation to the MobX
							ItemAdditions.addAdditionNew(keys, itemMap)
						}

						if (_variationItem.variations?.length > 0) {
							// the item has a variation so check if the variation should be rendered via recursion

							const [_optionId] = _variation.itemIds

							// NB parentTitle param of any string value is needed for the correct indentation and TreeBox styling

							// if this variation is hidden then provide its title below so it can be added to the child variation's title
							// NB the variation's 'title' will be used if the useOptionTitle param is set, otherwise the child variation's title will be used

							return (
								<div key={keys}>
									<MenuItemPageSections
										itemId={_optionId}
										parentIndex={_index}
										keys={keys}
										hiddenParentTitle={_variation.title?.useOptionTitle ? _variationTitle : _variationItem.title[locale]}
										parentTitle={_variation.title?.useOptionTitle ? null : 'branch from me'}
										visibleVariationCounter={currentVisibleVariationCounter}
									/>
								</div>
							)
						}

						return null
					}
					return null
				}

				if (
					_variation.minNumAllowed === 0 &&
					_variation.maxNumAllowed > 0 &&
					_variation.defaults?.length === 0 &&
					_variation.itemIds?.length === 0
				) {
					// I saw this from PH peru - Duo Familiar item. The variation has no defaults and no itemIds! It seems meaningless
					return null
				}

				let subTitle = null

				const quantitySelection = !!_variation.title.quantityselection

				const options = []

				_variation.itemIds.forEach((_itemId) => {
					if (Restaurant.items[_itemId]) {
						options.push(Restaurant.items[_itemId])
					}
				})

				let defaultQuantitiesPerItem = null

				/**
				 * "newDataFormatDefaultQuantitiesPerItem" variable created to cover a new data structure provided by Daniel
				 * You can take a look on TT-5264 to see the new structure
				 */
				let newDataFormatDefaultQuantitiesPerItem
				let isNewQuantity = false
				let requiredVariation = !!(_variation.title.minimumAmountRequired > 0 || _variation.minNumAllowed > 0)

				if (quantitySelection) {
					newDataFormatDefaultQuantitiesPerItem = _variation.title.minimumAmountRequired != null
					// commented this so it never uses the new format
					// urgent fix peru
					if (newDataFormatDefaultQuantitiesPerItem) {
						// if (false) {
						isNewQuantity = true
						newDataFormatDefaultQuantitiesPerItem = _variation.title.minimumAmountRequired
						if (
							newDataFormatDefaultQuantitiesPerItem > 0 &&
							_variation.title.maximumAmountRequired === newDataFormatDefaultQuantitiesPerItem
						) {
							subTitle = `${getTranslatedTextByKey('selectExactly')} ${newDataFormatDefaultQuantitiesPerItem} ${getTranslatedTextByKey(
								'items'
							)}`
						} else {
							subTitle = `${getTranslatedTextByKey('selectUpTo')} ${_variation.title.maximumAmountRequired} ${getTranslatedTextByKey(
								'items'
							)}`
						}
					} else {
						defaultQuantitiesPerItem = getDefaultQuantitySelections(options)

						if (defaultQuantitiesPerItem.requiredTotal > 0) {
							// required QS so use - ect exactly X items
							subTitle = `${getTranslatedTextByKey('selectExactly')} ${defaultQuantitiesPerItem.requiredTotal} ${getTranslatedTextByKey(
								'items'
							)}`
						} else {
							// optional QS so use - Select up to X items
							requiredVariation = false
							const max = Restaurant.items[_variation.itemIds[_variation.itemIds.length - 1]]?.variations[0].itemIds.length ?? 0 - 1
							subTitle = `${getTranslatedTextByKey('selectUpTo')} ${max} ${getTranslatedTextByKey('items')}`
						}
					}
				} else if (_variation.minNumAllowed === 1 && _variation.maxNumAllowed === 1) {
					// Select an item
					subTitle = getTranslatedTextByKey('selectAnItem')
				} else if (_variation.minNumAllowed === 0) {
					// Select up to X items
					if (_variation.maxNumAllowed > _variation.itemIds.length) {
						_variation.maxNumAllowed = _variation.itemIds.length
						console.log(
							`- item '${_variationTitle}' maxNumAllowed > no. of items so settings it to no. of items! This could be a menu config issue and the max should be set to the numner of items?`
						)
					}
					subTitle = `${getTranslatedTextByKey('selectUpTo')} ${_variation.maxNumAllowed} ${getTranslatedTextByKey('items')}`
				} else if (_variation.minNumAllowed === _variation.maxNumAllowed) {
					// Select exactly X items
					subTitle = `${getTranslatedTextByKey('selectExactly')} ${_variation.minNumAllowed} ${getTranslatedTextByKey('items')}`
				} else {
					// Select X - Y items
					subTitle = `${getTranslatedTextByKey('selectBetween')} ${_variation.minNumAllowed} - ${
						_variation.maxNumAllowed
					} ${getTranslatedTextByKey('items')}`
				}

				// accordionCounter++

				const _key = `${itemId}_${accordionCounter}`

				keys += `${itemId}.variationsChoices.${_childIndex}`
				// keys += `${itemId}.variationsChoices.${accordionCounter}`

				// console.log(`1. props.parentTitle: ${props.parentTitle}`)

				// const itemTitle = getLocaleStr(item.title, locale)

				// increment the counter when we actually show something in the UI
				currentVisibleVariationCounter++

				// Hide plus sign for item at first section/variation level is user checked it in Dashboard
				const isPlusIconHidden = _childIndex === 0 && props.parentIndex === undefined && item.description?.hidePricePlusIcon

				return (
					<MenuItemPageSection
						hasParent={!!props.parentTitle}
						parentType={props.parentType}
						hiddenParent={!!props.hiddenParentTitle}
						key={_key}
						childIndex={_childIndex}
						keys={keys}
						title={props.hiddenParentTitle ? `${props.hiddenParentTitle} > ${_variationTitle}` : _variationTitle}
						subTitle={subTitle}
						options={options}
						prices={_variation.prices}
						variation={_variation}
						isNewQuantity={isNewQuantity}
						type={_variation.displayType}
						requiredVariation={requiredVariation}
						maxNumAllowed={isNewQuantity ? _variation?.title?.maximumAmountRequired : _variation.maxNumAllowed}
						minNumAllowed={isNewQuantity ? _variation?.title?.minimumAmountRequired : _variation.minNumAllowed}
						currency={Restaurant.currency}
						quantitySelection={quantitySelection}
						defaults={_variation.defaults}
						optionId={itemId}
						index={_index}
						/* childIndex={_idCountSet[_idCountKey]} */
						multiSelect={_variation.maxNumAllowed > 1}
						User={User}
						ItemAdditions={ItemAdditions}
						visibleVariationCounter={currentVisibleVariationCounter + _childIndex}
						defaultQuantitiesPerItem={isNewQuantity ? newDataFormatDefaultQuantitiesPerItem : defaultQuantitiesPerItem}
						isPlusIconHidden={isPlusIconHidden}
					/>
				)
			})
		} catch (e: unknown) {
			console.error('possible error from broken menu', e)
			Infra.setNotification({
				open: true,
				message: getTranslatedTextByKey('eCommerce.menuItem.currentlyItemNotAvailable', 'Currently item not available'),
				onClose: () => {
					Infra.closeNotification()
				},
				okAction: () => {
					cartStore.closeItemModal()
					Infra.closeNotification()
				},
			})
			return null
		}

		return props.hiddenParentTitle ? (
			mainContent
		) : (
			<div
				my-attr="xyz"
				className={classnames('menuItemPageSections root', isIPhoneX() ? 'iphoneX' : '')}
				data-testid="item-sections-container"
			>
				{mainContent}
			</div>
		)
	})
)

// export default React.memo(MenuItemPageSections, compare)
export default MenuItemPageSections

/// ///////////////////////////////////////////
/// /

const StyledTypographyTitle = styled(TypographyPro)`
	color: var(--footerAndDarkBackgrounds);
	line-height: ${({ $hasParent }) => ($hasParent ? '20px' : '24px')};
	margin-bottom: 5px;
	font-size: ${({ $hasParent }) => ($hasParent ? '15px' : '18px')};

	@media (min-width: 1280px) {
		font-size: ${({ $hasParent }) => ($hasParent ? '20px' : '23px')};
		line-height: ${({ $hasParent }) => ($hasParent ? '27px' : '31px')};
	}

	.invalid & {
		&.required {
			color: var(--err);
		}
	}
`

const StyledTypographyStatus = styled(TypographyPro)`
	color: var(--disable);
	text-transform: uppercase;

	&.required {
		color: var(--approved);
	}

	.invalid & {
		&.required {
			color: var(--err);
		}
	}
`

const TitleBox = styled.div`
	display: flex;
	justify-content: space-between;
`

const BorderFiller = styled.div`
	width: 14px;
`

const TreeBranch = styled.div`
	height: 30px;
	width: 14px;
	border-bottom: 1px solid #d4d0d0;
	border-bottom-left-radius: 5px;

	@media (min-width: 1280px) {
		height: 31px;
	}
`

const TreeContent = styled.div`
	flex: none;
	width: calc(100% - 28px);
	margin-top: 17px;
	padding-left: 5px;
`

/**
 * There could be nested TreeBox components eg in Tacobell NZ 'Double Taco Supreme Combo'. The '& > ${BorderFiller}'
 * syntax below signifies that the rule is checked for the BorderFiller against it's immediate ancestor ie the TreeBox.
 * This means it won't check if it's the last BorderFiller against any outer TreeBox (this was a bug).
 *
 * @type {*|P.div.constructor}
 */
const TreeBox = styled.div`
	display: -webkit-box;
	width: 100%;
	background-color: #F3F3F3;

	&:not(:last-child) {
		& > ${BorderFiller} {
			border-right: 1px solid #adacac;
		}

	}

	&:last-child {
		& > ${BorderFiller} {
			width: 13px;
		}

		& > ${TreeBranch} {
			border-left: 1px solid #adacac;
			width: 15px;
		}

	}
  }
`

const StyledSubTitle = styled(TypographyPro)`
	line-height: 12px;
	color: var(--inactive);
	margin-bottom: 4px;

	@media (min-width: 1280px) {
		line-height: 19px;
	}

	.invalid & {
		&.required {
			color: var(--err);
		}
	}
`

const MenuItemPageSection = (props) => {
	const { cartStore } = useStores()
	const { User, ItemAdditions } = props
	const { store } = useContext(StoreContext)
	const rest = store.data
	const locale = User.preferredLanguage ? codeToLocale[User.preferredLanguage] : rest.locale

	const [state, setState] = useState({
		itemsByIdMap: {},
		groupChipState: {}, // selected state of all chips in this Accordion
		chipVariations: {}, // state for variations for a selected chip
		init: false,
	})

	// runs once on mount and onunmout due to 2nd param []
	// since there's componentWillMount effect equivalent, I reset the ItemAdditions store on unmounting this component
	// see https://stackoverflow.com/questions/53464595/how-to-use-componentwillmount-in-react-hooks
	useEffect(() => {
		// console.log(`MIPS index ${props.index}, title: ${props.title}, mounted...`)
		const initGroupChipState = {}
		const initItemsByIdMap = {}
		const initChipVariations = {}

		// props.options = eg the 3 options for 'modify twister' are 'no tomato', 'no garnish', 'no sauce'
		// for this MenuItemPageSection check if the options are part of a group and if there is a default selection
		props.options.forEach((_option, _optionIdx) => {
			// if the option is a quantity-selection, then its defaults are taken from a sub-level down.
			// if the option is for chips, then take the defaults from props.defaults
			let defaultArray = props.quantitySelection ? [] : props.defaults

			const _hasDefaults = (!!props.defaults && Array.isArray(props.defaults) && props.defaults.length > 0) || props.minNumAllowed

			initItemsByIdMap[_option.id] = {
				id: _option.id,
				en_ID: _option.description?.en_ID,
				name: getLocaleStr(_option.title, rest.locale),
				price: (props.prices && props.prices[_option.id]) || 0, // some items don't have a price since they are free
				hasVariations: !!_option.variations,
				autoSelectedAndHidden: false,
				isVariation: props.hasParent,
				groupHasDefaults: _hasDefaults, // !!props.defaults && Array.isArray(props.defaults) && props.defaults.length > 0, // if this chip belongs to a set of chips that has a default then cannot de-select the chip
				minNumAllowed: props.minNumAllowed,
				maxNumAllowed: props.maxNumAllowed,
			}

			let _itemCartAdditionsMap = null

			// NB State for a Quantity Selection item is managed in ItemQuantities.jsx, NOT here
			if (cartStore.itemModalEditMode && !props.quantitySelection) {
				// const optionKey = `${props.optionId}_${props.index}`

				// NB for editing, the Cart's item additions are detached when loaded via ItemAdditions.postInit via JSON parse and stringify
				// so updating the items in the edit popup won't update the Cart's price or trigger a re-render of the CartSummaryRow
				// _itemCartAdditionsMap = ItemAdditions.additions[optionKey]

				// const optionKeyNew = `${ItemAdditions.itemId}.variationsChoices.${_optionIdx}.${props.keys}`
				const optionKeyNew = `${props.keys}`
				/*				console.log('******* edit new json ********')
				console.log(toJS(ItemAdditions.additionsNew))

				console.log('******* edit old json ********')
				console.log(toJS(ItemAdditions.additions))

				console.log('******* edit old map ********')
				console.log(toJS(_itemCartAdditionsMap)) */

				if (nestedProperty.has(ItemAdditions.additionsNew, optionKeyNew)) {
					// const _itemCartAdditionsNewMap = nestedProperty.get(ItemAdditions.additionsNew, optionKeyNew)
					_itemCartAdditionsMap = nestedProperty.get(ItemAdditions.additionsNew, optionKeyNew)
					// console.log('****** edit new map ****')
					// console.log(toJS(_itemCartAdditionsNewMap))
				} else {
					console.log(`***** MenuItemPageSection Item doesn't contain the key: '${optionKeyNew}'`)
				}

				// if the option has a selection then the value is an array of the selections/
				// if the option has no selections then the value is undefined.
				// some options don't have mandatory selections so check if this addition has any selection
				if (_itemCartAdditionsMap) {
					console.log(`editing item: ${cartStore.modalItemId} - addition: ${JSON.stringify(_itemCartAdditionsMap)}`)

					// since editing an item, change the default mandatory selection for this addition to the one chosen by the user
					defaultArray = Object.keys(_itemCartAdditionsMap).map((_additionId, _idx) => _additionId)

					// editing
					initGroupChipState[_option.id] = defaultArray.includes(_option.id)

					if (initGroupChipState[_option.id]) {
						// check if this selected addition has variants and set their selection as well (eg chips > regular or large)
						if (_option.variations && _option.variations[0] && _option.variations[0].itemIds) {
							// the index starts from zero since this accordion was rendered from a new call to the prent MenuItemPageSecions component
							const variantKey = `${_option.id}_${props.index}`

							// const _variantAdditionsMap = Cart.items[Cart.menuItemId][Cart.editCartItemIdx].additions[variantKey]

							// get all of the selected variantIds and add their chips to the chipVariations
							Object.keys(_itemCartAdditionsMap).forEach((_variantId, _idx) => {
								const _chip = props.options.find((item) => item.id === _variantId)
								if (_chip && _chip.variations) {
									// only add a chip that has variations to the var below
									initChipVariations[_variantId] = _chip
								}
							})
						}
					}
				}
			} else {
				// todo ezra: good candidate 1, initialize the required object here
				// not editing
			}

			if (!cartStore.itemModalEditMode || !_itemCartAdditionsMap) {
				let _selected = false

				if (props.defaults && props.defaults.length > 0) {
					_selected = Array.isArray(props.defaults) && defaultArray.includes(_option.id)

					if (props.requiredVariation) {
						// this QS has a required minimum and since the user can use the '-' to decrease the default
						// selected quantity, we need to keep track of the total quantity of ALL items selected.
						// We will show the validation warning if eg there is a required default of 16 items and the user
						// has selected < 16.
						// should it handle 0 ?
						// urgent fix peru
						if (props.defaultQuantitiesPerItem?.requiredTotal) {
							ItemAdditions.addRequiredSelection(
								props.keys,
								props.defaultQuantitiesPerItem.requiredTotal,
								// consider under this to use the real max also // urgent fix peru
								props.defaultQuantitiesPerItem.requiredTotal,
								props.quantitySelection
							)
						} else if (props.quantitySelection) {
							ItemAdditions.addRequiredSelection(
								props.keys,
								props.minNumAllowed,
								props.maxNumAllowed, // urgent fix peru
								props.quantitySelection
							)
						}
					}
				} else if (props.minNumAllowed && props.minNumAllowed > _optionIdx) {
					// instead of having to validate that the user has selected at least one option when there are no defaults
					// I artificially create as many defaults as needed to meet the props.minNumAllowed
					// _selected = true

					// NB if a quantity selection is required then there will be a default selected for it so no need to add it here
					ItemAdditions.addRequiredSelection(props.keys, props.minNumAllowed, props.maxNumAllowed, false) // the variation parent's id
				}

				initGroupChipState[_option.id] = _selected

				if (_selected && _option.variations && _option.variations.length > 0) {
					initChipVariations[_option.id] = _option
				}

				if (_selected) {
					ItemAdditions.addAdditionNew(props.keys, initItemsByIdMap[_option.id])
				}
			}
		})

		// for edit only
		setState({
			itemsByIdMap: initItemsByIdMap,
			groupChipState: initGroupChipState,
			chipVariations: initChipVariations,
			init: true,
		})
	}, [])

	// NB this method will be recreated per render of MenuItemPageSection and passed to the child components which will cause them to re-render.
	// wrapping it in useCallback won't work since there's a param of optionId which mayu be different on each call! So
	// wrapping the child in Memo won't work and will only cause a bug in the rendering of the child's state since this
	// method will have the wrong state! See https://github.com/facebook/react/issues/14972

	// keep track of chip state for this Accordion, if 'choice' - mutually excusive, if diff can have multiple select up to a maximum
	const handleSelectionChange = (optionId) => {
		// console.log(`id: ${optionId}`)

		// 1. manage state of chips
		const copy = JSON.parse(JSON.stringify(state.groupChipState))

		/* if (state.itemsByIdMap[optionId].groupHasDefaults && state.groupChipState[optionId] && state.itemsByIdMap[optionId].minNumAllowed > 0) {
			// cannot deselect a chip that belongs to a group of chips that must have at least 1 selected
			console.log(`cannot deselect chip: ${optionId} since it belongs to a chip-set that needs at least 1 selected`)
		} else { */
		if (props.type === 'choice') {
			const numSelected = Object.keys(copy).filter((_key) => copy[_key] === true).length

			if (copy[optionId] && numSelected === props.minNumAllowed) {
				console.log(`cannot de-select this option since there is a min. of ${props.minNumAllowed} selected options`)
			} else {
				Object.keys(copy).forEach((_key) => {
					// set the unique choice to true/false (the opposite of what it is) and all the others to false
					copy[_key] = optionId === _key ? (copy[_key] = !copy[_key]) : false

					// 1. b) update this item's state with this selection
					if (copy[_key]) {
						ItemAdditions.addAdditionNew(props.keys, state.itemsByIdMap[_key])
					} else {
						ItemAdditions.removeAdditionNew(props.keys, _key)
					}
				})
			}
		} else if (props.type === 'diff') {
			// can select from minNumAllowed up to and including maxNumAllowed eg select 0-2 items

			const numSelected = Object.keys(copy).filter((_key) => copy[_key] === true).length
			const newOptionState = !JSON.parse(JSON.stringify(copy[optionId]))

			if (newOptionState) {
				// is selected
				if (numSelected === props.maxNumAllowed) {
					for (const _key in copy) {
						if (copy[_key]) {
							copy[_key] = false
							console.log(
								`unselected key: '${_key}' to allow for the new key '${optionId}' to be selected to not have more than ${props.maxNumAllowed} items selected `
							)
							// ItemAdditions.removeAddition(props.optionId, props.index, _key)
							ItemAdditions.removeAdditionNew(props.keys, _key)
							break
						}
					}
				}

				// a) delete the entry from the map
				delete copy[optionId]

				// b) set ONLY this selected/unselected chip true/false AND c) add the entry so it will be found last when looping over the map
				// this provides a last-in-last-out behaviour so the user can select what they want
				copy[optionId] = newOptionState
				ItemAdditions.addAdditionNew(props.keys, state.itemsByIdMap[optionId])
			} else {
				// is unselected
				if (numSelected === props.minNumAllowed) {
					console.log(`cannot unselect key: '${optionId}' since there's a min selection of ${props.minNumAllowed}`)
				} else {
					copy[optionId] = newOptionState
					ItemAdditions.removeAdditionNew(props.keys, optionId)
				}
			}
		} else {
			console.error(`unknown props.type of ${props.type}`)
		}

		// 2. check if the selected/unselected chips have variations and so need to show a separate Accordion for them or remove the Accordion
		const currentChipVariations = JSON.parse(JSON.stringify(state.chipVariations))

		props.options.forEach((_chip) => {
			if (_chip.variations && _chip.variations.length > 0) {
				if (copy[_chip.id]) {
					currentChipVariations[_chip.id] = _chip
				} else {
					delete currentChipVariations[_chip.id]

					// 2. b) remove the variations from the total cost of this item
					_chip.variations.forEach((_variation) => {
						_variation.itemIds.forEach((_itemId, _variationIdx) => {
							ItemAdditions.removeAdditionNew(props.keys, _itemId)
						})
					})
				}
			}
		})

		const _stateClone = JSON.parse(JSON.stringify(state))
		_stateClone.chipVariations = currentChipVariations
		_stateClone.groupChipState = copy
		setState(_stateClone)
		// }
	}

	if (!state.init) {
		return null
	}

	const sectionStatus = props.requiredVariation ? 'required' : 'optional'

	const mainContent = (
		<>
			{/* section-header */}
			<div my-attr="def" aria-controls={`panel${props.title}d-content`} id={props.keys}>
				<TitleBox>
					<StyledTypographyTitle
						$hasParent={props.hasParent}
						variant="h4"
						className={sectionStatus}
						data-testid="menu-item-page-section-title"
					>
						{props.title}
					</StyledTypographyTitle>
					<StyledTypographyStatus variant="BodyRegular" className={sectionStatus} data-testid={`menu-item-dialog-${sectionStatus}-section`}>
						{props.requiredVariation ? getTranslatedTextByKey('webviewFlow.required') : getTranslatedTextByKey('webviewFlow.optional')}
					</StyledTypographyStatus>
				</TitleBox>

				{props.subTitle && (
					<StyledSubTitle component="div" variant="BodySmall" className={sectionStatus}>
						{formatTitle(props.subTitle, true)}
					</StyledSubTitle>
				)}
			</div>

			{/* section-details */}
			<Container
				className={classnames(`menuItemPageSection details`, props.hasParent ? 'hasParentDetails' : ``)}
				data-testid="select-options-container"
				/* style={{ marginBottom: !props.quantitySelection && !props.multiSelect ? '5px' : '0' }} */
			>
				{/* a) can select > 1 of the same option - // urgent fix peru */}
				{/* I commented this part to restore old version of quantity select since it's failing almost 100% of markets. */}
				{props.quantitySelection && !props.isNewQuantity && <ItemQuantities {...props} isRequired={props.requiredVariation} />}
				{props.quantitySelection && props.isNewQuantity && <ItemQuantitiesForNewDataFormat {...props} variation={props.variation} />}
				{/* {props.quantitySelection && <ItemQuantities {...props} isRequired={props.requiredVariation} />} */}

				{/* b) can select more than 1 option */}
				{!props.quantitySelection &&
					props.multiSelect &&
					props.options.map((_option, _idx) => (
						<Fragment key={`${_option.id}_${_idx}`}>
							<MultiSelect
								label={findTitle(_option.title, locale)}
								id={_option.id}
								price={(props.prices && props.prices[_option.id]) || 0}
								selected={state.groupChipState[_option.id]}
								handleSelectionChange={handleSelectionChange}
								currency={props.currency}
								isPlusIconHidden={props.isPlusIconHidden}
							/>
							{/* d) this option has variations */}
							{state.chipVariations[_option.id] && (
								<MenuItemPageSections
									keys={props.keys}
									itemId={_option.id}
									parentTitle={state.chipVariations[_option.id].title[locale]}
									parentIndex={props.index}
									parentType="parentIsMultiSelect"
									variationCounter={props.visibleVariationCounter + _idx}
								/>
							)}
						</Fragment>
					))}

				{/* c) can select only 1 option */}
				{!props.quantitySelection &&
					!props.multiSelect &&
					props.options.map((_option, _idx) => (
						<Fragment key={`${_option.id}_${_idx}`}>
							<SingleSelect
								label={findTitle(_option.title, locale)}
								id={_option.id}
								price={(props.prices && props.prices[_option.id]) || 0}
								selected={state.groupChipState[_option.id]}
								handleSelectionChange={handleSelectionChange}
								currency={props.currency}
								isPlusIconHidden={props.isPlusIconHidden}
							/>
						</Fragment>
					))}

				{/* d) for CHIPS only - this option has variations - now show them under the parent CHIP options */}
				{!props.quantitySelection &&
					!props.multiSelect &&
					state.chipVariations &&
					Object.keys(state.chipVariations).map((_optionId, _idx) => (
						<MenuItemPageSections
							keys={props.keys}
							itemId={_optionId}
							parentTitle={state.chipVariations[_optionId].title[locale]}
							parentIndex={props.index}
							key={`${_optionId}_${_idx}`}
							visibleVariationCounter={props.visibleVariationCounter + _idx}
						/>
					))}
			</Container>
		</>
	)

	return (props.hasParent && !props.hiddenParent) || (props.hasParent && props.hiddenParent && props.visibleVariationCounter > 0) ? (
		<TreeBox className={sectionStatus} data-testid="tree-container">
			<BorderFiller />
			<TreeBranch />
			<TreeContent>{mainContent}</TreeContent>
		</TreeBox>
	) : (
		<Container className={classnames('menuItemPageSection root', props.parentType || ``, sectionStatus)} data-testid="item-section-container">
			{mainContent}
		</Container>
	)
}

const compare2 = (prevProps, nextProps) => {
	// console.log(prevProps,nextProps)
	if (prevProps.optionId === nextProps.optionId) {
		console.log(`1. not rendering MenuItemPageSection: ${prevProps.optionId}`)
		return true
	}
	console.log(`2. rendering MenuItemPageSection: ${prevProps.optionId}`)
	return false
	// return (prevProps.location.pathname !== '/' && nextProps.location.pathname !== '/')
}

// export default React.memo(MenuItemPageSection, compare)
