import { GOOGLE_API_KEY } from '../constants'
import { Loader } from '@googlemaps/js-api-loader'

const loadGoogleMapsApi = async (apiKey: string) => {
	const loader = new Loader({
		apiKey: apiKey || GOOGLE_API_KEY,
	})

	return Promise.all([loader.importLibrary('maps'), loader.importLibrary('places')])
}

const getGoogleMapAPIObj = async (apiKey: string) => {
	await loadGoogleMapsApi(apiKey)
	return window.google.maps
}

export const geoCode = async (options: google.maps.GeocoderRequest, apiKey: string): Promise<google.maps.GeocoderResult> => {
	let googleMapsAPIObj = window.google?.maps
	if (!googleMapsAPIObj?.Geocoder) {
		googleMapsAPIObj = await getGoogleMapAPIObj(apiKey)
	}

	const geocoder = new googleMapsAPIObj.Geocoder()
	return new Promise((resolve) => {
		geocoder.geocode(options, (responses: any[]) => {
			resolve(responses?.[0])
		})
	})
}
