import Link from 'next/link'
import React from 'react'
import IconComponent from 'themes/IconComponent'

enum Variant {
	Whatsapp = 'whatsapp',
	PlayStore = 'playstore',
	AppStore = 'appstore',
}

interface Props {
	variant: Variant
	url: string
	target?: string
	onClick?: React.MouseEventHandler<HTMLAnchorElement>
}

const variantToIcon = (variant: Variant): JSX.Element => {
	switch (variant) {
		case Variant.Whatsapp:
			return <IconComponent asset="/icons/whatsapp-availability.svg" />
		case Variant.PlayStore:
			return <IconComponent asset="/icons/google-play-availability.svg" />
		case Variant.AppStore:
			return <IconComponent asset="/icons/appstore-availability.svg" />
		default:
			throw new Error(`Invalid variant: ${variant}`)
	}
}

const variantToTarget = (variant: Variant): string => {
	switch (variant) {
		case Variant.Whatsapp:
			return '_blank'
		case Variant.PlayStore:
			return 'android'
		case Variant.AppStore:
			return 'ios'
		default:
			throw new Error(`Invalid variant: ${variant}`)
	}
}

const OpenInChip: React.FC<Props> = ({ variant, url, target, onClick }) => (
	<Link onClick={onClick} href={url} target={target ?? variantToTarget(variant)}>
		{variantToIcon(variant)}
	</Link>
)

export default OpenInChip

export { Variant as OpenInVariant }
