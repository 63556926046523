import React, { useCallback } from 'react'
import styled from 'styled-components'
import TypographyPro from 'themes/TypographyPro'
import ButtonBase from 'components/common/ButtonBase'
import { getTranslatedTextByKey } from 'utils/utils'
import type { Filter } from 'mobx/Coupons/types'
import { CouponFilter } from 'mobx/Coupons/types'
import { mediaQueriesBreakpoints } from 'styles/mixins'
import { sendCustomEvent } from 'utils/analytics/analytics'

interface Props {
	filter: Filter
	setFilter: React.Dispatch<React.SetStateAction<Filter>>
	filterOrder: Filter[]
	ignoredFilters: Filter[]
	disabled: boolean
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;

	margin: 0 -0.62rem;

	@media ${mediaQueriesBreakpoints.xs} {
		margin: 0 -1.25rem 0;
	}

	@media ${mediaQueriesBreakpoints.md} {
		margin: 0 -2.5rem 0;
	}

	@media ${mediaQueriesBreakpoints.lg} {
		padding: 0;
		margin: 0;
	}
`

const TagList = styled.div`
	display: flex;
	gap: 0.5rem;

	@media ${mediaQueriesBreakpoints.xs} {
		padding: 0 1.25rem;
	}

	@media ${mediaQueriesBreakpoints.md} {
		padding: 0 2.5rem;
	}

	@media ${mediaQueriesBreakpoints.lg} {
		padding: 0;
		margin: 0;
	}

	width: max-content;
`

const Title = styled(TypographyPro).attrs({ variant: 'SubheadHighlighted' })`
	@media ${mediaQueriesBreakpoints.xs} {
		padding: 0 1.25rem;
	}

	@media ${mediaQueriesBreakpoints.md} {
		padding: 0 2.5rem;
	}

	@media ${mediaQueriesBreakpoints.lg} {
		padding: 0;
		margin: 0;
	}

	color: var(--strokeMenuTitle);
`

const Text = styled(TypographyPro).attrs({ variant: 'Subhead' })``

const Tag = styled(ButtonBase)<{
	selected: boolean
}>`
	width: fit-content;
	padding: 0;

	.MuiButton-label {
		padding: 0.25rem 0.75rem;
	}

	${({ selected, disabled }) =>
		`
			background-color: ${selected ? 'var(--primary)' : disabled ? 'var(--optionalBackground)' : 'var(--clear)'} !important;
			color: ${selected ? 'var(--clear)' : disabled ? 'var(--disable)' : 'var(--fonts)'} !important;
			border: 1px solid ${selected ? 'var(--primary)' : disabled ? 'var(--disable)' : 'var(--fonts)'} !important;
	`}
`

const TagListContainer = styled.div`
	overflow-x: auto;

	&::-webkit-scrollbar {
		display: none;
	}
`

export const filterToString: Record<Filter, string> = {
	[CouponFilter.ALL]: 'All',
	[CouponFilter.MY_COUPONS]: '♥︎ My Coupons',
	[CouponFilter.DELIVERY]: 'Delivery',
	[CouponFilter.PICKUP]: 'Pickup',
	[CouponFilter.IN_STORE]: 'In Store',
	[CouponFilter.DRIVETHROUGH]: 'Drive through',
}

const CouponsFilters = ({ filter, setFilter, filterOrder, disabled, ignoredFilters }: Props) => {
	const orderByType = useCallback((a: Filter, b: Filter) => filterOrder.indexOf(a) - filterOrder.indexOf(b), [filterOrder])

	const handleFilter = (type: Filter) => {
		setFilter(type)

		sendCustomEvent({
			category: 'filter_coupons',
			action: 'clicked',
			filter_type: type,
			page_path: window.location.pathname,
		})
	}

	return (
		<Container>
			<Title>{getTranslatedTextByKey('eCommerce.coupons.filter.title', 'Filter your coupons:')}</Title>
			<TagListContainer>
				<TagList>
					{Object.values(CouponFilter)
						.filter((f) => !ignoredFilters.includes(f))
						.sort(orderByType)
						.map((type) => (
							<Tag
								disabled={disabled}
								$type={filter === type ? 'primary' : 'secondary'}
								key={type}
								selected={filter === type}
								data-testid="coupon-tab"
								onClick={() => handleFilter(type)}
							>
								<Text data-testid={`${type}`}>
									{getTranslatedTextByKey(`eCommerce.coupons.filter.${type}`, filterToString[type])}
								</Text>
							</Tag>
						))}
				</TagList>
			</TagListContainer>
		</Container>
	)
}

export default CouponsFilters
