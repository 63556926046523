import { staticImplements } from 'utils/types'

import type _AddressManager from "mobx/AddressManager"
import { type AppParams } from 'mobx/Infra/Infra.type'

import type { AddressManagerV2Dependencies } from './types'

@staticImplements<AddressManagerV2Dependencies>()
export default class AddressManagerV2Repository {

    static addressManager: typeof _AddressManager | null = null

    static appParams: AppParams | null = null

    static newLocalizationFlowEnabled(): boolean {
        return this.appParams?.features?.localizationFlow === true
    }

    static setAppParams(appParams: AppParams): void {
		this.appParams = appParams
	}

    static setAddressManager(addressManagger: typeof _AddressManager): void {

        // New localization Feature Flag is OFF, AddressManager is available
        if(!this.newLocalizationFlowEnabled()) {
            this.addressManager = addressManagger
        }

        // New localization Feature Flag is ON, AddressManager is undefined
	}

    static getStoreId = () => localStorage.getItem('storeId')
}