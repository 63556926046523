// @ts-nocheck
import TagManager from 'react-gtm-module'

import Application from 'mobx/Application'
import Infra from 'mobx/Infra'
import { CONSTANTS } from 'utils/constants'

import * as ga4 from './ga4'
import * as ga4YumGlobal from './ga4YumGlobal'

const googleTagConsumers = []

const sendEvent = async (event) => {
	const resetDataLayer = !Infra?.appParams?.features?.removeNullEvents
	if (resetDataLayer) {
		// we are advised to 'reset' the dataLayer but this generates a 'Message' entry in the https://tagassistant.google.com/ page
		TagManager.dataLayer({
			dataLayer: {
				ecommerce: null,
				category: null,
				action: null,
				label: null,
				event: null,
				variations: null,
				postLocalisation: null,
				storeID: null,
				disposition: null,
				loginMethod: null,
				popupTitle: null,
				errorMessage: null,
				pageTitle: null,
				pageURL: null,
				item: null,
			},
		}) // Clear the previous ecommerce object
	}

	// send the real event
	if (Object.prototype.hasOwnProperty.call(event, 'dataLayer')) {
		TagManager.dataLayer({
			...event,
			dataLayer: {
				...event.dataLayer,
				session_channel: Application.isMobileApp ? CONSTANTS.CHANNEL_TYPE.APP.toLowerCase() : CONSTANTS.CHANNEL_TYPE.WEB.toLowerCase(),
			},
		})
	} else {
		TagManager.dataLayer(event)
	}
}

export async function init(params) {
	let gtmIds = new Set<string>()

	if (params?.analytics?.googleTagManager?.ids?.length && !params?.analytics?.googleTagManager?.ids.some((id) => id.indexOf('XXX') > -1)) {
		gtmIds = new Set(params?.analytics?.googleTagManager?.ids)
	}

	if (params?.analytics?.googleTagManager?.id?.indexOf('XXX') === -1) {
		gtmIds.add(params?.analytics?.googleTagManager?.id)
	}

	if (gtmIds.size > 0) {
		gtmIds.forEach((id) => {
			console.info(`init. Google Tag Manager with id: ${id}`)
			const googleTagManagerArgs = {
				gtmId: id,
			}
			TagManager.initialize(googleTagManagerArgs)
		})

		if (params.analytics.googleTagManager.consumers) {
			params.analytics.googleTagManager.consumers.map(async (_consumerName) => {
				switch (_consumerName) {
					case 'GA4': {
						ga4.init(TagManager, sendEvent)
						googleTagConsumers.push(ga4)
						break
					}
					case 'GA4_YUM_GLOBAL':
					case 'GA4_KFC_GLOBAL': {
						ga4YumGlobal.init(TagManager, sendEvent)
						googleTagConsumers.push(ga4YumGlobal)
						break
					}
					default: {
						console.warn(`unknown GTM event format: ${_consumerName}`)
					}
				}
			})
		} else {
			console.warn(`Missing 'analytics.googleTagManager.consumers' !`)
		}
	} else {
		console.info(`Missing Google Tag Manager Id or it's in the wrong format!`)
	}
}

export function sendEnhancedEcommerceEvent(eventType, ...args) {
	googleTagConsumers.map((_consumer) => {
		_consumer.triggerSendEvent(eventType, ...args)
	})
}

/**
 *
 * @param event = {
 * 		category: '',
 *		action: '',
 *		label: '',
 * }
 *
 * event is hard-coded to 'custom-event' as per advice from our (GTM expert)
 *
 */
export function sendCustomEvent(data) {
	googleTagConsumers.map((_consumer) => {
		_consumer.triggerSendEvent(CONSTANTS.ANALYTICS.OTHER_EVENTS.custom, data)
	})
}

export function sendPageViewEvent(data) {
	googleTagConsumers.map((_consumer) => {
		_consumer.triggerSendEvent(CONSTANTS.ANALYTICS.OTHER_EVENTS.pageView, data)
	})
}
