import { sendRequest } from 'utils/utils'
import { tenantUrls, TenantUrlPickOptions } from 'utils/api/storefrontUrls'

export const webFlowAddress = {
	getBranchesList: async ({
		formatted,
		lat,
		lng,
		chainId,
		lang,
		orderType,
		showLoader = true,
	}: {
		formatted: any
		lat: any
		lng: any
		chainId: any
		lang: any
		orderType: any
		showLoader: any
	}) => {
		const isAddressEmpty = !formatted && !lat && !lng
		const urls = await tenantUrls([TenantUrlPickOptions.WebFlowAddressURL])
		let requestUrl = urls.webFlowAddressURL

		if (isAddressEmpty) {
			requestUrl += `&lang=${lang}&type=getBranchesList&cust=openRest&noFilter=true`
		}
		const requestPayload = {
			chainId,
			type: 'getBranchesList',
			addr: isAddressEmpty ? {} : { formatted, lat, lng },
			cust: 'openRest',
			lang,
			orderType,
			noFilter: true,
		}

		return sendRequest(
			showLoader,
			requestUrl,
			isAddressEmpty ? 'get' : 'post',
			requestPayload,
			{
				'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
			},
			undefined,
			undefined,
			null,
			false
		)
	},
}
