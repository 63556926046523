import type MenuItem from 'types/MenuItem'
import type MenuSection from 'types/MenuSection'
import type MenuUpsells from './MenuUpsells'
import type CountryCode from './CountryCode'
import type { LanguageLocale } from 'utils/language'
import type { DeliveryInfo } from 'shared-types/Cart'

interface Address {
	city: string
	countryCode: CountryCode
	formatted: string
	latLng: { lat: number; lng: number }
	properties: Record<string, any>
}

type PaymentType = 'cash' | 'credit'

interface Menu {
	_id: string
	id: string
	buildId?: string
	createdAt?: string
	desc?: Record<string, string>
	name?: Record<string, string>
	phoneNumber?: string
	kitchen?: string
	address?: Address
	locales?: string[]
	locale?: LanguageLocale
	deliveryInfo?: DeliveryInfo[]
	openTimes?: any
	picture?: string
	noImagePicture?: string
	currency: string
	timezone?: string
	paymentTypes?: PaymentType[]
	creditcardsInfo?: any
	countryCode?: string
	multiPaymentDisabled?: boolean
	chainId?: any
	preOrderMins?: number
	orders?: any
	items: Record<string, MenuItem> // todo: check if we should use syntax {[key: string]: MenuItem}
	codesMap?: Record<string, string>
	upsells: MenuUpsells
	sections: MenuSection[]
	hiddenSections?: MenuSection[]
	menuWords?: string
	benefits?: any[]
	amountOfItems?: number
	noMenu?: boolean
	discounts?: any
	theme?: any
	openingHours?: any[]
}

function isMenuValid(input: unknown): input is Menu {
	if (typeof input !== 'object' || input === null || Array.isArray(input)) {
		return false
	}
	// todo
	return true
}

export { isMenuValid, type Menu }
