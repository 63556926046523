import type { Env } from 'types/Env'
import { areEnvironmentVariablesValid, DEV, STAGING, LOCAL, PROD, TICTUK_TESTS } from 'types/Env'
import type { NextPageContext } from 'next'
import { parse } from 'querystring'
import * as URL from 'url'
import type { LanguageCode } from 'utils/utils'
import { sendRequest, isRTL, buildTheme, fetchLanguageFile, safeStringify } from 'utils/utils'
import { getTheme, getThemeUrl } from 'utils/theme/themeManager'
import { THEME_STRATEGY } from 'constants/theme'
import muiComponentTheme from 'themes/mui-component-theme'
import type { IncomingMessage } from 'http'
import { type AppParams } from 'mobx/Infra/Infra.type'
import { isCustomerStaticPageValid } from 'types/CustomerStaticPage'
import { CONSTANTS } from '../src/utils/constants'
import { tenantUrls, TenantUrlPickOptions } from 'utils/api/storefrontUrls'

const psl = require('psl')

export const extractBaseDomain = (host: string, env: Env) => {
	// Default treatment for all hosts, we remove domain prefix 'www.'
	host = host.replace('www.', '')
	host = host.replace('kiosk.', '')

	if (host.includes('http')) {
		throw new Error('Domain includes http')
	}

	switch (true) {
		// On tests and demo env, we return the host name unchanged (subdomain + domain)
		case env === TICTUK_TESTS:
			return host

		// On local environment, we remove '.localhost' from domain
		// ex: 'pizzahut.de.localhost' --> 'pizzahut.de'
		case env === LOCAL && host.includes('.localhost'):
			return host.split('.localhost')[0]

		// On dev environment, we remove .xxx.lji.li from domain (where xxx is env name)
		// ex: 'kfc.com.mx.frontend.lji.li' --> 'kfc.com.mx'
		case (env === DEV || env === STAGING) && host.includes('.lji.li'):
			const splitHost = host.split('.')
			splitHost.splice(splitHost.length - 3, 3)
			host = splitHost.join('.')
			return psl.parse(host).domain || host

		// extract domain only (no subdomain) from cleaned host name
		// ex: 'orders.pizzahut.fi' --> 'pizzahut.fi'
		case env === PROD:
			if (host.includes('tictuk-demo.com')) {
				return host
			}
			return psl.parse(host).domain

		default:
			throw new Error(`Unexpected combination of env and host. ENV=${env}; host=${host}`)
	}
}

/**
 * Fetch the params data by calling ecommerce server endpoint.
 * Domain URL will be used to determine which params must be loaded for its store.
 */
const fetchParamsByDomain = async (host: string, env: Env) => {
	// TODO FOR NEXT: temp code to remove
	const extractedDomain = extractBaseDomain(host, env)

	if (!extractedDomain) {
		throw new Error('Missing a domain to fetch params from')
	}

	// DEV ONLY
	/* let extractedDomain = null
	if (host === 'dev.local.com:8080' || 'localhost:3000') {
		extractedDomain = 'pizzahut.de'
	} */

	const url = `${process.env.ECOMMERCE_SERVER}/v1/ecommerce-params?field=url&value=${extractedDomain}`
	return sendRequest(false, url, 'get', null, undefined)
}

/**
 * Get the web-app's params for nextJS context
 */
export const getAppParamsForBackend = async (host: string, env: Env): Promise<AppParams & { tictuk_listener?: string }> => {
	try {
		const _parsed = (await fetchParamsByDomain(host, env)) as { title: string; tictuk_listener: string; wru: string }
		// TODO FOR NEXT: add ZOD validation on params

		// Overload app params if url query string contains parameters
		// except for title and add tictuk_listener
		return {
			..._parsed,
			title: _parsed.title,
			tictuk_listener: _parsed.tictuk_listener || _parsed.wru,
		} as unknown as AppParams
	} catch (e: any) {
		throw new Error(`Unable to find the params configuration related to this store - url: ${host} - error: ${safeStringify(e)}`)
	}
}

export const appInit = async (ctx: NextPageContext) => {
	const { env } = process
	const { query, pathname, req } = ctx
	const host = req?.headers?.host

	if (!areEnvironmentVariablesValid(env)) {
		throw new Error('Environment variables are missing or not valid')
	}

	if (typeof host !== 'string') {
		throw new Error('No host')
	}

	const appParams = await getAppParamsForBackend(host, env.NEXT_PUBLIC_ENV)
	const preferredLanguage = (query.lang as LanguageCode) || appParams.l || 'en'
	const chainId = appParams.c || appParams.pc
	const brand = appParams.eCommerce ? appParams.brand : undefined
	const themeStrategy = appParams.eCommerce ? appParams.theme : THEME_STRATEGY.GENERIC

	const appTheme = await getTheme({
		chainId,
		brand,
		themeStrategy,
		isKiosk: pathname.includes('/kiosk/'),
	})

	if (appParams.brand !== appTheme.brand) {
		throw new Error('Mismatching between params and theme brand')
	}

	const appFontsUrl = `${getThemeUrl(themeStrategy, chainId, brand)}/assets/fonts.css`
	const userLocaleLanguage = await fetchLanguageFile(chainId, preferredLanguage, appParams)
	const eCommerceFooter = appParams.eCommerce
		? await (async () => {
				try {
					return await sendRequest(
						false,
						`${process.env.ECOMMERCE_SERVER}/v1/ecommerce-footer?chainId=${chainId}&lang=${preferredLanguage}`,
						'get',
						null,
						undefined
					)
				} catch (err: any) {
					throw new Error(
						`Unable to find the footer configuration related to this store - url: ${host} - error: ${safeStringify(err)} - full url: ${
							process.env.ECOMMERCE_SERVER
						}/v1/ecommerce-footer?chainId=${chainId}&lang=${preferredLanguage}`
					)
				}
		  })()
		: null

	return { appParams, appTheme, appFontsUrl, eCommerceFooter, userLocaleLanguage }
}

export const extractQueryParamsFromUrl = (urlStr: string) => parse(URL.parse(urlStr).query as string)

/**
 * Extract the initial and forwarded URLs from NextJS request.
 * - initial : URL where nextJS server is connected to
 * - forwarded : URL which is forwarded to the initial host (if not exists, equal to initial url)
 */
export const extractUrlsFromNextRequest = (req: IncomingMessage) => {
	try {
		const nextRequestMetaPropName = Object.getOwnPropertySymbols(req).find((s) => s.toString() === 'Symbol(NextInternalRequestMeta)')
		if (nextRequestMetaPropName) {
			// @ts-ignore
			const nextRequestMeta = req[nextRequestMetaPropName]
			const { requestHeaders } = nextRequestMeta.incrementalCache
			const [initialProto] = nextRequestMeta.initURL.split('://')
			const [initialHost] = nextRequestMeta.initURL.split('://')[1].split('/')
			const initialUrl = `${initialProto}://${initialHost}`
			const forwardedUrl =
				requestHeaders['x-forwarded-proto'] && requestHeaders['x-forwarded-host']
					? `${requestHeaders['x-forwarded-proto']}://${requestHeaders['x-forwarded-host']}`
					: initialUrl

			const forwardedProto = requestHeaders['x-forwarded-proto'] || initialProto

			if (!initialUrl) {
				throw new Error(`Error: initialUrl`)
			}

			if (!forwardedUrl) {
				throw new Error(`Error: forwardedUrl`)
			}

			return { initialUrl, forwardedUrl, initialProto, forwardedProto }
		}

		throw new Error(`Error: no nextRequestMetaPropName`)
	} catch (e) {
		throw new Error(`Error during server side urls extraction : ${e}`)
	}
}

export const getHomeData = async (appParams: any, forwardedUrl: string | null, type: string, noFilter?: boolean, channelType?: string) => {
	const chainId = appParams.c || appParams.pc
	const queryParams = `lang=${appParams.l}&type=${type}&cust=openRest${noFilter ? '&noFilter=true' : ''}${
		channelType ? `&channelType=${channelType}` : ''
	}`
	const urls = await tenantUrls([TenantUrlPickOptions.WebFlowAddressURL], chainId)

	return sendRequest(
		false,
		`${urls.webFlowAddressURL}&${queryParams}`,
		'get',
		null,
		forwardedUrl
			? {
					origin: forwardedUrl,
			  }
			: null,
		undefined,
		undefined,
		null,
		false
	)
}

export const appHomeInit = async ({
	appParams,
	forwardedUrl,
	cookies = {},
}: {
	appParams: any
	forwardedUrl: string
	cookies?: Record<string, string>
}) => {
	const channelType = CONSTANTS.CHANNEL_TYPE.WEB

	// Fetch branches and features of home page
	const homeLogo = await getHomeData(appParams, forwardedUrl, 'getLogo')
	const homeFeaturedItems = await getHomeData(appParams, forwardedUrl, 'getFeaturedItems', false, channelType)

	// TODO FOR NEXT: check if this variable affectation appParams.logo is usefull or not
	// for non e-commerce clients there is not header and footer logos. There is only this logo from the dashboard so store it
	// appParams.logo =  homeLogo.msg

	return {
		homeLogo,
		homeFeaturedItems,
	}
}

export const buildCombinedTheme = (appParams: any, appTheme: any, homeLogo: any, locale: any = 'en_US') => {
	// For non-ecommerce app, we add this specificity to the theme
	if (!appParams.eCommerce) {
		appTheme.palette.first = homeLogo.themeColor
	}

	// Build the combined theme object (brand and mui themes)
	const _muiComponentTheme = muiComponentTheme(appTheme)
	const _direction = isRTL(locale) ? 'rtl' : 'ltr'

	return buildTheme(appTheme, _muiComponentTheme, _direction)
}

export const getCustomerStaticPage = async (appParams: AppParams, pageName: string, lang: string) => {
	try {
		const staticPagesData = await sendRequest(
			false,
			`${appParams?.wruec}/v1/tenants/${appParams?.c}/customer-static-page/${pageName}/language/${lang}`,
			'get',
			null,
			undefined,
			true
		)
		if (isCustomerStaticPageValid(staticPagesData)) {
			return staticPagesData
		}
		return null
	} catch (err: any) {
		console.error(err.toString())
		return null
	}
}

export function getDefaultNavigationBarButtons() {
	return [
		{
			name: 'Home',
			languageKey: 'appBarHome',
			route: '/',
			icon: 'home.svg',
		},
		{
			name: 'Deals',
			languageKey: 'appBarDeals',
			route: '/:l/deals',
			icon: 'deals.svg',
		},
		{
			name: 'Menu',
			languageKey: 'appBarMenu',
			route: '/:l/menu',
			icon: 'menu.svg',
			isMenuButton: true,
		},
		{
			name: 'Account',
			languageKey: 'appBarAccount',
			route: '/account',
			icon: 'account.svg',
			showUserName: true,
			isSignInButton: true,
		},
	] as const
}

export const getStringWithoutHTMLSuffix = (itemNameFromQuery: string): string =>
	(itemNameFromQuery as string).endsWith('.html')
		? (itemNameFromQuery as string).substring(0, (itemNameFromQuery as string).length - 5)
		: itemNameFromQuery

export const getFirstSubdomain = (host: string): string | null => {
	host = host.replace('www.', '')
	if (host.includes('.com.')) {
		const idx = host.lastIndexOf('.com.')
		host = host.slice(0, idx + 4)
	}
	if (host.includes('.co.')) {
		const idx = host.lastIndexOf('.co.')
		host = host.slice(0, idx + 3)
	}
	const parts = host.split('.')
	if (parts.length > 2) {
		return parts[0]
	}
	return null
}
