import type React from 'react'
import { useEffect, useCallback, useMemo, cloneElement, isValidElement } from 'react'
import { inject, observer } from 'mobx-react'

import { Page, HISTORY_WINDOW_STATE_ID } from 'utils/constants'
import { Guard } from 'mobx/Infra/Infra.type'
import useWindowState from 'hooks/useWindowState'
import { getTranslatedTextByKey } from 'utils/utils'
import type _Account from 'mobx/Account'
import type _Application from 'mobx/Application'
import type _Infra from 'mobx/Infra'
import { useRouter } from 'next/router'

interface GuardProps {
	children: React.ReactNode
	Account?: typeof _Account
	Infra?: typeof _Infra
	Application?: typeof _Application
	context: Guard.Context
	type: Guard.Type
	name: Guard.Name
}

export default inject(
	'Account',
	'Application',
	'Infra'
)(
	observer(({ Account, Infra, Application, children, context, name, type }) => {
		const router = useRouter()
		const { openSignUpPopUp } = Account!
		const loggedIn: boolean = useMemo(() => !!Account!.user || Account!.loggingOut, [Account!.user, Account!.loggingOut])

		const signupTitle = getTranslatedTextByKey('eCommerce.signIn.couponWalletTitleText', 'Unlock Your Exclusive Coupons!')
		const signupSubTitle = getTranslatedTextByKey(
			'eCommerce.signIn.couponWalletSubTitleText',
			'Log in to access your exclusive coupons and enjoy great deals'
		)
		const items = Infra!.appParams?.guard?.[context]?.[type]
		const item = items?.find((c) => name === c?.name)
		const isProtected: boolean = item?.roles.some((role) => role.action === 'loginProtected' && role.type === 'authenticated') ?? false

		const [addState] = useWindowState()
		const handleOpenSignUpPopup = useCallback(() => {
			addState(() => {
				if (window.history?.state?.url?.includes(HISTORY_WINDOW_STATE_ID)) {
					openSignUpPopUp(false, { onSignupPopupClose: () => {} })
				}

				return true
			}, HISTORY_WINDOW_STATE_ID)

			openSignUpPopUp(true, {
				onSignupPopupClose: async () => router.push(Page.HOME),
				...(context === Guard.Context.wallet ? { signupTitle, signupSubTitle, signupDisplaySkipSection: false } : {}),
			})
		}, [addState, openSignUpPopUp])

		useEffect(() => {
			if (type === Guard.Type.page && isProtected && Application!.page === item?.name) {
				if (loggedIn) {
					openSignUpPopUp(false, { onSignupPopupClose: () => {} })
				} else {
					handleOpenSignUpPopup()
				}
			}
		}, [loggedIn, isProtected, Application!.page])

		if (type === Guard.Type.page && Application!.page === item?.name && isProtected && !loggedIn) {
			return null
		}

		if (type === Guard.Type.component && isProtected) {
			return loggedIn ? children : null
		}

		if (type === Guard.Type.event && isProtected && !loggedIn && isValidElement(children)) {
			const onClick = children?.props?.onClick

			return cloneElement(children as React.ReactElement, {
				...children.props,
				onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
					openSignUpPopUp(true, {
						onSignupPopupClose: () => {
							try {
								const loginSuccessfullyCompleted = !!Account!.user

								if (onClick && loginSuccessfullyCompleted) {
									onClick(e)
								}
							} catch (err) {
								console.error(err)
							}
						},
						...(context === Guard.Context.wallet
							? {
									signupTitle,
									signupSubTitle,
									signupDisplaySkipSection: false,
							  }
							: {}),
					})

					e.stopPropagation()
				},
			})
		}

		return children
	}) as React.FC<GuardProps>
)
