import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider as MaterialThemeProvider, StylesProvider } from '@material-ui/core/styles'
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components/macro'
import { GlobalStyle } from 'utils/theme/GlobalStyle'

// TODO FOR NEXT: check if these css are mandatory or not
// import '../src/index.css'

/*
 Removed jss from the StylesProvider, since putting both jss and injectFirst causes an error,
 and providing jss configuration just adds partial support for RTL,
 which we currently do not need to support for any markets.
 */
// import { create } from 'jss'
// import rtl from 'jss-rtl'
// const jss = create({ ...jssPreset(), plugins: [...jssPreset().plugins, rtl()] })

const ThemeProvider = ({ theme, children }: { theme: any; children: any }) => (
	<MaterialThemeProvider theme={theme}>
		<StyledComponentsThemeProvider theme={theme}>
			<GlobalStyle />
			<StylesProvider injectFirst>
				<CssBaseline>{children}</CssBaseline>
			</StylesProvider>
		</StyledComponentsThemeProvider>
	</MaterialThemeProvider>
)

export default ThemeProvider
