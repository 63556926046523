// @ts-nocheck
import { isEmpty } from 'lodash-es'

import { CONSTANTS } from '../constants'
import Account from '../../mobx/Account'
import Application from '../../mobx/Application'
import * as smartLook from './sdk/smartLook'

import * as gtm from './sdk/googleTagManager'
import * as nativeApp from './sdk/nativeApp'

const consumers = {}

export const bannerViewedMap = {}

export async function initAnalytics(params, isMobileApp) {
	// TODO - can I loop over the params.analytics and dynamically await the required Promise by a concatenated key name?
	// if not, then I need to manyally add each analytics SDK here
	const _analytics = params.analytics

	if (_analytics?.smartLook?.token && _analytics.smartLook?.region) {
		smartLook.init(_analytics.smartLook.token, _analytics.smartLook.region)
	}

	if (_analytics?.googleTagManager?.id || _analytics?.googleTagManager?.ids?.length) {
		await gtm.init(params)
		consumers.gtm = gtm
	} else {
		// When customer do not work with Google anlytics (maybe work with different tool).
		console.info(`Missing Google Tag Mgr Id!`)
	}

	if (isMobileApp) {
		consumers.nativeApp = nativeApp
	}
}

/**
 * Any non-enhanced-ecommerce events.
 *
 * Send to all analytics SDKs that are in the params json that have an id
 *
 * Each SDK will map our json to its required json format
 *
 * @param json
 */
export function sendCustomEvent(json) {
	Object.keys(consumers).forEach((_key, _idx) => {
		const _consumer = consumers[_key]

		const _accountUser = Account.getUser()
		if (_accountUser && _accountUser.user) {
			// add user contact info if its available
			const _userContactInfo = _accountUser.user

			json.uuid = _userContactInfo.userConsistentId || ''
			json.first_name = _userContactInfo.firstName || ''
			json.last_name = _userContactInfo.lastName || ''
			json.email = _userContactInfo.email || ''
			json.phone_number = emptyStringInsteadZero(_userContactInfo.phone) || emptyStringInsteadZero(_userContactInfo.phoneNumber) || ''
		}
		try {
			json.session_channel = Application.isMobileApp ? CONSTANTS.CHANNEL_TYPE.APP.toLowerCase() : CONSTANTS.CHANNEL_TYPE.WEB.toLowerCase()
			_consumer.sendCustomEvent(json)
		} catch (e) {
			console.error(e)
		}
	})
}

const emptyStringInsteadZero = (value) => {
	if(value && value !== '0') {
		return value
	}
	return ''
}

/**
 * Enhanced ecommerce events ONLY
 *
 * Send to all analytics SDKs that are in the params json that have an id
 *
 * Each SDK will map our event to its required event format
 *
 * @param type - must be one of the defined names
 * @param json - json required for this event
 */
export function sendEnhancedEcommerceEvent(type, ...args) {
	if (CONSTANTS.ANALYTICS.ENHANCED_ECOMMERCE_EVENTS[type]) {
		Object.keys(consumers).forEach((_key, _idx) => {
			const _consumer = consumers[_key]
			try {
				_consumer.sendEnhancedEcommerceEvent(type, ...args)
			} catch (e) {
				console.error(e)
			}
		})
	} else {
		console.warn(`not sending event for unknown eCommerce event: ${type}`)
	}
}

export function sendPageViewEvent(path) {
	const pageView = {
		category: 'navigation',
		action: 'page view',
		label: path,
		// location.search contains query string - to add ??
		session_channel: Application.isMobileApp ? CONSTANTS.CHANNEL_TYPE.APP.toLowerCase() : CONSTANTS.CHANNEL_TYPE.WEB.toLowerCase(),
	}

	Object.keys(consumers).forEach((_key, _idx) => {
		const _consumer = consumers[_key]

		try {
			_consumer.sendPageViewEvent(pageView)
		} catch (e) {
			console.error(e)
		}
	})
}

export const flattenVariationsChoices = (_variationsChoices) => {
	const _variationsList = []
	const _flattenVariations = (variationsChoices) => {
		if (isEmpty(variationsChoices)) {
			return
		}

		variationsChoices.forEach((variationsList) => {
			if (isEmpty(variationsList)) {
				return
			}

			variationsList.forEach((variation) => {
				_variationsList.push(variation)
				_flattenVariations(variation.variationsChoices)
			})
		})
	}

	_flattenVariations(_variationsChoices)

	return _variationsList
}

export const getRestaurantAnalyticsFields = (rest) => ({
	items: rest.items,
	locale: rest.locale,
	countryCode: rest.countryCode,
	currency: rest.currency,
	sections: rest?.sections,
})
