import type { CartItem, VariationChoice } from 'shared-types/Cart'
import type MenuItem from 'types/MenuItem'
import type { LanguageLocale } from 'utils/language'
import type { ReOrderItem, VariationChoiceOriginal } from './types'

export const transformMenuItemToCartItem = (item: MenuItem): CartItem => ({
	itemId: item.id,
	en_ID: item.description?.en_ID ?? '',
	price: item.price ?? 0,
	quantity: 1,
	total: item.price ?? 0,
	additionsNew: {},
})

const transformVariationChoice = (variation: VariationChoiceOriginal, locale: LanguageLocale): VariationChoice => ({
	id: variation.itemId,
	en_ID: variation.desc?.en_ID || '',
	name: variation.title?.[locale] || variation.title?.en_US || '',
	price: variation.price,
	hasVariations: variation.variations.length > 0,
	autoSelectedAndHidden: false,
	isVariation: true,
	groupHasDefaults: variation.variationsChoices.length > 0,
	minNumAllowed: variation.variations.length ? 1 : 0,
	variationsChoices: variation.variationsChoices.length
		? variation.variationsChoices.map((choiceGroup) => {
				if (choiceGroup.length === 0) {
					return {} // Empty object for empty groups
				}
				const transformedGroup: Record<string, VariationChoice> = {}
				choiceGroup.forEach((choice) => {
					transformedGroup[choice.itemId] = transformVariationChoice(choice, locale)
				})
				return transformedGroup
		  })
		: undefined,
})

export function transformItem(item: ReOrderItem, locale: LanguageLocale): CartItem {
	const filledVariationsChoices = item.variationsChoices.map((choiceGroup) => {
		if (choiceGroup.length === 0) {
			return {} // Add empty object for empty groups
		}
		const transformedGroup: Record<string, VariationChoice> = {}
		choiceGroup.forEach((choice) => {
			transformedGroup[choice.itemId] = transformVariationChoice(choice, locale)
		})
		return transformedGroup
	})

	const transformedItem: CartItem = {
		itemId: item.itemId,
		en_ID: item.en_ID,
		price: item.price,
		quantity: item.quantity,
		total: item.total,
		additionsNew: {
			[item.itemId]: {
				variationsChoices: filledVariationsChoices,
			},
		},
	}

	return transformedItem
}
