import queryString from 'query-string'

import Infra from 'mobx/Infra'
import User from 'mobx/User'
import MobileApplication from 'mobx/MobileApplication'
import { getNewRefObject, getDomainByEnv, getUUID, getPlatformId, sendRequest, sendDDLog, isMobile } from 'utils/utils'

interface NewSessionParams {
	storeId?: string
	refObject?: object
	wru?: string
	chainId?: string
	stopLoading?: boolean
	shouldRedirectIfError?: boolean
}

export const initOrUpdateSession = async ({
	storeId = (Infra.appParams as any).j || (Infra.appParams as any).c,
	refObject = {},
	wru = (Infra.appParams as any).wru || getDomainByEnv(undefined),
	chainId = (Infra.appParams as any).c,
	stopLoading = true,
	shouldRedirectIfError = true,
}: NewSessionParams): Promise<string> => {
	const newRefObject = getNewRefObject(refObject)
	Infra.setLoading(true)

	const userUUID = getUUID() || null

	const parsed = Infra.appParams
	const lang: string = User.preferredLanguage || (parsed as any).l
	let { cust } = parsed as any

	if (cust !== 'stag1') {
		cust = 'openRest'
	}

	const platform = getPlatformId()

	wru = wru.endsWith('/') ? wru : `${wru}/`

	const storeIdWithoutDev = storeId.replace('_dev', '')

	const refObjectEncodedString = Object.keys(newRefObject).length ? encodeURIComponent(JSON.stringify(newRefObject)) : ''
	const url2 = `${wru}start_web_session?cust=${cust}&store=${storeIdWithoutDev}&user=${userUUID}&lang=${lang}&ref=${refObjectEncodedString}&chain=${chainId}${
		platform ? `&platform=${platform}` : ''
	}`
	const msg = await sendRequest(false, url2, 'get', null, null, stopLoading) as {
		OK?: boolean
		url?: string
		redirectURL?: string
	}

	let newMenuPath = ''

	if (msg) {
		if (msg?.OK && msg?.url) {
			const oldMenuUrl = new URL(msg?.url)
			const urlSearch = oldMenuUrl.search
			const urlPath = oldMenuUrl.pathname // eg /iw_08c14c60-ba9e-36b5-e906-8b8d594aaeb9_dev_menu.html

			let [, j] = urlPath.split('_')

			j = process.env.NODE_ENV === 'production' || parsed.useProductionMenu ? j : `${j}_dev`

			newMenuPath = `/menu${urlSearch}&j=${j}&pc=${chainId}&ref=${refObjectEncodedString}${window.location.hash}`
		} else if (shouldRedirectIfError) {
			// todo dan: I'm unsure we should throw an error from here, it can redirect to order lock
			// if you want to validate what we get from the server for session expired ask alex or TBE (should be only check_request)
			// if you want to know what is happening with initNewSession, ask Erron, but I think it would be overkill for this task
			console.error(JSON.stringify(msg))

			// something went wrong with creating the session so load the home page
			const homePageUrl = `${window.location.protocol}//${window.location.host}`
			sendDDLog({ level: 'info', message: 'order-lock-redirect_start-web-session', data: msg?.redirectURL })
			const redirectURL = msg?.redirectURL?.replace(/^.*?(?=\/order-confirmation)/, window.location.origin)
			window.location.href = redirectURL || homePageUrl
			return ''
		}
	}

	try {
		const newMenuQueryParams = queryString.parse((newMenuPath ?? '').split('?')[1])
		const { request: sessionId } = newMenuQueryParams

		if (sessionId) {
			localStorage.setItem('sessionId', sessionId as string)
		}

		if (isMobile()) {
			const userConsistentId = queryString.parse(newMenuPath)?.userConsistentId
			if (userConsistentId) {
				User.setUserConsistentId(userConsistentId as string)
			}

			await MobileApplication.saveUserConsistentIdExpoTokenPair(Infra)
		}
	} catch (e) {
		console.error(`Error: `, e)
	}

	if (stopLoading) {
		Infra.setLoading(false)
	}
	return newMenuPath
}
