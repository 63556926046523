import { getECommerceDomainByEnv, getNewRefObject, getUrlUpdatedWithNewRef, sendRequest } from 'utils/utils'
import Session from 'mobx/Session'
import type { PushNotification } from 'types/PushNotification'
import { PushNotificationActionType } from 'types/PushNotification'
import type _Infra from 'mobx/Infra'
import type _Application from 'mobx/Application'
import type { Stores } from 'contexts/StoresContext'
import { ITEM_CODE_ORDER_TYPE_SEPARATOR } from 'utils/constants'
import type { BackendChannel } from 'utils/constants'

const sendPushNotificationOpenedAnalyticsEvent = async (
	Infra: typeof _Infra,
	Account: any,
	pushNotificationData: PushNotification,
	channelType: BackendChannel
) => {
	try {
		const chainId = Infra.appParams.c || Infra.appParams.pc
		const host = getECommerceDomainByEnv(Infra?.appParams)
		const campaignId = pushNotificationData?.campId
		const messageType = pushNotificationData?.messageType

		const authorizationHeaders = await Account.getAuthorizationHeader(channelType)

		if (!campaignId) {
			console.warn("No campainId in the push notification's payload")
			return
		}

		sendRequest(
			false,
			`${host}/v1/tenants/${chainId}/push-notification-opened`,
			'post',
			{
				campaignId,
				messageType,
			},
			authorizationHeaders
		)
	} catch (err) {
		console.warn(
			`Failed to update analytics on push notification clicked - campaign id: "${pushNotificationData.campId}", messageType: "${pushNotificationData.messageType}"`
		)
	}
}

export const handlePushNotificationReceived = async (
	pushNotificationData: PushNotification,
	stores: Stores,
	Infra: typeof _Infra,
	Account: any,
	Application: typeof _Application
) => {
	const _pathname = window.location.pathname
	const ref = pushNotificationData?.actionData?.ref
	const { couponFlowStore } = stores
	const extraParams = pushNotificationData?.campId
		? {
				i: pushNotificationData.campId,
				...(pushNotificationData.actionType === PushNotificationActionType.dripSecond ? { second: '1' } : {}),
		  }
		: {}

	if (ref || Object.keys(extraParams).length) {
		await Session.initOrUpdateSession({
			refObject: ref ? { ref, ...extraParams } : { ...extraParams },
		})
	}
	if (ref) {
		const newRefObject = getNewRefObject({ ref })
		const refObjectEncodedString = Object.keys(newRefObject).length ? encodeURIComponent(JSON.stringify(newRefObject)) : ''

		// Update window history with ref
		if (_pathname !== '/') {
			const newUrl = getUrlUpdatedWithNewRef(refObjectEncodedString)
			window.history.replaceState({ path: newUrl }, '', newUrl)
		}
	} else {
		await Session.initOrUpdateSession({
			refObject: { ...extraParams },
		})
	}

	await sendPushNotificationOpenedAnalyticsEvent(Infra, Account, pushNotificationData, Application.backendChannel)

	if (pushNotificationData?.actionType === PushNotificationActionType.startOrder) {
		// code for start order goes here
	}

	// If actionType is expressCheckout, it means we received an item coupon
	if (pushNotificationData?.actionType === PushNotificationActionType.expressCheckout) {
		const item = pushNotificationData?.actionData
		const rawCouponCode = item?.description?.en_ID
		if (rawCouponCode) {
			const [couponCode] = rawCouponCode.split(ITEM_CODE_ORDER_TYPE_SEPARATOR)
			if (couponCode) {
				couponFlowStore.start({ code: couponCode, orderTypeToForce: null })
			} else {
				console.error('No item code in the push notification data for action type expressCheckout')
			}
		}
	}

	// If actionType is coupon, it means we received a discount coupon
	if (pushNotificationData?.actionType === PushNotificationActionType.coupon) {
		const couponCode = pushNotificationData?.actionData?.code
		if (couponCode) {
			couponFlowStore.start({ code: couponCode, orderTypeToForce: null })
		} else {
			console.error('No coupon code in the push notification data for action type coupon')
		}
	}
}
