// @ts-nocheck
import React from 'react'
import Typography from '@material-ui/core/Typography'
import InfoIcon from '@material-ui/icons/Info'
import styled from 'styled-components'

import { getTranslatedTextByKey } from 'utils/utils'
import { sendCustomEvent } from 'utils/analytics/analytics'
import User from 'mobx/User'
import type CartStore from 'mobx/Cart/store'
import type { NextRouter } from 'next/router'
import type { SetStore } from 'contexts/StoreContext'
import type SectionID from 'types/SectionID'
import type AddressManagerV2Store from 'mobx/AddressManagerV2/store'

const StyledWarning = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`

const StyledWarningTitle = styled.span`
	margin-inline-start: 5px;
`

export const itemNotFoundPopUp = (Infra) => {
	const _title = getTranslatedTextByKey('webviewFlow.itemNotFound')
	const _message = getTranslatedTextByKey('webviewFlow.selectedDealIsNotAvailable')

	sendCustomEvent({
		category: 'error',
		action: 'notification',
		label: _title,
		message: _message,
	})

	Infra.setNotification({
		title: (
			<StyledWarning>
				<InfoIcon key="1" color="secondary" />
				<StyledWarningTitle key="2">{_title}</StyledWarningTitle>
			</StyledWarning>
		),
		message: (
			<Typography variant="caption">
				<span key="1">{_message} </span>
			</Typography>
		),
		okAction: () => {
			history.replaceState(null, null, ' ')
			Infra.closeNotification()
		},
	})
}

export const buildSectionMenuUrl = async (sectionId: SectionID, sectionName: string, cartStore: CartStore, addressManagerV2Store: AddressManagerV2Store, extraParams = '') => {
	if (addressManagerV2Store.isUserLocalized()) {
		const { menuUrl } = await addressManagerV2Store.onMenuClickWhenLocalized(cartStore, User)

		return `${menuUrl}${extraParams ? `&${extraParams}` : ''}#section_id=${sectionId}&section_name=${sectionName}`
	}
	return `/${User.preferredLanguage}/menu${extraParams ? `?${extraParams}` : ''}#section_id=${sectionId}&section_name=${sectionName}`
}

export const navigateToMenuSection = async (
	sectionId: SectionID,
	sectionName: string,
	setStore: SetStore,
	cartStore: CartStore,
	router: NextRouter,
	addressManagerV2Store: AddressManagerV2Store,
	extraParams = ''
) => {
	const url = await buildSectionMenuUrl(sectionId, sectionName, cartStore, addressManagerV2Store, extraParams)

	if (addressManagerV2Store.isUserLocalized()) {
		const { menuRes, storeMetaData, serverSession } = await addressManagerV2Store.onMenuClickWhenLocalized(cartStore, User)

		User.setSession(serverSession)
		setStore((store) => ({ ...store, data: menuRes, metaData: storeMetaData }))
	}

	router.push(url)
}
