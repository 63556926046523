import { getECommerceDomainByEnv, sendRequest } from 'utils/utils'
import { tenantUrls, TenantUrlPickOptions } from 'utils/api/storefrontUrls'

export interface GetBranchesListConfigProps {
	showLoader?: boolean
	stopLoading?: boolean
	timeout?: number
	onCompleteCallback?: any
	useCacheBuster?: boolean
}

export type GetBranchesListRequestParameters = (queryParams: string, GetBranchesListConfig?: GetBranchesListConfigProps) => any

const defaultConfigs = {
	showLoader: false,
	stopLoading: false,
	timeout: 9000,
	onCompleteCallback: null,
	useCacheBuster: false,
}

const getBranchesListRequest: GetBranchesListRequestParameters = async (queryParams, config = defaultConfigs) => {
	const urls = await tenantUrls([TenantUrlPickOptions.WebFlowAddressURL])
	return sendRequest(
		!!config.showLoader,
		`${urls.webFlowAddressURL}&${queryParams}`,
		'get',
		null,
		null,
		config.stopLoading,
		config.timeout,
		config.onCompleteCallback,
		false
	)
}

export type GetBrowseCategoriesRequestParameters = (chainOrStoreId: string, channelType: string, timezone: string) => any
const getBrowseCategoriesRequest: GetBrowseCategoriesRequestParameters = (chainOrStoreId, channelType, timezone) =>
	sendRequest(
		false,
		`${getECommerceDomainByEnv()}/v1/tenants/${chainOrStoreId}/menu/sections?channelType=${channelType}&timezone=${timezone}`,
		'GET',
		null,
		null,
		true, // Have to set default params in order to disable cacheBuster
		90000,
		null,
		false
	)

const setBackdropBody = (backdropEnabled: boolean) => {
	if (backdropEnabled) {
		document.body.style.overflow = 'hidden'
		document.body.style.position = 'relative'
		document.body.style.height = '100%'
	} else {
		document.body.style.overflow = 'auto'
		document.body.style.position = 'unset'
		document.body.style.height = 'unset'
	}
}

export default {
	getBranchesListRequest,
	getBrowseCategoriesRequest,
	setBackdropBody,
}
