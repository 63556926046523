import React, { useEffect, useState } from 'react'
import { getTranslatedTextByKey } from 'utils/utils'
import {
	ErrorPageContainer,
	ErrorPageContent,
	ErrorPageContentImage,
	ErrorPageNoButtonHeader,
	redirectToChat,
	SessionExpiredPageButton,
	SessionExpiredPageDescription,
	SessionExpiredPageTitle,
	SessionExpiredSubTitle,
	SESSION_EXPIRED_DESCRIPTION,
	SESSION_EXPIRED_RESTART_BUTTON,
	SESSION_EXPIRED_SUB_TITLE,
	SESSION_EXPIRED_TITLE,
} from 'static/components/errors/ErrorPagesSharedComponents'
import { ERROR_IMAGE_ROUTE } from 'utils/staticFilesRoutes'
import { inject, observer } from 'mobx-react'
import type _Infra from 'mobx/Infra'
import { getThemeUrl } from 'utils/theme/themeManager'
import { THEME_STRATEGY } from 'constants/theme'

interface ChatSessionExpiredErrorPageProps {
	Infra: typeof _Infra
	targetChatUrl: string
	appId: string
}

const ChatSessionExpiredErrorPage = inject('Infra')(
	observer(({ Infra, targetChatUrl, appId }: ChatSessionExpiredErrorPageProps) => {
		const { c: chainId } = Infra.appParams
		const [isValidReturnButton, setIsValidReturnButton] = useState(true)
		const imgPath = `${getThemeUrl(THEME_STRATEGY.GENERIC, chainId)}/assets`

		useEffect(() => {
			if (appId && targetChatUrl) {
				return setIsValidReturnButton(true)
			}
			return setIsValidReturnButton(false)
		}, [appId, targetChatUrl])

		return (
			<ErrorPageContainer>
				<ErrorPageNoButtonHeader />
				<ErrorPageContent>
					<ErrorPageContentImage src={`${imgPath}${ERROR_IMAGE_ROUTE}`} alt="error image" />
					<SessionExpiredPageTitle className="HomePageTitle">
						{getTranslatedTextByKey('eCommerce.errorPages.sessionErrorTitle', SESSION_EXPIRED_TITLE).toUpperCase()}
					</SessionExpiredPageTitle>
					<SessionExpiredSubTitle variant="Subhead">
						{getTranslatedTextByKey('eCommerce.errorPages.sessionErrorSubtitle', SESSION_EXPIRED_SUB_TITLE)}
					</SessionExpiredSubTitle>
					<SessionExpiredPageDescription variant="Subhead" component="div">
						{getTranslatedTextByKey('eCommerce.errorPages.sessionErrorDescription', SESSION_EXPIRED_DESCRIPTION)}
					</SessionExpiredPageDescription>
					{isValidReturnButton && (
						<SessionExpiredPageButton
							onClick={() => {
								redirectToChat(targetChatUrl, appId, setIsValidReturnButton)
							}}
							$size="big"
						>
							{getTranslatedTextByKey('eCommerce.errorPages.restartButton', SESSION_EXPIRED_RESTART_BUTTON)}
						</SessionExpiredPageButton>
					)}
				</ErrorPageContent>
			</ErrorPageContainer>
		)
	})
)

export default ChatSessionExpiredErrorPage
