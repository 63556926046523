export interface PushNotification {
	actionType: PushNotificationActionType
	actionData: any
	messageType?: string
	campId?: string
}

export enum PushNotificationActionType {
	expressCheckout = 'expressCheckout',
	coupon = 'coupon',
	startOrder = 'order',
	dripSecond = 'drip_second',
}
